import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router-dom';
// import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from '../dashboard/header';
import Nav from '../dashboard/nav'
import VendorProfile from '../../pages/VendorProfile';
import GetSingleHotelsDetails from '../../Store/Reducers/VendorReducer/GetSingleVendorReducer';
import { GetVendorByIdAction } from '../../Store/Actions/VendorsActions/GetVendorByID';
import WaitLoader from '../../utils/WaitLoader';
import { UseStateManager } from '../../Context/StateManageContext';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

// ----------------------------------------------------------------------

export default function VendorProfileLayout() {


    const { id } = useParams();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [editable, setEditable] = useState(true)


    const { isLoading, data } = useSelector((state) => state.GetSingleHotelsDetails)


    const [selectedHotel, setSelectedHotel] = useState(null)


    const GetHotelDetail = () => {
        dispatch(GetVendorByIdAction(id))
    }

    useEffect(() => {
        GetHotelDetail()
    }, [])




    return (
        <StyledRoot>
            <WaitLoader Loading={isLoading} />
            <Header onOpenNav={() => setOpen(true)} />

            <Nav openNav={open} onCloseNav={() => setOpen(false)} />

            <Main>
                <Outlet />
                <VendorProfile vendorData={data} />
            </Main>
        </StyledRoot>
    );
}
