import { Check, PlusCircle } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
  Modal,
  Box,
  TextField,
  Fade,
  IconButton,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import CancelIcon from '@mui/icons-material/Cancel';
import WaitLoader from '../utils/WaitLoader';
import GlobalModel from '../components/GlobalModal/GlobalModel';
import { GetVendorByIdAction } from '../Store/Actions/VendorsActions/GetVendorByID';
import { UseStateManager } from '../Context/StateManageContext';
import { GetFileUploaded } from '../Store/Actions/ImageUploadActions/UploadActions';
import { GetUpdateTheHotelData } from '../Store/Actions/HotelActions/HotelCurdAction';

const VendorProfile = ({ vendorData, setVendorData }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [discription, setDicription] = useState(vendorData?.discription);
  const handleDicriptionChange = (e) => {
    e.preventDefault();
    setDicription(e.target.value);
  };

  const { currentUser } = UseStateManager();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [formData, setFormData] = useState({
    hotelName: vendorData?.hotelName,
    hotelCoverImg: vendorData.hotelCoverImg,
    hotelImages: [],
    checkIn: vendorData.checkIn,
    checkOut: vendorData.checkOut,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // hotelUpdate reducer
  const updatedData = useSelector((state) => state.UpdateHotelReducers);

  // Handle remove or add the image from array
  const HandleRemoveImage = (image) => {
    const newImages = formData.hotelImages?.filter((img) => img !== image);
    const newFormData = { ...formData };
    newFormData.hotelImages = newImages;
    setFormData(newFormData);
  };

  // handle input in formdata
  const HandleFormData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // image upload reducer
  const UploadedImage = useSelector((state) => state.UploadReducer);

  const HandleGetFileUploaded = (e, id) => {
    setLoader(true);
    dispatch(GetFileUploaded(e, id)).then(() => {
      setLoader(false);
    });
  };

  const [hotelDetailUpdate, sethotelDetailUpdate] = useState(false);

  const handleImageClick = (img) => {
    setSelectedImage(img);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const id = vendorData?._id;
    await dispatch(GetUpdateTheHotelData(id, formData)).then(() => {
      dispatch(GetVendorByIdAction(id));
      sethotelDetailUpdate(false);
    });
  };
  const handleUpdate = async (e, formData) => {
    e.preventDefault();
    const id = vendorData?._id;
    await dispatch(GetUpdateTheHotelData(id, formData)).then(() => {
      dispatch(GetVendorByIdAction(id));
      sethotelDetailUpdate(false);
    });
  };

  const discriptionStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500',
    maxHeight: '800px',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 1,
  };

  const styleHotelUpdate = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    maxHeight: '800px',
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 1,
  };

  useEffect(() => {
    if (UploadedImage.fileName.hotelImages) {
      setFormData({ ...formData, hotelImages: [...formData.hotelImages, UploadedImage.fileName.hotelImages] });
    } else {
      setFormData({ ...formData, ...UploadedImage.fileName });
    }
  }, [UploadedImage.fileName]);

  useEffect(() => {
    setFormData({
      ...formData,
      hotelName: vendorData.hotelName,
      hotelImages: vendorData.hotelImages,
      checkIn: vendorData.checkIn,
      checkOut: vendorData.checkOut,
      hotelCoverImg: vendorData.hotelCoverImg,
    });
  }, [vendorData]);

  return vendorData && vendorData.hotelName ? (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card sx={{ minHeight: 400 }}>
            <div style={{ display: 'grid', placeItems: 'center', padding: '15px' }}>
              <img
                style={{ borderRadius: '50%', border: '1px solid black' }}
                height={180}
                width={180}
                src={vendorData?.hotelCoverImg}
                alt="..."
              />
            </div>
            <CardContent>
              <div style={{ textAlign: 'center' }}>
                <Typography gutterBottom variant="h5" component="div">
                  {vendorData?.hotelName}
                </Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="p">
                    Property Type: <b>{vendorData.hotelType.title}</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" style={{ margin: '0px', padding: '3px 0px' }}>
                    Ownership Type: <b>{vendorData.ownershipType}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="p">
                    Locality: <b>{vendorData?.locality || 'NA'}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="p">
                    Mobile No. : <b>{vendorData.hotelMobileNo}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="p">
                    Email Id: <b>{vendorData.hotelEmail}</b>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Button sx={{ m: 1 }} onClick={() => sethotelDetailUpdate(true)} variant="contained">
              Update
            </Button>
          </Card>
        </Grid>
        <Grid item xs={8}>
          <Card sx={{ minHeight: 400 }}>
            <ImageList sx={{ height: 200 }} cols={4} rowHeight={164}>
              {vendorData && vendorData.hotelImages
                ? vendorData.hotelImages.map((item, index) => (
                    <ImageListItem key={item} onClick={() => handleImageClick(`${item}`)}>
                      <img
                        src={`${item}`}
                        srcSet={`${item}`}
                        alt={'Rooms'}
                        // loading="lazy"
                      />
                    </ImageListItem>
                  ))
                : null}
            </ImageList>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Description
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {vendorData?.discription}
              </Typography>
            </CardContent>
            <CardActions>
              <Button onClick={handleOpen} variant="contained">
                Edit Description
              </Button>
              {/* ------------------------- Discription modal------------------------------ */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={discriptionStyle}>
                  <TextField
                    id="outlined-multiline-static"
                    label="Edit Discription"
                    value={discription || ''}
                    multiline
                    onChange={handleDicriptionChange}
                    rows={4}
                    variant="outlined"
                    fullWidth
                  />

                  <Grid container py={2}>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                      <Button onClick={handleClose} variant="outlined">
                        Cancel
                      </Button>
                      <Button variant="contained" onClick={(e) => handleUpdate(e, { discription })}>
                        Save changes
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Modal>
              {/* ---------------- Discription Modal ------------------------------ */}
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      {/* Hotel room Details card table  */}
      <Card sx={{ p: 3, my: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={currentUser?.role === 'admin' ? 12 : 6}>
            <Typography variant="h4">Room details</Typography>
          </Grid>
          {currentUser?.role === 'admin' ? null : (
            <Grid item xs={6} textAlign={'right'}>
              <IconButton onClick={() => navigate('/dashboard/roomManagment')} color="error">
                <CreateIcon />
              </IconButton>
            </Grid>
          )}
          <Grid container sx={{ display: 'flex', padding: '10px' }}>
            <Grid xs={2} sx={{ paddingLeft: '10px' }}>
              <Typography sx={{ fontWeight: '700' }}>No of Rooms</Typography>
            </Grid>
            <Grid xs={2}>
              <Typography sx={{ fontWeight: '700' }}>Type</Typography>
            </Grid>
            <Grid xs={3}>
              <Typography sx={{ fontWeight: '700' }}>Amenities</Typography>
            </Grid>
            <Grid xs={3}>
              <Typography sx={{ fontWeight: '700' }}>Facilities</Typography>
            </Grid>
            <Grid xs={2}>
              <Typography sx={{ fontWeight: '700' }}>Price</Typography>
            </Grid>
          </Grid>
          {vendorData?.rooms.map((item, index) => (
            // vendoer room data map
            <Grid container sx={{ display: 'flex', padding: '10px' }}>
              <Grid xs={2} sx={{ paddingLeft: '10px' }}>
                <Typography sx={{ fontWeight: '700' }}>{item?.counts}</Typography>
              </Grid>
              <Grid xs={2}>
                <Typography sx={{ fontWeight: '700' }}>{item?.roomType?.title}</Typography>
              </Grid>
              <Grid xs={3}>
                <Typography sx={{ fontWeight: '700' }}>
                  {item.roomType?.amenties?.map((item, index) => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <Check size={15} />
                      {item?.title}
                    </div>
                  ))}
                </Typography>
              </Grid>
              <Grid xs={3}>
                <Typography sx={{ fontWeight: '700' }}>
                  {item.roomType?.includeFacilities?.map((item, index) => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <Check size={15} />
                      {item?.title}
                    </div>
                  ))}
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Typography sx={{ fontWeight: '700' }}>₹ &nbsp;{item?.price}</Typography>
              </Grid>
            </Grid>
          ))}

          {/* <Grid item xs={3}>
              <Typography variant="h5">No. of Rooms & Name</Typography>
              <hr />
              <Typography variant="p">10 Classic Rooms</Typography>
            </Grid>
         
          <Grid item xs={3}>
            <Typography variant="h5">Price</Typography>
            <hr />
            <Typography variant="p">₹ 800</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="h5">facilities</Typography>
            <hr />
            <Typography variant="p">Wifi</Typography>
          </Grid> */}
          {/* <Grid item xs={3}>
            <Typography variant="h5">Amenities</Typography>
            <hr />
            <Typography variant="p">TV</Typography>
          </Grid> */}
        </Grid>
      </Card>
      <Card sx={{ p: 3, my: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Accepted Terms & Conditions</Typography>
          </Grid>
          <Grid item xs={12}>
            <ul style={{ marginLeft: '10px' }}>
              <li>I Accepted Hotelio Term & Conditions.</li>
              <li>I Accepted My Hotel is fully Sanitized.</li>
              <li>I Accepted We Not Support Discrimination.</li>
              <li>I Accepted all information given by me is valid & True.</li>
            </ul>
          </Grid>
        </Grid>
      </Card>

      {/* Image Preview modal  */}
      <GlobalModel
        open={selectedImage !== null}
        handleClose={() => setSelectedImage(null)}
        content={
          <Fade in={selectedImage !== null}>
            <Box sx={style}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center' }}>
                <Typography variant="h4">Image Preview</Typography>
                <IconButton onClick={() => setSelectedImage(null)} color="error">
                  <CancelIcon />
                </IconButton>
              </div>
              <img src={selectedImage} alt="Selected" style={{ width: '100%', height: '500px' }} />
            </Box>
          </Fade>
        }
      />

      {/* Hotel details Update Modal  */}
      <GlobalModel
        open={hotelDetailUpdate}
        handleClose={() => sethotelDetailUpdate(false)}
        content={
          <Fade in={hotelDetailUpdate}>
            <Box sx={styleHotelUpdate}>
              <WaitLoader Loading={loader} />
              <div style={{ paddingBottom: '10px' }}>
                <Typography variant="h4">Update Profile</Typography>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    name="hotelName"
                    label="Hotel Name"
                    type="text"
                    fullWidth
                    value={formData.hotelName || ''}
                    onChange={HandleFormData}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="checkInTime"
                    name="checkIn"
                    label="Check-in (24-hour format)"
                    type="time"
                    fullWidth
                    inputProps={{
                      step: 60, // 1-minute intervals
                      inputMode: 'numeric',
                      pattern: '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$', // 24-hour time format pattern
                    }}
                    value={formData?.checkIn || ''}
                    onChange={HandleFormData}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="checkOutTime"
                    name="checkOut"
                    label="Check-out"
                    type="time"
                    value={formData?.checkOut || ''}
                    onChange={HandleFormData}
                    fullWidth
                    inputProps={{
                      step: 60, // 1-minute intervals
                      inputMode: 'numeric',
                      pattern: '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$', // 24-hour time format pattern
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {/* Upload Hotel Cover Image */}
                    <label htmlFor="uploadCoverimage">
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="hotelCoverImg"
                        type="file"
                        name="hotelCoverImg"
                        onChange={(e) => HandleGetFileUploaded(e, currentUser?._id)} // Handle file selection
                      />
                      <div>
                        <Button
                          variant="contained"
                          component="span"
                          onClick={() => {
                            document.querySelector('#hotelCoverImg').click();
                          }}
                        >
                          Upload Hotel Cover Image
                        </Button>
                        <p>
                          <b>Please upload only jpeg, jpg, png files.</b>
                        </p>
                      </div>
                    </label>
                    <div>
                      {/* Display the selected image */}
                      <img
                        style={{ width: '250px', borderRadius: '5px' }}
                        src={
                          formData.hotelCoverImg ||
                          'https://t4.ftcdn.net/jpg/05/07/58/41/360_F_507584110_KNIfe7d3hUAEpraq10J7MCPmtny8EH7A.jpg'
                        }
                        alt="hotelCoverImg"
                      />
                    </div>
                  </div>
                  <div>
                    {/* Upload Multiple Hotel Images */}
                    <label htmlFor="uploadCoverimage">
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="hotelImg"
                        type="file"
                        name="hotelImages"
                        onChange={(e) => HandleGetFileUploaded(e, currentUser._id)}
                      />
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={formData?.hotelImages?.length === 10}
                        component="span"
                        onClick={() => {
                          document.querySelector('#hotelImg').click();
                        }}
                      >
                        Upload Multiple Hotel Images
                      </Button>
                      <p>
                        <b>Please upload hotel images one by one, and ensure they are in JPEG, JPG, or PNG format.</b>
                      </p>
                    </label>
                    {/* <img style={{ width: '250px' }} src={item} alt={`${index}`} /> */}
                    <div style={{ marginTop: '10px', padding: '20px', overflowY: 'scroll', height: '200px' }} className="update-hotelImges">
                      {formData?.hotelImages?.map((item, index) => (
                        <div
                          className="update-hotelImge"
                          style={{
                            background: `linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${item})`,
                          }}
                        >
                          <PlusCircle
                            className="crossIcon"
                            size={18}
                            onClick={() => {
                              HandleRemoveImage(item);
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                }}
              >
                <Button onClick={() => sethotelDetailUpdate(false)} variant="contained" color="primary">
                  Cancel
                </Button>
                <Button type="submit" onClick={handleSubmit} variant="contained" color="primary">
                  Update
                </Button>
              </div>
            </Box>
          </Fade>
        }
      />
    </div>
  ) : (
    <WaitLoader Loading="true" />
  );
};

export default VendorProfile;
