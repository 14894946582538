export const GET_ALL_PROPERTY_TYPE_LOADING = "GET_ALL_PROPERTY_TYPE_LOADING";
export const GET_ALL_PROPERTY_TYPE_SUCCESS = 'GET_ALL_PROPERTY_TYPE_SUCCESS';
export const GET_ALL_PROPERTY_TYPE_ERROR = 'GET_ALL_PROPERTY_TYPE_ERROR';

export const PUT_ALL_PROPERTY_TYPE_LOADING = "GET_ALL_PROPERTY_TYPE_LOADING";
export const PUT_ALL_PROPERTY_TYPE_SUCCESS = 'GET_ALL_PROPERTY_TYPE_SUCCESS';
export const PUT_ALL_PROPERTY_TYPE_ERROR = 'GET_ALL_PROPERTY_TYPE_ERROR';

export const UPDATE_ALL_PROPERTY_TYPE_LOADING = "GET_ALL_PROPERTY_TYPE_LOADING";
export const UPDATE_ALL_PROPERTY_TYPE_SUCCESS = 'GET_ALL_PROPERTY_TYPE_SUCCESS';
export const UPDATE_ALL_PROPERTY_TYPE_ERROR = 'GET_ALL_PROPERTY_TYPE_ERROR';

export const REMOVE_ALL_PROPERTY_TYPE_LOADING = "GET_ALL_PROPERTY_TYPE_LOADING";
export const REMOVE_ALL_PROPERTY_TYPE_SUCCESS = 'GET_ALL_PROPERTY_TYPE_SUCCESS';
export const REMOVE_ALL_PROPERTY_TYPE_ERROR = 'GET_ALL_PROPERTY_TYPE_ERROR';