import { Trash } from 'react-feather';
import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Fade,
  Grid,
  IconButton,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import 'react-quill/dist/quill.snow.css';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Swal from 'sweetalert2';
import AddIcon from '@mui/icons-material/Add';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Bread from '../components/Bread/Bread';
import GlobalModel from '../components/GlobalModal/GlobalModel';
import AddFaqFunction from '../components/AddFaq/AddFaqFunction';
import { GetFaqByUser } from '../Store/Actions/faqActions';
import { UseStateManager } from '../Context/StateManageContext';
import { CreateLocationAction, GetLocationsAction } from '../Store/Actions/locationsActions';
import instance from '../utils/_utils';
import { arraySearch } from '../utils/functions';

const ManageFaq = () => {
  const allFaqList = useSelector((state) => state.GetFaqByUserReducer);
  const createdLocation = useSelector((state) => state.CreateLocationReducer);
  const [viewData, setViewData] = useState(null);
  const AllLocations = useSelector((state) => state.GetLocationsReducers);
  const [locationFromData, setLocationFromData] = useState({});
  const [editLocationFormData, setEditLocationFormData] = useState({});
  const handleEditLocationForm = (name, value) => {
    setEditLocationFormData({ ...editLocationFormData, [name]: value });
  };
  const handleLocationFormCreation = (name, value) => {
    setLocationFromData({ ...locationFromData, [name]: value });
  };
  const { currentUser } = UseStateManager();
  // Styling for the modal
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1200,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    maxHeight: 700,
    overflowY: 'scroll',
    p: 4,
  };

  const dispatch = useDispatch();

  // State for the Location modal
  const [isOpenAddFaq, setIsAddFaq] = useState(false);

  // Close the Location modal
  const handleCloseAddFaqModal = () => {
    setIsAddFaq(!isOpenAddFaq);
  };

  const handleAddFAQ = (e, id) => {
    e.stopPropagation();
    const faq = editLocationFormData?.faq || [];

    if (!faq.includes(id)) {
      setEditLocationFormData({ ...editLocationFormData, faq: [...faq, id] });
    } else {
      setEditLocationFormData({ ...editLocationFormData, faq: faq.filter((item) => item !== id) });
    }
  };

  // State for the Location Tiles modal
  const [isOpenLocationTileModal, setIsLocationTileModal] = useState(false);

  // Close the Location Tiles modal
  const handleCloseLocationTilesModal = () => {
    setIsLocationTileModal(!isOpenLocationTileModal);
  };

  const [editLocationTileModal, setLocationTileModal] = useState(false);

  const handleEditTile = () => {
    setLocationTileModal(!editLocationTileModal);
  };

  const [viewLocationTileModal, setviewLocationTileModal] = useState(false);

  const handleViewTile = (value) => {
    if (viewLocationTileModal?._id === value?._id) {
      setviewLocationTileModal(false);
      setViewData(null);
    } else {
      setviewLocationTileModal(true);
      setViewData(value);
    }
  };

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'], // Include the 'link' option for adding links
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ font: [] }],
      [{ align: [] }],
      ['image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'color',
    'background',
    'code-block',
    'script',
  ];

  const [content, setContent] = useState(
    `Bold Question
    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
    nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
    culpa qui officia deserunt mollit anim id est laborum.`
  );

  const handleChange = (value) => {
    setContent(value);
  };

  const ReadMore = ({ text, lines }) => {
    const [showFullText, setShowFullText] = useState(false);

    // Split the text into lines
    const textLines = text.split('\n');

    // Display only the first `lines` lines if not showing the full text
    const displayText = showFullText ? text : textLines.slice(0, lines).join('\n');

    return (
      <div>
        <Typography dangerouslySetInnerHTML={{ __html: displayText }} color={'grey'} variant="subtitle2" />
        {textLines.length > lines && (
          <Button variant="text" onClick={() => setShowFullText(!showFullText)}>
            {showFullText ? 'Read Less' : 'Read More'}
          </Button>
        )}
      </div>
    );
  };

  const [selectedCity, setSlectedCity] = useState('');
  const [editSelectedCity, setEditSelectedCity] = useState('');

  const handleFaqSelect = (id) => {
    const faq = locationFromData?.faq || [];

    if (!faq.includes(id)) {
      setLocationFromData({ ...locationFromData, faq: [...faq, id] });
    } else {
      setLocationFromData({ ...locationFromData, faq: faq.filter((item) => item !== id) });
    }
  };

  const HandlesearchFaqList = (array, text) => {
    const faqNoNSelectedList = array.filter((x) => !editLocationFormData?.faq?.includes(x?._id));
    console.log(faqNoNSelectedList);
    const searchedFilterData = faqNoNSelectedList?.filter((x) =>
      x?.question?.toLowerCase()?.includes(text?.toLowerCase())
    );
    return searchedFilterData;
  };

  const handleFormSubmit = async (formdata) => {
    try {
      const response = await instance.post('/popular-location/create', formdata);

      if (response.status === 200) {
        Swal.fire({ icon: 'success', text: 'Successfully created' });
        dispatch(GetLocationsAction());
        setIsAddFaq(false);
      } else if (response.status === 409) {
        Swal.fire({ icon: 'error', text: 'Already a data with this URL exists' });
      } else {
        Swal.fire({ icon: 'error', text: 'An error occurred while processing your request' });
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        Swal.fire({ icon: 'error', text: 'Already registered with this URL' });
      } else {
        Swal.fire({ icon: 'error', text: 'An error occurred while processing your request' });
      }

      console.error(error);
    }
  };

  const handleEditSubmit = async (id, formdata) => {
    console.log(id, formdata);
    try {
      const response = await instance.patch(`/popular-location/update/${id}`, formdata);

      if (response.status === 200) {
        Swal.fire({ icon: 'success', text: 'Successfully created' });
        dispatch(GetLocationsAction());
        setLocationTileModal(false);
      } else if (response.status === 409) {
        Swal.fire({ icon: 'error', text: 'Already a data with this URL exists' });
      } else {
        Swal.fire({ icon: 'error', text: 'An error occurred while processing your request' });
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        Swal.fire({ icon: 'error', text: 'Already registered with this URL' });
      } else {
        Swal.fire({ icon: 'error', text: 'An error occurred while processing your request' });
      }

      console.error(error);
    }
  };

  const handleDeleteLocation = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await instance.delete(`/popular-location/delete/${id}`);
        if (response.status === 200) {
          dispatch(GetLocationsAction());
          Swal.fire({
            title: 'Deleted!',
            text: 'Your file has been deleted.',
            icon: 'success',
          });
        } else {
          Swal.fire({
            title: 'Filed!',
            text: 'Deletion Failed try again',
            icon: 'error',
          });
        }
      }
    });
  };

  useEffect(() => {
    if (selectedCity) {
      geocodeByPlaceId(selectedCity?.value?.place_id).then((result) => {
        const { lat, lng } = result[0].geometry.location;
        const latitude = lat();
        const longitude = lng();
        setLocationFromData({
          ...locationFromData,
          location: { coordinates: [longitude, latitude] },
          address: selectedCity.label,
        });
      });
    }
    if (editSelectedCity) {
      geocodeByPlaceId(editSelectedCity?.value?.place_id).then((result) => {
        const { lat, lng } = result[0].geometry.location;
        const latitude = lat();
        const longitude = lng();
        setEditLocationFormData({
          ...editLocationFormData,
          location: { coordinates: [longitude, latitude] },
          address: selectedCity.label,
        });
      });
    }
  }, [selectedCity, editSelectedCity]);

  useEffect(() => {
    dispatch(GetLocationsAction());
  }, []);

  useEffect(() => {
    dispatch(GetFaqByUser({ user: currentUser?._id }));
  }, [currentUser]);

  useEffect(() => {
    if (viewData) {
      const endpoint = viewData?.endpoint;
      const address = viewData?.address;
      const location = viewData?.location;
      const faq = viewData?.faq?.map((x) => x?._id);
      const discriptions = viewData?.discriptions;
      const title = viewData?.title;
      const meta = viewData?.meta;
      setEditLocationFormData({ endpoint, address, location, faq, discriptions, title, meta });
    }
  }, [viewData]);

  const [searchLocationTile, setSearchLocationTile] = useState('');
  const [searchFaqInput, setSearchFaqInput] = useState('');
  const [addFaqSearchInput, setAddFaqSearchInput] = useState('');

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Bread icon={'wpf:faq'} tabName={'Location wise FAQ'} />
        {isOpenAddFaq || editLocationTileModal ? null : (
          <>
            <TextField
              label="Search"
              variant="outlined"
              value={searchLocationTile}
              onChange={(e) => setSearchLocationTile(e.target.value)}
            />
            <Button variant="contained" onClick={handleCloseAddFaqModal}>
              Add Location
            </Button>
          </>
        )}
      </Box>
      {editLocationTileModal ? (
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Box>
              <Typography py={2} variant="h6">
                Edit Location
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label="Enter Url Name"
                    fullWidth
                    helperText="Ex-: hotels-in-lucknow"
                    value={editLocationFormData.endpoint || ''}
                    onChange={(e) => handleEditLocationForm(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <GooglePlacesAutocomplete
                    apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    apiOptions={{ language: 'en', region: 'in', libraries: 'places' }}
                    selectProps={{
                      value: editSelectedCity,
                      onChange: setEditSelectedCity,
                      styles: {
                        input: (provided) => ({
                          ...provided,
                          padding: '10px',
                        }),
                        option: (provided) => ({
                          ...provided,
                          color: '#ee2e24',
                        }),
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    label="latitude"
                    fullWidth
                    disabled
                    value={editLocationFormData?.location?.coordinates[1]}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="text"
                    label="longitude"
                    fullWidth
                    disabled
                    value={editLocationFormData?.location?.coordinates[0]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextareaAutosize
                    style={{ width: '100%' }}
                    maxRows={5}
                    minRows={5}
                    disabled
                    placeholder="Write full Address"
                    value={editLocationFormData?.address}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactQuill
                    theme="snow"
                    value={editLocationFormData?.discriptions || ''}
                    onChange={(value) => {
                      handleEditLocationForm('discriptions', value);
                    }}
                    modules={modules}
                    formats={formats}
                    style={{ borderRadius: '8px' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    label="Title"
                    name="title"
                    fullWidth
                    helperText="Ex : Hotel In Lucknow | Hotelio"
                    value={editLocationFormData.title || ''}
                    onChange={(e) => {
                      handleEditLocationForm(e.target.name, e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="meta"
                    fullWidth
                    label="Tags"
                    helperText="Enter all <meta> Tags Here"
                    value={editLocationFormData.meta || ''}
                    onChange={(e) => {
                      handleEditLocationForm(e.target.name, e.target.value);
                      console.log(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleEditSubmit(viewData?._id, editLocationFormData);
                    }}
                  >
                    Save Changes
                  </Button>
                  <Button variant="outlined" onClick={handleEditTile} sx={{ ml: 1 }}>
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{ border: '2px solid #ee2e24', borderRadius: '8px', p: 1, minHeight: '70vh', overflowY: 'scroll' }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="subtitle2">Select FAQ</Typography>
                <Button sx={{ my: 1 }} variant="contained" size="small" onClick={handleCloseLocationTilesModal}>
                  Add FAQ
                </Button>
              </Box>
              <TextField
                sx={{ mb: 1 }}
                label="Search FAQ"
                value={searchFaqInput}
                onChange={(e) => setSearchFaqInput(e.target.value)}
                variant="outlined"
                fullWidth
              />
              <h6
                className="d-flex align-items-center text-danger"
                style={{ margin: '0px 0px', color: 'red', padding: '5px 0px' }}
              >
                Selected Faq
              </h6>
              {allFaqList?.data?.data

                ?.filter((x) => editLocationFormData?.faq?.includes(x?._id))
                .map((item, index) => (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel1-content`}
                      id={`panel1-header`}
                    >
                      <Typography fontWeight={800}>{item.question}</Typography>
                      <IconButton sx={{ ml: 'auto' }} onClick={(e) => handleAddFAQ(e, item?._id)} color="secondary">
                        {editLocationFormData?.faq.includes(item?._id) ? (
                          <CancelIcon color="error" />
                        ) : (
                          <AddIcon color="error" />
                        )}
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                        color={'grey'}
                        variant="subtitle2"
                      />
                    </AccordionDetails>
                  </Accordion>
                ))}
              <h6
                className="d-flex align-items-center text-danger"
                style={{ margin: '0px 0px', color: 'red', padding: '5px 0px ' }}
              >
                All Faq
              </h6>
              {HandlesearchFaqList(allFaqList?.data?.data, searchFaqInput)?.map((item, index) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel1-content`}
                    id={`panel1-header`}
                  >
                    <Typography fontWeight={800}>{item.question}</Typography>
                    <IconButton sx={{ ml: 'auto' }} onClick={(e) => handleAddFAQ(e, item?._id)} color="secondary">
                      {editLocationFormData?.faq.includes(item?._id) ? (
                        <CancelIcon color="error" />
                      ) : (
                        <AddIcon color="error" />
                      )}
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography dangerouslySetInnerHTML={{ __html: item.answer }} color={'grey'} variant="subtitle2" />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
            <GlobalModel
              open={isOpenLocationTileModal}
              handleClose={handleCloseLocationTilesModal}
              content={
                <Fade in={isOpenLocationTileModal}>
                  <Box sx={style}>
                    <Typography py={2} variant="h6">
                      Add Location
                    </Typography>
                    <AddFaqFunction />
                    <Button variant="contained" onClick={handleCloseLocationTilesModal}>
                      Close
                    </Button>
                  </Box>
                </Fade>
              }
            />
          </Grid>
        </Grid>
      ) : (
        <Grid spacing={1} container>
          {isOpenAddFaq ? (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Box>
                    <Typography py={2} variant="h6">
                      Add Location
                    </Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          label="Enter Url Name"
                          fullWidth
                          name="endpoint"
                          value={locationFromData.endpoint || ''}
                          helperText="Ex-: hotels-in-lucknow"
                          onChange={(e) => {
                            handleLocationFormCreation(e.target.name, e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sx={{ zIndex: 2 }}>
                        <GooglePlacesAutocomplete
                          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                          apiOptions={{ language: 'en', region: 'in', libraries: 'places' }}
                          selectProps={{
                            value: selectedCity,
                            onChange: setSlectedCity,
                            styles: {
                              input: (provided) => ({
                                ...provided,
                                padding: '10px',
                              }),
                              option: (provided) => ({
                                ...provided,
                                color: '#ee2e24',
                              }),
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="text"
                          placeholder="latitude"
                          fullWidth
                          disabled
                          value={locationFromData?.location?.coordinates[1]}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          type="text"
                          placeholder="longitude"
                          fullWidth
                          disabled
                          value={locationFromData?.location?.coordinates[0]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextareaAutosize
                          style={{ width: '100%' }}
                          maxRows={5}
                          minRows={5}
                          disabled
                          value={locationFromData?.address}
                          placeholder="Write full Address"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ReactQuill
                          theme="snow"
                          value={locationFromData.discriptions || ''}
                          onChange={(value) => handleLocationFormCreation('discriptions', value)}
                          modules={modules}
                          formats={formats}
                          style={{ borderRadius: '8px' }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          type="text"
                          label="Title"
                          name="title"
                          fullWidth
                          helperText="Ex : Hotel In Lucknow | Hotelio"
                          value={locationFromData.title || ''}
                          onChange={(e) => {
                            handleLocationFormCreation(e.target.name, e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="meta"
                          fullWidth
                          label="Tags"
                          helperText="Enter all <meta> Tags Here"
                          value={locationFromData.meta || ''}
                          onChange={(e) => {
                            handleLocationFormCreation(e.target.name, e.target.value);
                            console.log(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          onClick={() => {
                            handleFormSubmit(locationFromData);
                          }}
                        >
                          Save
                        </Button>
                        <Button variant="outlined" onClick={handleCloseAddFaqModal} sx={{ ml: 1 }}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    sx={{
                      border: '2px solid #ee2e24',
                      borderRadius: '8px',
                      p: 1,
                      minHeight: '60vh',
                      overflowY: 'scroll',
                    }}
                  >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="subtitle2">Select FAQ</Typography>
                      <Button sx={{ my: 1 }} variant="contained" size="small" onClick={handleCloseLocationTilesModal}>
                        Add FAQ
                      </Button>
                    </Box>
                    <TextField
                      sx={{ mb: 1 }}
                      label="Search FAQ"
                      value={addFaqSearchInput}
                      onChange={(e) => setAddFaqSearchInput(e.target.value)}
                      variant="outlined"
                      fullWidth
                    />
                    {allFaqList?.data?.data
                      ?.filter((item) => item?.question?.toLowerCase().includes(addFaqSearchInput?.toLowerCase()))
                      .map((item, index) => (
                        <Accordion key={index}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel1-content`}
                            id={`panel1-header`}
                          >
                            <Typography fontWeight={800}>{item?.question}</Typography>
                            <IconButton
                              sx={{ ml: 'auto' }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleFaqSelect(item?._id);
                              }}
                              color="secondary"
                            >
                              {locationFromData?.faq?.includes(item?._id) ? (
                                <CancelIcon color="error" />
                              ) : (
                                <AddIcon color="error" />
                              )}
                            </IconButton>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              dangerouslySetInnerHTML={{ __html: item.answer }}
                              color={'grey'}
                              variant="subtitle2"
                            />
                          </AccordionDetails>
                        </Accordion>
                      ))}
                  </Box>
                  <GlobalModel
                    open={isOpenLocationTileModal}
                    handleClose={handleCloseLocationTilesModal}
                    content={
                      <Fade in={isOpenLocationTileModal}>
                        <Box sx={style}>
                          <Typography py={2} variant="h6">
                            Add Location
                          </Typography>
                          <AddFaqFunction />
                          <Button variant="contained" onClick={handleCloseLocationTilesModal}>
                            Close
                          </Button>
                        </Box>
                      </Fade>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              {AllLocations?.data?.data
                ?.filter((x) => x?.endpoint?.toLowerCase()?.includes(searchLocationTile))
                .map((item, index) => (
                  <Grid key={index} item m={1} xs={4}>
                    <Card onClick={() => handleViewTile(item)} sx={{ border: '1px solid #ee2e24', cursor: 'pointer' }}>
                      <CardContent
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#ee2e24',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <Typography fontWeight={700} variant="subtitle2">
                          {item?.endpoint}{' '}
                        </Typography>
                        <div className="d-flex gap-2">
                          <Trash
                            color="#f00"
                            size={23}
                            style={{ marginRight: '5px' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteLocation(item._id);
                            }}
                          />
                          <BorderColorIcon onClick={handleViewTile} />
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              <GlobalModel
                open={viewLocationTileModal}
                handleClose={handleViewTile}
                content={
                  <Fade in={viewLocationTileModal}>
                    <Box sx={style}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography py={2} variant="h6">
                          Website Preview
                        </Typography>
                        <Button variant="contained" onClick={handleEditTile}>
                          <EditIcon />
                        </Button>
                      </Box>
                      <Box py={1}>
                        <Typography
                          dangerouslySetInnerHTML={{ __html: viewData?.discriptions }}
                          color={'grey'}
                          variant="subtitle2"
                        />
                        {viewData?.faq?.map((item, index) => (
                          <Accordion key={index}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              <Typography>{item?.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                dangerouslySetInnerHTML={{ __html: item.answer }}
                                color={'grey'}
                                variant="subtitle2"
                              />
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </Box>
                      <Button variant="contained" onClick={handleViewTile}>
                        Close
                      </Button>
                    </Box>
                  </Fade>
                }
              />
            </>
          )}
        </Grid>
      )}
    </div>
  );
};

export default ManageFaq;
