import * as constant from '../Constants/RoomConfigConstant';

const initialState = {
  loading: false,
  error: false,
  data: null,
};

const GetAllRoomConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_ROOMS_CONFIG_LOADING:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_ROOMS_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case constant.GET_ROOMS_CONFIG_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
};

const GetRoomStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.GET_STATS_CONFIG_LOADING:
      return {
        ...state,
        loading: true,
      };
    case constant.GET_STATS_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case constant.GET_STATS_CONFIG_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
      };

    default:
      return {
        ...state,
      };
  }
};

export { GetAllRoomConfigReducer, GetRoomStatsReducer };
