import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Fade, Grid, Input, Typography, Chip } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'; // Import CancelRoundedIcon
import GlobalModel from '../GlobalModal/GlobalModel';
import WaitLoader from '../../utils/WaitLoader';

const AddFacilitiesModal = ({
  openFacilities,
  handleCloseFacilities,
  loader,
  style,
  selectedAdditonalFacilities,
  setSelectedAddtionalFacilities,
  hotelData,
  expanded,
  HandleAdditionalFacilities,
}) => {
  const [value, setValue] = useState('');

  const roomData = hotelData?.rooms?.find((x) => x?._id === expanded);

  const HandleAddAmenities = (value) => {
    if (selectedAdditonalFacilities.includes(value)) {
      window.alert('This Amenity is Already you have added');
    } else {
      setSelectedAddtionalFacilities([...selectedAdditonalFacilities, value]);
      setValue('');
    }
  };

  useEffect(() => {
    if (roomData) {
      setSelectedAddtionalFacilities([]);
      setSelectedAddtionalFacilities([...roomData?.additionalFacilties]);
    }
  }, [expanded, openFacilities]);

  return (
    <GlobalModel
      open={openFacilities}
      handleClose={handleCloseFacilities}
      content={
        <Fade in={openFacilities}>
          <Box sx={style}>
            {/* <WaitLoader Loading={loader} /> */}
            <Typography variant="h6">Add Additional Facilities</Typography>
            <hr />
            <div style={{ paddingTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid xs={6} item>
                  <div>
                    <Input
                      type="text"
                      placeholder="Type here"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                    <Button variant="contained" onClick={() => HandleAddAmenities(value)}>
                      Add Option
                    </Button>
                  </div>
                </Grid>
                <Grid xs={6} item>
                  <div>
                    <Box>
                      {selectedAdditonalFacilities?.map((item, index) => (
                        <Chip
                          key={index}
                          label={item}
                          sx={{ m: 1 }}
                          onDelete={() =>
                            setSelectedAddtionalFacilities([...selectedAdditonalFacilities.filter((x) => x !== item)])
                          }
                          deleteIcon={<CancelRoundedIcon />}
                          variant="outlined"
                        />
                      ))}
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <LoadingButton
                sx={{ m: 1 }}
                variant="contained"
                onClick={() => HandleAdditionalFacilities(hotelData?._id, expanded, selectedAdditonalFacilities)}
                color="primary"
                loading={loader}
                type="submit"
              >
                Save
              </LoadingButton>
              <Button onClick={handleCloseFacilities} sx={{ m: 1 }} variant="outlined" color="primary">
                Cancel
              </Button>
            </div>
          </Box>
        </Fade>
      }
    />
  );
};

export default AddFacilitiesModal;
