import Swal from 'sweetalert2';
import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { Box, Button, ButtonGroup, Card, CardContent, Fade, Grid, Menu, MenuItem, Switch, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GetAllVendors } from '../../Store/Actions/VendorsActions/GetAllVendorAction';
import instance from '../../utils/_utils';
import GlobalModel from '../GlobalModal/GlobalModel';

export default function VendorTable() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [vendorProfile, setVendorProfile] = React.useState(false)

  const [activeVendor, setActiveVendor] = React.useState(null)
  const handelVendorProfile = (id) => {
    setActiveVendor(id)
    setVendorProfile(!vendorProfile)
  }
  const [updateProfile, setupdateProfile] = React.useState(false)
  const handelVendorEditProfile = (id) => {
    setActiveVendor(id)
    setupdateProfile(!updateProfile)
  }
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '800',
    maxHeight: '800px',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 1,
  };

  const DataWithID = (data) => {
    const newData = [];
    if (data !== undefined) {
      data.forEach((item, index) => {
        newData.push({ ...item, id: index + 1 });
      });
    } else {
      newData.push({ id: 0 });
    }
    return newData;
  };

  // Delele the Vendor
  //  Delete the vendor
  const DeleteTheVendor = async (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await instance.get(`/api/vendor/delete/${id}`);
          console.log(`/api/vendor/delete/${id}`);
          if (response.status === 200) {
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
            dispatch(GetAllVendors());
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  const [isActive, setIsActive] = React.useState(false);

  const handleSwitchToggle = () => {
    setIsActive(!isActive);
  };

  // reduceer data
  const { data } = useSelector((pre) => pre.GetAllVendorsReducer);

  // call the data
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(GetAllVendors());
  }, []);



  const vendorSingleData = data?.data?.find((item) => item._id === activeVendor)

  const [formData, setFormData] = React.useState({
    name: vendorSingleData?.name,
    mobileNo: vendorSingleData?.mobileNo,
    email: vendorSingleData?.email,

  })
  const handleFormData = (e) => {
    setFormData({ ...formData, [e.target.naem]: e.target.value })
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    {
      field: 'name',
      headerName: 'Partner Name',
      width: 200,
      renderCell: (params) => (
        <div style={{ display: 'flex' }}>
          {/* <img width={50} height={50} style={{ borderRadius: '50%' }} style={{ borderRadius: '50%' }} src={params.row.image} alt=".." /> */}
          <div style={{ paddingLeft: '5px' }}>
            <p>{params.row.name}</p>
          </div>
        </div>
      ),
    },
    {
      field: 'properties',
      headerName: 'No of Property',
      width: 150,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ApartmentIcon />
          &nbsp; <p style={{ margin: '0 0px' }}>{params.row.hotels?.length}</p>
        </div>
      ),
    },
    {
      field: 'hotelDetail',
      headerName: 'Partner Contact',
      width: 150,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0 0px' }}>{params.row.mobileNo}</p>
          <p style={{ margin: '0 0px' }}>{params.row.email}</p>
        </div>
      ),
    },
    {
      field: 'hotelLocation',
      headerName: 'Partner Location',
      width: 200,
      renderCell: (params) => (
        <div>
          {params.row.partnerCountry ? (
            <div>
              <p style={{ margin: '0 0px' }}>
                {params.row.streetName} ,{params.row.partnerCity}
              </p>
              <p style={{ margin: '0 0px' }}>
                {params.row.partnerCountry}, {params.row.partnerPinCode}
              </p>
            </div>
          ) : (
            'NA'
          )}
        </div>
      ),
    },
    {
      field: 'hotelDetails',
      headerName: 'Partner Details',
      width: 200,
      renderCell: (params) => (
        <div style={{ padding: '10px 0px' }}>
          <p style={{ margin: '0 0px' }}>UID: {params.row.aadharNo}</p>
          <p style={{ margin: '0 0px' }}>PAN: {params.row.panNo}</p>
        </div>
      ),
    },
    {
      field: 'hotelStatus',
      headerName: 'Partner Status',
      width: 200,
      renderCell: (params) => <div style={{ padding: '10px 0px', color: 'green' }}>Active</div>,
    },
    {
      field: 'action',
      headerName: 'Action',
      headerAlign: 'center',
      width: 150,
      renderCell: (params) => (
        <div>
          <Button>
            <VisibilityIcon onClick={() => handelVendorProfile(params.row?._id)} color="success" />
          </Button>
          {/* <Button>
            <EditIcon onClick={() => handelVendorEditProfile(params.row?._id)} />
          </Button> */}
          <Button onClick={() => DeleteTheVendor(params.row._id)}>
            <DeleteForeverIcon color="error" />
          </Button>
        </div>
      ),
    },
  ];

  const [profile, setProfile] = React.useState(true)


  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      const response = await instance.post(`/api/vendor/update/${vendorSingleData._id}`, formData);

      if (response.status === 200) {
        Swal.fire('Success', 'Vendor profile updated successfully!', 'success');
        dispatch(GetAllVendors());
        setupdateProfile(false);
      } else {
        Swal.fire('Error', 'Failed to update vendor profile', 'error');
      }
    } catch (error) {
      console.error(error);
      Swal.fire('Error', 'An error occurred while updating the vendor profile', 'error');
    }
  };

  return (
    <div style={{ minheight: '100vh', width: '100%', marginTop: '10px' }}>

      {/* // Modal Vendor  */}

      <GlobalModel
        open={updateProfile}
        handleClose={handelVendorEditProfile}
        content={
          <Fade in={updateProfile}>
            <Box sx={style}>
              <Typography sx={{ py: 1 }} variant='h5'>Vendor Edit Form</Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name || ''}
                      onChange={handleFormData}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      label="Mobile No."
                      name="contact"
                      variant="outlined"
                      value={vendorSingleData?.mobileNo}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Email"
                      name="email"
                      variant="outlined"
                      value={vendorSingleData?.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      label="Location"
                      name="location"
                      variant="outlined"
                      value={'Location'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      label="Aadhar"
                      name="aadhar"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      required
                      fullWidth
                      label="PAN"
                      name="pan"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div>Upload Aadhar Card (Front)</div>
                    <input
                      accept="image/*"
                      id="aadharFront"
                      type="file"
                      style={{ display: 'none' }}
                    />
                    <div htmlFor="aadharFront">
                      <Button variant="contained" component="span">
                        Upload
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>Upload Aadhar Card (Back)</div>
                    <input
                      accept="image/*"
                      id="aadharBack"
                      type="file"
                      style={{ display: 'none' }}
                    />
                    <div htmlFor="aadharBack">
                      <Button variant="contained" component="span">
                        Upload
                      </Button>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>Upload PAN Card</div>
                    <input
                      accept="image/*"
                      id="panCard"
                      type="file"
                      style={{ display: 'none' }}
                    />
                    <div htmlFor="panCard">
                      <Button variant="contained" component="span">
                        Upload
                      </Button>
                    </div>
                  </Grid>
                </Grid>
                <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                  <Button onClick={handelVendorEditProfile} variant="contained" color="primary">
                    Cancel
                  </Button>
                </Box>
              </form>
            </Box>
          </Fade>
        }
      />

      {/* // Modal vendor profile */}

      <GlobalModel
        open={vendorProfile}
        handleClose={handelVendorProfile}
        content={
          <Fade in={vendorProfile}>
            <Box sx={style}>
              <ButtonGroup variant="outlined" aria-label="outlined button group">
                <Button variant={profile === true && 'contained'} onClick={() => setProfile(true)} >Profile</Button>
                <Button variant={profile === false && 'contained'} onClick={() => setProfile(false)} >Documents</Button>
              </ButtonGroup>
              {profile ?
                <div style={{ padding: '15px', width: '600px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ py: 1 }} variant='h5'>Name :</Typography>
                    <div style={{ textAlign: 'left', width: '50%' }}>
                      <Typography variant='p'>{vendorSingleData?.name}</Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ py: 1 }} variant='h5'>Status :</Typography>
                    <div style={{ textAlign: 'left', width: '50%' }}>
                      <Typography color='green' variant='p'>Active</Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ py: 1 }} variant='h5'>Contact No. :</Typography>
                    <div style={{ textAlign: 'left', width: '50%' }}>
                      <Typography variant='p'>{vendorSingleData?.mobileNo}</Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ py: 1 }} variant='h5'>Email Id. :</Typography>
                    <div style={{ textAlign: 'left', width: '50%' }}>
                      <Typography variant='p'>{vendorSingleData?.email}</Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ py: 1 }} variant='h5'>UID No. :</Typography>
                    <div style={{ textAlign: 'left', width: '50%' }}>
                      <Typography variant='p'>564545153485</Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ py: 1 }} variant='h5'>PAN No. :</Typography>
                    <div style={{ textAlign: 'left', width: '50%' }}>
                      <Typography variant='p'>NFCLU8755C</Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ py: 1 }} variant='h5'>Kyc Verified :</Typography>
                    <div style={{ textAlign: 'left', width: '50%' }}>
                      <Typography color='green' variant='p'>Verified</Typography>
                    </div>
                  </div>
                </div> : <div style={{ padding: '15px', width: '500px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h5'>Aadhar Card (Front) :</Typography>
                    <div style={{ width: '50%', border: '2px solid #ee2e24', borderRadius: '8px' }}>
                      <img style={{ borderRadius: '8px' }} src="https://i.pinimg.com/474x/ac/3b/19/ac3b195812909b9747bc43a95360a20c.jpg" alt="front" />
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0px' }}>
                    <Typography variant='h5'>Aadhar Card (Back) :</Typography>
                    <div style={{ width: '50%', border: '2px solid #ee2e24', borderRadius: '8px' }}>
                      <img style={{ borderRadius: '8px' }} src="https://online.fliphtml5.com/rqxuf/jbdo/files/large/2.jpg?1593885030" alt="back" />
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant='h5'>Pan Card :</Typography>
                    <div style={{ width: '50%', border: '2px solid #ee2e24', borderRadius: '8px' }}>
                      <img style={{ borderRadius: '8px' }} src="https://i.pinimg.com/736x/c4/b6/b5/c4b6b5933f35276583127ca0c207a08e.jpg" alt="pan" />
                    </div>
                  </div>
                </div>}
              <Box sx={{ m: 2, textAlign: 'right' }}>
                <Button onClick={handelVendorProfile} variant="contained" color="primary">
                  Close
                </Button>
              </Box>
            </Box>
          </Fade>
        }
      />
      <DataGrid
        rows={DataWithID(data.data)}
        columns={columns}
        density="comfortable" // Set default density to "comfortable"
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
