import axios from "axios"
import { API_URL } from "../../../config"
import * as constant from "../../Constants/adminConstants/kycConstants"
import instance from "../../../utils/_utils"




// List all the kyc 
export const GetListOfKyc = () => {
    return async (dispatch) => {
        dispatch({ type: constant.GET_ALL_LIST_KYC_LOADING })
        try {
            const response = await instance.get(`/kyc/getall`)
            if (response.status === 200) {
                dispatch({ type: constant.GET_ALL_LIST_KYC_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.GET_ALL_LIST_KYC_ERROR })
        }
    }
}



// action on kyc details

export const MakeActionOnKyc = (id, action) => {
    return async (dispatch) => {
        dispatch({ type: constant.MAKE_ACTION_KYC_LOADING })
        try {
            const queryData = new URLSearchParams({ id, action }).toString()
            const response = await instance.get(`/kyc/verify?${queryData}`)
            if (response.status === 200) {
                dispatch({ type: constant.MAKE_ACTION_KYC_SUCCESS, payload: response.data })
            }
        } catch (error) {
            dispatch({ type: constant.MAKE_ACTION_KYC_ERROR })
        }
    }
}