import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Switch } from '@mui/material';
import { Link } from 'react-router-dom';


export default function PaymentTable() {



    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        {
            field: 'name',
            headerName: 'Name',
            width: 150,
            renderCell: (params) => (
                <div>
                    <p>{params.row.name}</p>
                </div>
            )
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 180,
            renderCell: (params) => (
                <div>
                    <b>{params.row.code}</b>
                </div>
            )
        },
        {
            field: 'applicable',
            headerName: 'Applicable',
            width: 120
        },
        {
            field: 'discount',
            headerName: 'Discount',
            width: 100
        },
        {
            field: 'startDate',
            headerName: 'Start Date',
            width: 100
        },
        {
            field: 'expiry',
            headerName: 'Expiry',
            width: 100
        },
        {
            field: 'limit',
            headerName: 'Limit',
            width: 100
        },
        {
            field: 'claimed',
            headerName: 'Claimed',
            width: 100
        },
        {
            field: 'couponStatus',
            headerName: 'Coupons Status',
            width: 200,
            renderCell: (params) => (
                <div style={{ padding: '10px 0px' }}>
                    In-Active
                    <Switch defaultChecked={params.row.couponStatus} />
                    Active
                </div>
            )
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                <div style={{ display: 'flex' }}>
                    <EditIcon />
                    <DeleteForeverIcon color='error' />
                </div>
            )
        }

    ];

    const rows = [
        {
            id: 1,

        }
    ];

    return (
        <div style={{ minheight: '100vh', width: '100%', marginTop: '8px' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                density="comfortable" // Set default density to "comfortable"
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                components={{
                    Toolbar: GridToolbar,
                }}
            />
        </div>
    );
}