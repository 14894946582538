import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import {
    Box,
    TextField,
    Chip,
    Grid, Typography
} from '@mui/material';
import { GetAllNotificationEventAction } from '../Store/Actions/Notification/NotificationActions';

const Notification = () => {

    const navigation = useNavigate()
    const dispatch = useDispatch();
    const NotificationEvents = useSelector((state) => state.GetAllNotificationEventReducer)

    React.useEffect(() => {
        dispatch(GetAllNotificationEventAction())
    }, [])

    return (
        <div>
            <Typography variant="h4" color="initial">Notifications Events</Typography>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            id="search"
                            label="Search"
                            type='search'
                            fullWidth
                            variant="outlined" />
                    </Grid>
                    <Grid item xs={12} >
                        <Box >
                            {
                                NotificationEvents?.data?.map((item, index) => (
                                    <Chip onClick={() => navigation(`${item?._id}`)} sx={{ m: 1 }} key={index} label={item?.title} />
                                ))
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default Notification