import * as React from 'react';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, IconButton, Switch } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { UseStateManager } from '../../Context/StateManageContext';
import { GetUpdateTheHotelData, GetUserHotelData } from '../../Store/Actions/HotelActions/HotelCurdAction';
import { GetAllRoomType } from '../../Store/Actions/AdminActions/allRoomType';
import instance from '../../utils/_utils';
import { GetVendorByIdAction } from '../../Store/Actions/VendorsActions/GetVendorByID';

export default function VendorHotel({ handleOpen }) {
  const { currentUser } = UseStateManager();
  const dispatch = useDispatch();
  const location = useLocation();
  const hotels = useSelector((state) => state.GetAllHotelReducer);
  const roomTypes = useSelector((state) => state.AllRoomTypeReducers);
  const updateHotel = useSelector((state) => state.UpdateHotelReducers)
  const currentPathname = location.pathname;
  React.useEffect(() => {
    dispatch(GetUserHotelData(currentUser?._id, currentUser?.role));
    dispatch(GetAllRoomType());
  }, [currentUser]);

  const DataWithID = (data) => {
    const newData = [];
    if (data !== undefined) {
      data.forEach((item, index) => {
        newData.push({ ...item, id: index + 1 });
      });
    } else {
      newData.push({ id: 0 });
    }
    return newData;
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <GridToolbarDensitySelector />
        {currentPathname === '/dashboard/app' ? null :
          <Button onClick={handleOpen} variant='contained'>Add Hotel</Button>}
      </GridToolbarContainer>
    );
  };

  // Hotel delete by id

  async function deleteHotelById(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          console.log(id);
          const query = new URLSearchParams({ hotelId: id }).toString();
          const response = await instance.get(`/hotel/deletesingle?${query}`);
          if (response.status === 200) {
            dispatch(GetUserHotelData(currentUser?._id, currentUser?.role));
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          }
        } catch (error) {
          console.error('An error occurred:', error);
        }
      }
    });
  }

  // status change
  const ShowRoomStatus = (value) => {
    let title = '';
    let icon = '';

    if (value === true) {
      title = 'Room is Now <span style="color: green;">Active</span>';
      icon = 'success';
    } else {
      title = 'Room is <span style="color: red;">In-Active</span> Now';
      icon = 'error';
    }

    Swal.fire({
      title,
      icon,
      showConfirmButton: false,
      timer: 2000, // Adjust the time (in milliseconds) you want the alert to be displayed before automatically closing.
    });
  };

  const handleUpdate = async (e, id, formData) => {
    await dispatch(GetUpdateTheHotelData(id, formData)).then(async () => {
      if (updateHotel.data) {
        await dispatch(GetUserHotelData(currentUser?._id, currentUser?.role)).then(() => {
          ShowRoomStatus(e);
        }).catch((error) => {
          console.log(error)
        });
      }
    }).catch((error) => {
      console.log(error)
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'hotelName',
      headerName: 'Hotel Name',
      width: 250,
      renderCell: (params) => (
        <div style={{ display: 'flex' }}>
          <img
            style={{
              minWidth: '80px',
              height: '60px',
              objectFit: 'cover',
              borderRadius: '15px',
            }}
            src={`${params.row.hotelCoverImg}`}
            alt="hotelImage"
          />
          <div style={{ paddingLeft: '5px' }}>
            <Link to={`/vendorName/${params.row._id}`}>
              <b>{params.row.hotelName}</b>
            </Link>
          </div>
        </div>
      ),
    },
    {
      field: 'hotelDetail',
      headerName: 'Hotel Contact',
      width: 150,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0 0px' }}>{params.row.hotelMobileNo}</p>
          <p style={{ margin: '0 0px' }}>{params.row.hotelEmail}</p>
        </div>
      ),
    },
    {
      field: 'hotelLocation',
      headerName: 'Hotel Location',
      width: 200,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0 0px' }}>
            {params.row?.locality || 'NA'} ,{params.row.city}
          </p>
          <p style={{ margin: '0 0px' }}>
            {params.row.country}, {params.row.zipCode}
          </p>
        </div>
      ),
    },
    {
      field: 'room',
      headerName: 'Room',
      width: 200,
      height: 700,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', padding: '2px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {params.row.rooms.map((item, index) => (
              <div style={{ display: 'flex' }}>
                <SingleBedIcon />{' '}
                <p style={{ margin: '0 0px' }}>
                  {item?.counts} R, {item?.roomType?.title}
                </p>
              </div>
            ))}
          </div>
        </div>
      ),
    },
    {
      field: 'hotelDetails',
      headerName: 'Hotel Details',
      width: 200,
      renderCell: (params) => (
        <div style={{ padding: '10px 0px' }}>
          <p style={{ margin: '0 0px' }}>GST: {params.row.gstNo}</p>
          <p style={{ margin: '0 0px' }}>PAN: {params.row.businessPan}</p>
        </div>
      ),
    },
    {
      field: 'hotelStatus',
      headerName: 'Hotel Status',
      width: 200,
      renderCell: (params) => (
        <div style={{ padding: '10px 0px' }}>
          In-Active
          <Switch
            checked={params?.row?.status}
            onChange={(e) => handleUpdate(!params?.row?.status, params.row._id, { status: !params.row.status })}
          />
          Active
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <div>
          <IconButton href={`/vendorName/${params.row._id}`}>
            <VisibilityIcon color="success" />
          </IconButton>
          <IconButton href={`/vendorName/${params.row._id}`}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => deleteHotelById(params.row._id)}>
            <DeleteForeverIcon color="error" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div style={{ minheight: '100vh', width: '100%', marginTop: '10px' }}>
      <DataGrid
        rows={DataWithID(hotels.data)}
        columns={columns}
        loading={hotels?.isLoading}
        density="comfortable" // Set default density to "comfortable"
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        components={{ Toolbar: CustomToolbar }}
      />
    </div>
  );
}
