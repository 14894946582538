export const GET_OFFERS_API_LOADING = 'GET_OFFERS_API_LOADING';
export const GET_OFFERS_API_SUCCESS = 'GET_OFFERS_API_SUCCESS';
export const GET_OFFERS_API_ERROR = 'GET_OFFERS_API_ERROR';

export const CREATE_OFFERS_API_LOADING = 'CREATE_OFFERS_API_LOADING';
export const CREATE_OFFERS_API_SUCCESS = 'CREATE_OFFERS_API_SUCCESS';
export const CREATE_OFFERS_API_ERROR = 'CREATE_OFFERS_API_ERROR';

export const DELETE_OFFERS_API_LOADING = 'DELETE_OFFERS_API_LOADING';
export const DELETE_OFFERS_API_SUCCESS = 'DELETE_OFFERS_API_SUCCESS';
export const DELETE_OFFERS_API_ERROR = 'DELETE_OFFERS_API_ERROR';