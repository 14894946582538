import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { CurrencyRupee } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import { Avatar, Box, Card, CardContent, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import { ScaleLoader } from 'react-spinners';
import Header from '../layouts/dashboard/header';
import Nav from '../layouts/dashboard/nav';
import instance from '../utils/_utils';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

export default function BookingInfo() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [booking, setBooking] = useState(null);

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const res = await instance.get(`hotel/book/get-booking/${id}`);
        if (!res.data) {
          setLoading(false);
          return;
        }
        setBooking(res?.data?.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchBooking();
  }, [id]);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
            }}
          >
            <ScaleLoader color="#FF4081" loading={loading} />
          </Box>
        ) : booking ? (
          <Paper elevation={3} sx={{ padding: 4, margin: 'auto', maxWidth: 900, marginTop: 4 }}>
            <Typography variant="h4" gutterBottom>
              Booking Details
            </Typography>

            {/* Guest Details */}
            <Card sx={{ marginBottom: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Guest Information
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar>{booking?.guest?.name.charAt(0)}</Avatar>
                  <Box>
                    <Typography variant="body1">
                      <strong style={{ marginRight: '10px' }}>Name:</strong>{' '}
                      <Link to={`/dashboard/user/${booking?.customer}`}>{booking?.guest?.name}</Link>
                    </Typography>
                    <Typography variant="body1">
                      <strong style={{ marginRight: '10px' }}>Email:</strong> {booking?.guest?.email}
                    </Typography>
                    <Typography variant="body1">
                      <strong style={{ marginRight: '10px' }}>Mobile No:</strong> {booking?.guest?.mobileNo}
                    </Typography>
                  </Box>
                </Stack>
              </CardContent>
            </Card>

            {/* Booking Info */}
            <Card sx={{ marginBottom: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Booking Information
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong style={{ marginRight: '10px' }}>Hotel Name:</strong>{' '}
                      <Link to={`/vendorName/${booking?.hotelDetails?._id}`}>{booking?.hotelDetails?.hotelName}</Link>
                    </Typography>
                    <Typography variant="body1">
                      <strong style={{ marginRight: '10px' }}>Room Type:</strong> {booking?.room.roomType}
                    </Typography>
                    <Typography variant="body1">
                      <strong style={{ marginRight: '10px' }}>No. of Rooms:</strong> {booking?.numberOfRooms}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1">
                      <strong style={{ marginRight: '10px' }}>Booking ID:</strong> {booking?.bookingId}
                    </Typography>
                    <Typography variant="body1">
                      <strong style={{ marginRight: '10px' }}>Status:</strong> {booking?.bookingStatus}
                    </Typography>
                    <Typography variant="body1">
                      <strong style={{ marginRight: '10px' }}>Source:</strong> {booking?.bookingSource}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                  <strong style={{ marginRight: '10px' }}>Date of Booking:</strong>{' '}
                  {new Date(booking?.dateOfBooking).toLocaleString()}
                </Typography>
                <Typography variant="body1">
                  <strong style={{ marginRight: '10px' }}>Cancellation Due Date:</strong>{' '}
                  {new Date(booking?.cancellationDueDate).toLocaleString()}
                </Typography>
              </CardContent>
            </Card>

            {/* Dates */}
            <Card sx={{ marginBottom: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Booking Dates
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
                <Typography variant="body1">
                  <strong style={{ marginRight: '10px' }}>Check-In:</strong>{' '}
                  {new Date(booking?.bookingDate?.checkIn).toLocaleDateString()}
                </Typography>
                <Typography variant="body1">
                  <strong style={{ marginRight: '10px' }}>Check-Out:</strong>{' '}
                  {new Date(booking?.bookingDate?.checkOut).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>

            {/* Discounts */}
            {booking?.discountInfo?.length > 0 && (
              <Card sx={{ marginBottom: 3 }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Discounts
                  </Typography>
                  <Divider sx={{ marginBottom: 2 }} />
                  {booking?.discountInfo?.map((discount) => (
                    <Typography key={discount._id} variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <strong style={{ marginRight: '10px' }}>{discount?.name}:</strong>{' '}
                      <CurrencyRupee fontSize="small" /> {discount?.amount}
                    </Typography>
                  ))}
                </CardContent>
              </Card>
            )}

            {/* Payment Info */}
            <Card sx={{ marginBottom: 3 }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Payment Information
                </Typography>
                <Divider sx={{ marginBottom: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <strong style={{ marginRight: '10px' }}>Payment Type:</strong> {booking?.payment?.paymentType}
                    </Typography>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <strong style={{ marginRight: '10px' }}>Paid Amount:</strong> <CurrencyRupee fontSize="small" />{' '}
                      {booking?.totalAmount - booking?.payment?.balanceAmt}
                    </Typography>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <strong style={{ marginRight: '10px' }}>Balance Amount:</strong>{' '}
                      <CurrencyRupee fontSize="small" /> {booking?.payment?.balanceAmt}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <strong style={{ marginRight: '10px' }}>Total Amount:</strong> <CurrencyRupee fontSize="small" />{' '}
                      {booking?.totalAmount}
                    </Typography>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <strong style={{ marginRight: '10px' }}>GST:</strong> <CurrencyRupee fontSize="small" />{' '}
                      {booking?.additionalCharges?.gst}
                    </Typography>
                    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center' }}>
                      <strong style={{ marginRight: '10px' }}>Service Fee:</strong> <CurrencyRupee fontSize="small" />{' '}
                      {booking?.additionalCharges?.serviceFee}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        ) : (
          <p>Booking not found!</p>
        )}
      </Main>
    </StyledRoot>
  );
}

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
