import * as constant from '../../Constants/HotelioCouponConstants/HotelioCouponContant';

const initialState = {
    loading: false,
    error: false,
    data: null,
};

const GetAllCouponReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_ALL_COUPON_API_LOADING:
            return {
                ...state,
                loading: true,
            };
        case constant.GET_ALL_COUPON_API_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case constant.GET_ALL_COUPON_API_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};


export { GetAllCouponReducer };
