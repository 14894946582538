import * as constant from '../../Constants/adminConstants/allamenitiesConstants'


const initialState = {
    isLoading: false,
    isError: false,
    data: null
}

const GetAllAmenities = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_ALL_AMENITIES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.GET_ALL_AMENITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.GET_ALL_AMENITIES_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}

const AddAmenitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.ADD_AMENITIES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.ADD_AMENITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.ADD_AMENITIES_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}

const RemoveAmenitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.REMOVE_AMENITIES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.REMOVE_AMENITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.REMOVE_AMENITIES_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}
const RemoveAllAmenitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.REMOVE_ALL_AMENITIES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.REMOVE_ALL_AMENITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.REMOVE_ALL_AMENITIES_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}

export { GetAllAmenities, AddAmenitiesReducer, RemoveAmenitiesReducer, RemoveAllAmenitiesReducer }