import instance from '../../../utils/_utils'
import * as constant from '../../Constants/adminConstants/allamenitiesConstants'


export const GetAllAmenities = () => {
    return async (dispatch) => {
        dispatch({ type: constant.GET_ALL_AMENITIES_LOADING })
        try {
            const response = await instance.get(`/amenity/get`)
            if (response.status === 200) {
                dispatch({ type: constant.GET_ALL_AMENITIES_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.GET_ALL_AMENITIES_ERROR })
        }
    }
}

export const AddAmenitiesAction = (formData) => {
    return async (dispatch) => {
        dispatch({ type: constant.ADD_AMENITIES_LOADING })
        try {
            const response = await instance.post(`/amenity/add`, formData)
            if (response.status === 200) {
                dispatch({ type: constant.ADD_AMENITIES_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.ADD_AMENITIES_ERROR })
        }
    }
}

export const RemoveAmenitiesAction = (amenityId) => {
    return async (dispatch) => {
        dispatch({ type: constant.REMOVE_AMENITIES_LOADING })
        try {
            const response = await instance.get(`/amenity/delete/${amenityId}`)
            if (response.status === 200) {
                dispatch({ type: constant.REMOVE_AMENITIES_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.REMOVE_AMENITIES_ERROR })
        }
    }
}

export const RemoveAllAmenitiesAction = () => {
    return async (dispatch) => {
        dispatch({ type: constant.REMOVE_ALL_AMENITIES_LOADING })
        try {
            const response = await instance.delete(`/amenity/deleteall`)
            if (response.status === 200) {
                dispatch({ type: constant.REMOVE_ALL_AMENITIES_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.REMOVE_ALL_AMENITIES_ERROR })
        }
    }
}