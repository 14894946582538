import instance from '../../../utils/_utils'
import * as constant from '../../Constants/adminConstants/allFacilitiesConstants'


export const GetAllFacilities = () => {
    return async (dispatch) => {
        dispatch({ type: constant.GET_ALL_FACILITIES_LOADING })
        try {
            const response = await instance.get(`/facility/get`)
            if (response.status === 200) {
                dispatch({ type: constant.GET_ALL_FACILITIES_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.GET_ALL_FACILITIES_ERROR })
        }
    }
}

export const AddFacilitiesAction = (formData) => {
    console.log(formData)
    return async (dispatch) => {
        dispatch({ type: constant.ADD_FACILITIES_LOADING })
        try {
            const response = await instance.post(`/facility/add`, formData)
            if (response.status === 200) {
                dispatch({ type: constant.ADD_FACILITIES_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.ADD_FACILITIES_ERROR })
        }
    }
}

export const RemoveFacilitiesAction = (facilityId) => {
    console.log(facilityId)
    return async (dispatch) => {
        dispatch({ type: constant.REMOVE_FACILITIES_LOADING })
        try {
            const response = await instance.get(`/facility/delete/${facilityId}`)
            if (response.status === 200) {
                dispatch({ type: constant.REMOVE_FACILITIES_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.REMOVE_FACILITIES_ERROR })
        }
    }
}

export const RemoveAllFacilitiesAction = () => {
    return async (dispatch) => {
        dispatch({ type: constant.REMOVE_ALL_FACILITIES_LOADING })
        try {
            const response = await instance.delete(`/facility/deleteall`)
            if (response.status === 200) {
                console.log(response.data)
                dispatch({ type: constant.REMOVE_ALL_FACILITIES_SUCCESS, payload: response.data.data })
            }
        } catch (error) {
            dispatch({ type: constant.REMOVE_ALL_FACILITIES_ERROR })
        }
    }
}