export const CREATE_ROOM_TYPE_LOADING = "CREATE_ROOM_TYPE_LOADING";
export const CREATE_ROOM_TYPE_SUCCESS = 'CREATE_ROOM_TYPE_SUCCESS';
export const CREATE_ROOM_TYPE_ERROR = 'CREATE_ROOM_TYPE_ERROR';

export const GET_ALL_ROOM_TYPE_LOADING = "GET_ALL_ROOM_TYPE_LOADING";
export const GET_ALL_ROOM_TYPE_SUCCESS = 'GET_ALL_ROOM_TYPE_SUCCESS';
export const GET_ALL_ROOM_TYPE_ERROR = 'GET_ALL_ROOM_TYPE_ERROR';

export const REMOVE_ROOM_TYPE_LOADING = "REMOVE_ROOM_TYPE_LOADING";
export const REMOVE_ROOM_TYPE_SUCCESS = 'REMOVE_ROOM_TYPE_SUCCESS';
export const REMOVE_ROOM_TYPE_ERROR = 'REMOVE_ROOM_TYPE_ERROR';

export const REMOVE_ALL_ROOM_TYPE_LOADING = "REMOVE_ALL_ROOM_TYPE_LOADING";
export const REMOVE_ALL_ROOM_TYPE_SUCCESS = 'REMOVE_ALL_ROOM_TYPE_SUCCESS';
export const REMOVE_ALL_ROOM_TYPE_ERROR = 'REMOVE_ALL_ROOM_TYPE_ERROR';