import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Fade,
  TextField,
  TextareaAutosize,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GlobalModel from '../components/GlobalModal/GlobalModel';
import Bread from '../components/Bread/Bread';
import { GetUserHotelData } from '../Store/Actions/HotelActions/HotelCurdAction';
import { UseStateManager } from '../Context/StateManageContext';
import { useRoomManager } from '../Context/useRoomManager';
import { GetAllTheRoomConfig, GetRoomStats } from '../Store/Actions/RoomConfigActions';

// Styling for the modal
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  boxShadow: 24,
  p: 4,
};


// Initialize momentLocalizer with the moment library
const localizer = momentLocalizer(moment);

// Main component
const AvailableRoom = () => {
  const dispatch = useDispatch();
  const HotelData = useSelector((state) => state.GetAllHotelReducer);
  const EventData = useSelector((state) => state.GetAllRoomConfigReducer);
  const RoomStats = useSelector((state) => state.GetRoomStatsReducer)
  const { currentUser } = UseStateManager();
  const [editRooms, setEditRooms] = useState(false);

  const [isOpenBookingModal, setIsBookingModal] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [HotelId, setHotelId] = useState(null)

  // Close the booking modal
  const handleOpenCloseBooking = (roomId, hotelId) => {
    setHotelId(hotelId)
    setIsBookingModal(true);
    setSelectedRoomId(roomId);
  };

  // Close the booking modal
  const handleCloseCloseBooking = () => {
    setHotelId(null)
    setIsBookingModal(false);
    setSelectedRoomId(null);
  };


  const [isEditBookingModal, setIsEditBookingModal] = useState(false)

  const IsEditOpenBookingModal = () => {
    setIsEditBookingModal(true)
  }

  const IsEditCloseBookingModal = () => {
    setIsEditBookingModal(false)
  }

  const [age, setAge] = useState('inc');

  const { formData, setFormData, CreateRoomConfig, handleFormData, DeleteRoomConfig, UpdateManagerConfig, handleUpdateFormData, setUpdateFormData, updateformData } = useRoomManager();

  // Handle form submission
  const handleFormSubmit = () => {
    CreateRoomConfig(HotelId, selectedRoomId, formData);
    handleCloseCloseBooking();
    setFormData({});
  };


  const [openEditModal, setEditOpenModal] = useState(false)
  const [editRoomConfig, setEditRoomConfig] = useState(null)

  const handleEditOpenModal = (data) => {
    setIsEditBookingModal(false)
    setEditRoomConfig(data._id)
    setUpdateFormData(data)
    setEditOpenModal(true)
  }

  const handleEditCloseModal = () => {
    setIsEditBookingModal(true)
    setEditOpenModal(false)
  }

  const handleEditFormSubmit = (event) => {
    event.preventDefault()
    UpdateManagerConfig(editRoomConfig, updateformData);
    handleEditCloseModal();
    dispatch(GetAllTheRoomConfig(editRooms))
    // setUpdateFormData({});
  };

  useEffect(() => {
    dispatch(GetUserHotelData(currentUser?._id, currentUser?.role));
  }, [currentUser]);

  useEffect(() => {
    dispatch(GetAllTheRoomConfig(editRooms))
  }, [editRooms])

  useEffect(() => {
    dispatch(GetRoomStats(selectedRoomId, formData.from, formData.to))
  }, [formData.form, formData.to])



  const createEventFromData = (data) => {
    if (!data) {
      return 'Data Not Found';
    }
    const dataStructur = data.map((item) => ({ start: new Date(item.from), end: new Date(item.to), title: item.reason }))

    return dataStructur
  };

  const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Filter data based on the selected date range
    const filtered = EventData?.data?.filter((item) => {
      const fromDate = moment(item.from);
      return fromDate.isSameOrAfter(moment(startDate)) && fromDate.isSameOrBefore(moment(endDate));
    });

    setFilteredData(filtered);
  }, [EventData?.data?.length, startDate, endDate]);


  const DeleteConfigData = async (id) => {
    DeleteRoomConfig(id)
    IsEditCloseBookingModal()
    setEditRooms(false)
  }

  return (
    <div>
      <Bread icon={'fluent:presence-available-10-regular'} tabName={'Room Availablity'} />

      {editRooms ? (
        // Display the calendar when editing rooms
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="contained" onClick={IsEditOpenBookingModal}>
              Edit close Booking Date
            </Button>
            <Button variant="contained" onClick={() => setEditRooms(false)}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Calendar
              localizer={localizer}
              // events={myEventsList}
              events={createEventFromData(EventData?.data)}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 550, zIndex: '0', position: 'relative' }}
            />
          </Grid>
        </Grid>
      ) : (
        // Display the list of rooms
        <Grid container spacing={2}>
          {HotelData?.data?.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4}>
              <Card sx={{ padding: '0rem 0.5rem' }}>
                <CardContent onClick={() => setHotelId(item._id)}>
                  <img
                    src={item?.hotelCoverImg}
                    alt="hotelCoverImg"
                    style={{
                      width: '100%',
                      height: '10rem',
                      borderRadius: '10px',
                      paddingTop: '0.5rem',
                      objectFit: 'cover',
                      objectPosition: 'end',
                    }}
                  />
                  <div style={{ color: 'red' }}>
                    <h3 >{item?.hotelName}</h3>
                  </div>
                  {item?.rooms?.map((room, index) => (
                    <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant="h6" component="div">
                          {room?.roomType?.title}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                          Available Rooms: <b>{room?.counts}</b>
                        </Typography>
                      </Box>

                      <Box sx={{ display: 'flex', gap: 5, justifyContent: 'space-evenly' }}>
                        <Button variant="outlined" color="primary"
                          onClick={() => handleOpenCloseBooking(room._id)}
                        >
                          Add
                        </Button>

                        <Button variant="contained" color="primary"
                          onClick={() => setEditRooms(room._id)}>
                          Calendar
                        </Button>
                      </Box>
                    </>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {/* Global booking modal */}
      <GlobalModel
        open={isOpenBookingModal}
        content={
          <Fade in={isOpenBookingModal}>
            <Box sx={style}>
              <Typography py={2} variant="h6">
                Set Close Booking Date
              </Typography>
              <form onSubmit={handleFormSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={9} lg={9}>
                    <Grid container spacing={1}>
                      <Grid item xl={12}>
                        <Box>
                          <Select
                            fullWidth
                            disabled
                            name='will'
                            value={formData.will || "dec"}
                            onChange={(e) => handleFormData(e.target.name, e.target.value)}
                          >
                            <MenuItem value={'inc'}>Add</MenuItem>
                            <MenuItem value={'dec'}>Remove</MenuItem>
                          </Select>
                        </Box>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          type="datetime-local"
                          label="Start Date and Time"
                          name='from'
                          fullWidth
                          focused
                          value={formData.from || ""}
                          onChange={(e) => handleFormData(e.target.name, e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          type="datetime-local"
                          label="End Date and Time"
                          name='to'
                          fullWidth
                          focused
                          value={formData.to || ""}
                          onChange={(e) => handleFormData(e.target.name, e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          type="number"
                          name='rooms'
                          label="No of rooms"
                          fullWidth
                          value={formData.rooms || ""}
                          onChange={(e) => handleFormData(e.target.name, e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Button fullWidth sx={{ padding: 2 }} variant="contained">
                          Whole Room
                        </Button>
                      </Grid>

                      <Grid item xs={12}>
                        <TextareaAutosize
                          style={{ width: '100%', mt: 1, borderRadius: '8px' }}
                          maxRows={10}
                          minRows={10}
                          name='reason'
                          placeholder="Reason (Optional)"
                          value={formData.reason || ""}
                          onChange={(e) => handleFormData(e.target.name, e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" onClick={handleFormSubmit}>
                          Save
                        </Button>
                        <Button variant="outlined" onClick={handleCloseCloseBooking} sx={{ ml: 1 }}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} lg={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Card
                        sx={{
                          width: '100%',
                          height: '80px',
                          textAlign: 'center',
                          display: 'grid',
                          placeItems: 'center',
                          margin: '2px 4px 2px 2px',
                          background: '#a4d8ff',
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">Total Rooms</Typography>
                          <Typography variant="subtitle2">{RoomStats?.data?.TotalRooms || 'Loading...'}</Typography>
                        </Box>
                      </Card>
                      <Card
                        sx={{
                          width: '100%',
                          height: '80px',
                          textAlign: 'center',
                          display: 'grid',
                          placeItems: 'center',
                          margin: '2px',
                          background: '#ffc9c9',
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">Total Rooms Booked</Typography>
                          <Typography variant="subtitle2">{RoomStats?.data?.totalRoomsBooked}</Typography>
                        </Box>
                      </Card>
                      <Card
                        sx={{
                          width: '100%',
                          height: '80px',
                          textAlign: 'center',
                          display: 'grid',
                          placeItems: 'center',
                          margin: '2px',
                          background: '#b2f3bb',
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">Available Rooms</Typography>
                          <Typography variant="subtitle2">{RoomStats?.data?.TotalRooms - RoomStats?.data?.decreasedRoom}</Typography>
                        </Box>
                      </Card>
                      <Card
                        sx={{
                          width: '100%',
                          height: '80px',
                          textAlign: 'center',
                          display: 'grid',
                          placeItems: 'center',
                          margin: '2px',
                          background: '#feec98',
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">Hold</Typography>
                          <Typography variant="subtitle2">{RoomStats?.data?.decreasedRoom || 'Loading...'}</Typography>
                        </Box>
                      </Card>

                      {
                        RoomStats?.data?.increasedRoom &&
                        <Card
                          sx={{
                            width: '100%',
                            height: '80px',
                            textAlign: 'center',
                            display: 'grid',
                            placeItems: 'center',
                            margin: '2px',
                            background: '#eebefa',
                          }}
                        >
                          <Box>
                            <Typography variant="subtitle1">Add on</Typography>
                            <Typography variant="subtitle2">{RoomStats?.data?.increasedRoom}</Typography>
                          </Box>
                        </Card>
                      }
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Fade>
        }
      />

      {/* Global list edit modal */}
      <GlobalModel
        open={isEditBookingModal}
        handleClose={IsEditCloseBookingModal}
        content={
          <Fade in={isEditBookingModal}>
            <Box sx={style}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant='h5'>Scheduled List</Typography>
                <Box sx={{ display: 'flex', gap: 2, marginBottom: 2 }}>
                  <TextField
                    type="date"
                    label="From Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    type="date"
                    label="To Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>
              </Box>
              {filteredData?.map((item, index) => (
                <Box key={index} sx={{ border: '2px solid #ee2e24', my: 1, p: 1, borderRadius: '5px' }}>
                  <Box sx={{ display: 'flex', gap: 2, justifyContent: 'space-between' }}>
                    <Typography><b>From:</b> {moment(item.from).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
                    <Typography><b>To:</b> {moment(item.to).format('MMMM Do YYYY, h:mm:ss a')}</Typography>
                  </Box>
                  <Box>
                    <Typography><b>Reason:</b> {item.reason}</Typography>
                  </Box>
                  <Box sx={{ gap: 2, display: 'flex', justifyContent: 'end' }}>
                    <IconButton color='error' onClick={() => handleEditOpenModal(item)} ><EditIcon /></IconButton>
                    <IconButton color='error' onClick={() => {
                      DeleteConfigData(item._id)

                    }}><DeleteForeverIcon /></IconButton>
                  </Box>
                </Box>
              ))}
              <Button onClick={IsEditCloseBookingModal} variant='contained' color='error'>Cancel</Button>
            </Box>
          </Fade>
        }
      />

      {/* Room edit modal */}
      <GlobalModel
        open={openEditModal}
        handleClose={handleEditCloseModal}
        content={
          <Fade in={openEditModal}>
            <Box sx={style}>
              <Typography py={2} variant="h6">
                Edit Close Booking Date
              </Typography>
              <form onSubmit={handleEditFormSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={9} lg={9}>
                    <Grid container spacing={1}>
                      <Grid item xl={12}>
                        <Box>
                          <Select
                            fullWidth
                            disabled
                            name='will'
                            value={updateformData.will || "dec"}
                            onChange={(e) => handleUpdateFormData(e.target.name, e.target.value)}
                          >
                            <MenuItem value={'inc'}>Add</MenuItem>
                            <MenuItem value={'dec'}>Remove</MenuItem>
                          </Select>
                        </Box>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          type="datetime-local"
                          label="Start Date and Time"
                          name='from'
                          fullWidth
                          focused
                          value={moment(updateformData.from).format('YYYY-MM-DDTHH:mm')}
                          onChange={(e) => handleUpdateFormData(e.target.name, e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          type="datetime-local"
                          label="End Date and Time"
                          name='to'
                          fullWidth
                          focused
                          value={moment(updateformData.to).format('YYYY-MM-DDTHH:mm')}
                          onChange={(e) => handleUpdateFormData(e.target.name, e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <TextField
                          type="number"
                          name='rooms'
                          label="No of rooms"
                          fullWidth
                          value={updateformData.rooms}
                          onChange={(e) => handleUpdateFormData(e.target.name, e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Button fullWidth sx={{ padding: 2 }} variant="contained">
                          Whole Room
                        </Button>
                      </Grid>

                      <Grid item xs={12}>
                        <TextareaAutosize
                          style={{ width: '100%', mt: 1, borderRadius: '8px' }}
                          maxRows={10}
                          minRows={10}
                          name='reason'
                          placeholder="Reason (Optional)"
                          value={updateformData.reason}
                          onChange={(e) => handleUpdateFormData(e.target.name, e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" onClick={handleEditFormSubmit}>
                          Save
                        </Button>
                        <Button variant="outlined" onClick={handleEditCloseModal} sx={{ ml: 1 }}>
                          Cancel
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={3} lg={3}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Card
                        sx={{
                          width: '100%',
                          height: '80px',
                          textAlign: 'center',
                          display: 'grid',
                          placeItems: 'center',
                          margin: '2px 4px 2px 2px',
                          background: '#a4d8ff',
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">Total Rooms</Typography>
                          <Typography variant="subtitle2">{RoomStats?.data?.TotalRooms || 'Loading...'}</Typography>                        </Box>
                      </Card>
                      <Card
                        sx={{
                          width: '100%',
                          height: '80px',
                          textAlign: 'center',
                          display: 'grid',
                          placeItems: 'center',
                          margin: '2px',
                          background: '#ffc9c9',
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">Total Rooms Booked</Typography>
                          <Typography variant="subtitle2">{RoomStats?.data?.totalRoomsBooked}</Typography>
                        </Box>
                      </Card>
                      <Card
                        sx={{
                          width: '100%',
                          height: '80px',
                          textAlign: 'center',
                          display: 'grid',
                          placeItems: 'center',
                          margin: '2px',
                          background: '#b2f3bb',
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">Available Rooms</Typography>
                          <Typography variant="subtitle2">{RoomStats?.data?.TotalRooms - RoomStats?.data?.decreasedRoom}</Typography>
                        </Box>
                      </Card>
                      <Card
                        sx={{
                          width: '100%',
                          height: '80px',
                          textAlign: 'center',
                          display: 'grid',
                          placeItems: 'center',
                          margin: '2px',
                          background: '#feec98',
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle1">Hold</Typography>
                          <Typography variant="subtitle2">14</Typography>
                        </Box>
                      </Card>
                      {
                        RoomStats?.data?.increasedRoom &&
                        <Card
                          sx={{
                            width: '100%',
                            height: '80px',
                            textAlign: 'center',
                            display: 'grid',
                            placeItems: 'center',
                            margin: '2px',
                            background: '#eebefa',
                          }}
                        >
                          <Box>
                            <Typography variant="subtitle1">Not Reserved</Typography>
                            <Typography variant="subtitle2">{RoomStats?.data?.increasedRoom}</Typography>
                          </Box>
                        </Card>
                      }
                    </Box>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Fade>
        }
      />

    </div>
  );
};
export default AvailableRoom;
