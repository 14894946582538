
import instance from "../../../utils/_utils";
import * as constant from "../../Constants/RoomManagementConstant/RoomManagementConstant";


// to add the new room 
export const GetAddTheNewRooom = (hotelid, formdata) => {
    return async (dispatch) => {
        dispatch({ type: constant.ADD_NEW_ROOM_API_LOADING })
        try {
            const response = await instance.post(`/hotel/room/add/${hotelid}`, formdata)
            if (response.status === 200) {
                dispatch({ type: constant.ADD_NEW_ROOM_API_SUCCESS, payload: response.data })
            }
        } catch (error) {
            dispatch({ type: constant.ADD_NEW_ROOM_API_ERROR })
        }
    }
}


// to add the new room 
export const UpdateRoomData = (hotelid, roomid, formdata) => {
    return async (dispatch) => {
        dispatch({ type: constant.UPDATE_ROOM_API_LOADING })
        try {
            const response = await instance.patch(`/hotel/room/update/${hotelid}/${roomid}`, formdata)
            if (response.status === 200) {
                dispatch({ type: constant.UPDATE_ROOM_API_SUCCESS, payload: response.data })
            }
        } catch (error) {
            dispatch({ type: constant.UPDATE_ROOM_API_ERROR })
            console.log(error)
        }
    }
} 