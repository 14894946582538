import Swal from 'sweetalert2';

export const ShowPopup = ({ error, message }) => {
    return Swal.fire({ icon: error ? 'error' : 'success', title: message });
};

export const ShowTimerPopup = (error, message, position, timer) => {
    const time = timer || 1500;
    const place = position || 'top-end';
    return Swal.fire({
        position: place,
        icon: error ? 'error' : 'success',
        title: message,
        showConfirmButton: false,
        timer: time,
    });
};

export const ImageShowPopup = (error, message, text, image) => {
    return error
        ? Swal.fire({
            icon: 'error',
            title: message,
        })
        : Swal.fire({
            title: 'Sweet!',
            text,
            imageUrl: image,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: 'Custom image',
        });
};
