import * as React from 'react';
import {
  DataGrid,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { Button } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { GetAllBookingAction, GetVendorBookingAction } from '../../Store/Actions/BookingActions/GetBookingActions';
import { UseStateManager } from '../../Context/StateManageContext';

export default function BookingTable() {
  const dispatch = useDispatch();
  const { currentUser } = UseStateManager();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (currentUser?.role === 'vendor') {
      dispatch(GetVendorBookingAction(currentUser?._id));
    } else if (currentUser?.role === 'admin') {
      dispatch(GetAllBookingAction());
    }
  }, [currentUser]);

  const AllBookingList = useSelector((state) => state.BookingReducers.data);
  const isLoading = useSelector((state) => state.BookingReducers.isLoading);
  const AllVendorBookingList = useSelector((state) => state.VendorBookingReducers.data);
  const VendorIsLoading = useSelector((state) => state.VendorBookingReducers.isLoading);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  const DataWithID = (data) => {
    const newData = [];
    if (data !== undefined) {
      data.forEach((item, index) => {
        newData.push({ ...item, id: index + 1 });
      });
    } else {
      newData.push({ id: 0 });
    }
    return newData;
  };

  const getTitleForRoom = (data) => {
    const { room, hotel } = data;

    if (hotel && hotel.rooms && room) {
      const matchingRoom = hotel.rooms.find((r) => r._id === room);

      if (matchingRoom && matchingRoom.roomType) {
        return matchingRoom.roomType.title;
      }
    }
    return null;
  };

  const getStatusContent = (paymentStatus) => {
    if (paymentStatus === 'expired') {
      return <p style={{ color: 'orange' }}>Session Expired</p>;
    }

    if (paymentStatus === 'confirmed') {
      return <p style={{ color: '#81c14b' }}>Paid</p>;
    }

    if (paymentStatus === 'canceled') {
      return <p style={{ color: 'red' }}>cancelled</p>;
    }

    return <p>{paymentStatus}</p>;
  };

  const handleRowClick = (params) => {
    navigate(`/dashboard/booking/${params.row.bookingId}`);
  };

  const columns = [
    {
      field: 'bookingId',
      headerName: 'Booking ID',
      width: 100,
    },
    {
      field: 'hotelName',
      headerName: 'Hotel Name',
      width: 250,
      fontWeight: 900,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img width={80} style={{ borderRadius: '15px' }} src={params.row?.hotel?.hotelCoverImg} alt=".." />
          <div style={{ paddingLeft: '5px', wordWrap: 'break-word' }}>
            <h4 style={{ margin: '0px' }}>{params.row?.hotel?.hotelName}</h4>
            <p style={{ margin: '0px', wordWrap: 'break-word' }}>{params.row?.hotel?.address}</p>
          </div>
        </div>
      ),
    },
    {
      field: 'guest',
      headerName: 'Guest Name',
      width: 150,
      valueFormatter: ({ value }) => value?.name,
    },
    {
      field: 'guestContactDetails',
      headerName: 'Guest Contact Details',
      width: 200,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{params.row?.guest?.mobileNo}</p>
          <p style={{ margin: '0px' }}>{params.row.guest?.email}</p>
        </div>
      ),
    },
    {
      field: 'bookingDate',
      headerName: 'Booking Date',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{moment(params.row?.dateOfBooking).format('DD/MM/YY')}</p>
          <p style={{ margin: '0px' }}>{moment(params.row?.dateOfBooking).format('h:mm')}</p>
        </div>
      ),
    },
    {
      field: 'checkIn',
      headerName: 'Check In',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkIn).format('DD/MM/YY')}</p>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkIn).format('h:mm')}</p>
        </div>
      ),
    },
    {
      field: 'checkOut',
      headerName: 'Check Out',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkOut).format('DD/MM/YY')}</p>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkOut).format('h:mm')}</p>
        </div>
      ),
    },
    {
      field: 'numberOfRooms',
      headerName: 'No. Of Rooms',
      width: 100,
    },
    {
      field: 'numberOfGuests',
      headerName: 'No. Of Guests',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{params.row?.numberOfGuests?.adults}</p>
        </div>
      ),
    },
    {
      field: 'roomType',
      headerName: 'Room Type',
      width: 150,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{getTitleForRoom(params.row)}</p>
        </div>
      ),
    },
    {
      field: 'roomPrice',
      headerName: 'Room Price',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>Rs {params.row?.amount ? params.row?.amount : 0}</p>
        </div>
      ),
    },
    {
      field: 'taxes',
      headerName: 'Taxes',
      width: 150,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>
            GST Rs {params.row?.additionalCharges.gst ? params.row?.additionalCharges.gst : 0}
          </p>
          <p style={{ margin: '0px' }}>
            Service Rs {params.row?.additionalCharges.serviceFee ? params.row?.additionalCharges.serviceFee : 0}
          </p>
        </div>
      ),
    },
    {
      field: 'discount',
      headerName: 'Discount',
      width: 150,
      renderCell: (params) => (
        <div>
          {params.row?.discountInfo.map((offer) => (
            <p style={{ margin: '0px' }}>
              {offer.name} Rs {offer.amount || 0}
            </p>
          ))}
        </div>
      ),
    },
    {
      field: 'totalAmount',
      headerName: 'Total Amount',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>Rs {params.row?.totalAmount ? params.row?.totalAmount : 0}</p>
        </div>
      ),
    },
    {
      field: 'paymentType',
      headerName: 'Payment Method',
      width: 150,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{params.row?.payment?.paymentType}</p>
        </div>
      ),
    },
    {
      field: 'bookingStatus',
      headerName: 'Payment Status',
      width: 150,
      renderCell: (params) => getStatusContent(params.row?.bookingStatus),
    },

    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) =>
        currentUser?.role === 'admin' ? (
          <Button variant="text" color="error">
            <CancelIcon /> Cancel Order
          </Button>
        ) : null,
    },
  ];

  return (
    <div style={{ height: '100vh', width: '100%', marginTop: '10px' }}>
      <DataGrid
        rows={DataWithID(AllBookingList || AllVendorBookingList)}
        columns={columns}
        loading={isLoading || VendorIsLoading}
        density="comfortable"
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[10, 15, 20]}
        checkboxSelection
        slots={{ toolbar: CustomToolbar }}
        onRowClick={handleRowClick}
      />
    </div>
  );
}
