import * as constant from '../Constants/faqconstants';

const initialValues = {
  isLoading: false,
  isError: false,
  data: [],
};

const GetFaqByUserReducer = (state = initialValues, action) => {
  switch (action.type) {
    case constant.FAQ_BYFIELD_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.FAQ_BYFIELD_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case constant.FAQ_BYFIELD_API_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };

    default:
      return state;
  }
};

const CreateTheFaqReducer = (state = initialValues, action) => {
  switch (action.type) {
    case constant.FAQ_CREATE_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.FAQ_CREATE_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case constant.FAQ_CREATE_API_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      return state;
  }
};

const UpdateFaqReducer = (state = initialValues, action) => {
  switch (action.type) {
    case constant.FAQ_UPDATE_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.FAQ_UPDATE_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case constant.FAQ_UPDATE_API_ERROR: {
      return {
        ...state,
        isError: true,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export { GetFaqByUserReducer, CreateTheFaqReducer, UpdateFaqReducer };
