import { Box, Button, Container, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { UseStateManager } from '../../Context/StateManageContext';
import { GetBankDetailsAction } from '../../Store/Actions/BankDetailActions';
import { API_URL } from '../../config';

const UpdateBankAccountForm = ({ handleUpdateBankForm }) => {
    const [accountHolder, setAccountHolder] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [linkedMobileNo, setMobileNo] = useState('');
    const [formdata, setFormData] = useState({})
    const { currentUser } = UseStateManager();

    const handleChange = (e) => {
        setFormData({ ...formdata, [e.target.name]: e.target.value })
    }

    const dispatch = useDispatch();
    const BankData = useSelector(state => state.BankDetailReducers);
    const [formError, setFormError] = useState(null);

    const fetchData = async (currentUser) => {
        try {
            await dispatch(GetBankDetailsAction(currentUser?._id));
        } catch (error) {
            console.error('Error fetching bank details:', error);
        }
    };

    useEffect(() => {
        fetchData(currentUser);
    }, [currentUser]);

    useEffect(() => {
        if (BankData?.data) {
            console.log(BankData.data)
            setFormData(BankData.data[0])
        }
    }, [BankData]);


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.patch(`${API_URL}/bank/bank-details/update/${BankData?.data[0]?._id}`, formdata);

            console.log('Update successful:', response.data);

            if (handleUpdateBankForm) {
                handleUpdateBankForm();
            }

            setAccountHolder('');
            setAccountNumber('');
            setBankName('');
            setIfsc('');
            setMobileNo('');

            dispatch(GetBankDetailsAction(currentUser?._id));
        } catch (error) {
            console.error('Error updating bank details:', error);
            setFormError('Error updating bank details. Please try again.');
        }
    };

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h5" gutterBottom>
                    Update Bank Account Details
                </Typography>
                {formError && <p style={{ color: 'red' }}>{formError}</p>}
                <form onSubmit={handleSubmit} >
                    <TextField
                        label="Account Holder Name"
                        name='accountHolderName'
                        fullWidth
                        error
                        margin="normal"
                        variant="outlined"
                        value={formdata?.accountHolderName || ''}
                        onChange={handleChange}
                        required
                    />

                    <TextField
                        label="Account Number"
                        fullWidth
                        type='text'
                        name='accountNo'
                        margin="normal"
                        variant="outlined"
                        value={formdata?.accountNo || ''}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Bank Name"
                        fullWidth
                        name='bankName'
                        margin="normal"
                        variant="outlined"
                        value={formdata?.bankName || ''}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="IFSC Code"
                        fullWidth
                        name='ifsc'
                        margin="normal"
                        variant="outlined"
                        value={formdata?.ifsc || ''}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        label="Enter Mobile No."
                        fullWidth
                        name='linkedMobileNo'
                        type='number'
                        margin="normal"
                        variant="outlined"
                        value={formdata?.linkedMobileNo || ''}
                        onChange={handleChange}
                        required
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px' }}>
                            Submit
                        </Button>
                        <Button onClick={handleUpdateBankForm} color="primary" style={{ marginTop: '10px' }}>
                            Close
                        </Button>
                    </Box>
                </form>
            </Paper>
        </Container>
    );
}

export default UpdateBankAccountForm;
