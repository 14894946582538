import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import Bread from '../components/Bread/Bread'

const HotelWiseFaqs = () => {
  const [addHotelWiseFaq, setAddHotelWiseFaq] = useState(false)
  return (
    <div>
      <Bread icon={'wpf:faq'} tabName={'Hotel Wise FAQ'} />
      <Box>
        {addHotelWiseFaq ?
          <Grid spacing={1} p={1} container>
            <Grid xs='8' item>
              <Box sx={{ border: '2px solid #ee2e24', p: 1, borderRadius: '8px' }}>
                <Typography color={'error'} variant='subtitle1'>Faq's List</Typography>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Question 1</Typography>
                    <IconButton
                      sx={{ ml: 'auto' }}
                      color="secondary"
                    >
                      <AddCircleOutlineIcon color="error" />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Answer
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Question 2</Typography>
                    <IconButton
                      sx={{ ml: 'auto' }}
                      color="secondary"
                    >
                      <AddCircleOutlineIcon color="error" />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Answer
                    </Typography>
                  </AccordionDetails>
                </Accordion>

              </Box>
            </Grid>
            <Grid xs='4' item>
              <Box sx={{ border: '2px solid #ee2e24', p: 1, borderRadius: '8px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
                  <Typography variant='subtitle1'>Added Faq Lists</Typography>
                  <Button variant='contained' size='small'> Save</Button>
                </Box>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Question 1</Typography>
                    <IconButton
                      sx={{ ml: 'auto' }}
                      color="secondary"
                    >
                      <CancelIcon color="error" />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Answer
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
          </Grid> :
          <Grid spacing={1} container>
            <Grid onClick={() => setAddHotelWiseFaq(!addHotelWiseFaq)} item xs={3}>
              <div style={{ position: 'relative' }}>
                <img style={{ borderRadius: '8px', width: '100%' }} src='https://images.unsplash.com/photo-1625244724120-1fd1d34d00f6?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWxzfGVufDB8fDB8fHww' alt='preview' />
                <div style={{ position: 'absolute', bottom: 0, width: '100%', display: 'grid', placeItems: 'center', background: '#ffffff6b' }}>
                  <Typography fontSize={800} color={'black'} variant="h5">Hotel Name</Typography>
                </div>
              </div>
            </Grid>
          </Grid>
        }
      </Box>


    </div>
  )
}

export default HotelWiseFaqs