import React, { useEffect } from 'react'
import { Card, Grid, Typography } from '@mui/material'


const RoomList = ({ handelRoomManagement, hotellist, filteredhotel }) => {



    return (
        <Grid spacing={1} container>
            {
                hotellist.data?.filter(x => filteredhotel.includes(x.hotelName)).map((item, index) => {
                    return (
                        <Grid item xs={12} sm={4} md={4}>
                            <Card
                                sx={{
                                    margin: '2px',
                                    boxShadow: 0,
                                    textAlign: 'center',
                                    border: '2px solid #c9d1d4',
                                    position: 'relative',
                                    height: '250px',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handelRoomManagement(item._id)}
                            >
                                <div style={{ position: 'relative', height: '100%' }}>
                                    <img
                                        src={item.hotelCoverImg}
                                        alt="Hotels"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    {/* Add the gradient mask overlay */}
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))',
                                        }}
                                    >
                                        <div style={{
                                            position: 'relative',
                                            top: '80%',
                                            color: '#fff',
                                        }}>
                                            <Typography variant="h6">{item.hotelName}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </Grid>
                    )
                })}

        </Grid>
    )
}

export default RoomList