// BankAccountForm.js
import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Paper, Typography, Box } from '@mui/material';
import { toast } from 'react-toastify';
import { API_URL } from '../../config'
import { UseStateManager } from '../../Context/StateManageContext';

const BankAccountForm = ({ handleCloseBank }) => {
    const [accountHolder, setAccountHolder] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [bankName, setBankName] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [linkedMobileNo, setMobileNo] = useState('');
    const { currentUser } = UseStateManager();

    // Use a single state object for formData
    const [formData, setFormData] = useState({
        ifsc: '',
        accountNo: '',
        accountHolderName: '',
        bankName: '',
        linkedMobileNo: ''
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {

            const response = await fetch(`${API_URL}/bank/bank-details/add/${currentUser._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ifsc,
                    accountNo: accountNumber,
                    accountHolderName: accountHolder,
                    bankName,
                    linkedMobileNo
                }),
            });


            if (response.ok) {
                toast.success('Form submitted successfully');
            } else {
                const errorData = await response.json();
                toast.error(`Error: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An unexpected error occurred');
        }

        handleCloseBank();
    };

    useEffect(() => {
        console.log('Updated formData:', formData);
    }, [formData]);

    return (
        <Container maxWidth="sm">
            <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                <Typography variant="h5" gutterBottom>
                    Bank Account Details
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Account Holder Name"
                        name='accountHolderName'
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        value={accountHolder}
                        onChange={(e) => setAccountHolder(e.target.value)}
                        required
                    />
                    <TextField
                        label="Account Number"
                        fullWidth
                        type='number'
                        name='accountNo'
                        margin="normal"
                        variant="outlined"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value)}
                        required
                    />
                    <TextField
                        label="Bank Name"
                        fullWidth
                        name='bankName'
                        margin="normal"
                        variant="outlined"
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                        required
                    />
                    <TextField
                        label="IFSC Code"
                        fullWidth
                        name='ifsc'
                        margin="normal"
                        variant="outlined"
                        value={ifsc}
                        onChange={(e) => setIfsc(e.target.value)}
                        required
                    />
                    <TextField
                        label="Enter Mobile No."
                        fullWidth
                        type='number'
                        name='linkedMobileNo'
                        margin="normal"
                        variant="outlined"
                        value={linkedMobileNo}
                        onChange={(e) => setMobileNo(e.target.value)}
                        required
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>

                        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '10px' }}>
                            Submit
                        </Button>
                        <Button onClick={handleCloseBank} color="primary" style={{ marginTop: '10px' }}>
                            Close
                        </Button>
                    </Box>

                </form>
            </Paper>
        </Container>
    );
};

export default BankAccountForm;
