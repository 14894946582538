export const GET_ALL_COUPON_API_LOADING = 'GET_ALL_COUPON_API_LOADING'
export const GET_ALL_COUPON_API_SUCCESS = 'GET_ALL_COUPON_API_SUCCESS'
export const GET_ALL_COUPON_API_ERROR = 'GET_ALL_COUPON_API_ERROR'

export const ADD_COUPON_API_LOADING = 'ADD_COUPON_API_LOADING'
export const ADD_COUPON_API_SUCCESS = 'ADD_COUPON_API_SUCCESS'
export const ADD_COUPON_API_ERROR = 'ADD_COUPON_API_ERROR'

export const DELETE_COUPON_API_LOADING = 'DELETE_COUPON_API_LOADING'
export const DELETE_COUPON_API_SUCCESS = 'DELETE_COUPON_API_SUCCESS'
export const DELETE_COUPON_API_ERROR = 'DELETE_COUPON_API_ERROR'