export const GETALL_NOTIFICATION_API_LOADING = 'GETALL_NOTIFICATION_API_LOADING';
export const GETALL_NOTIFICATION_API_SUCCESS = 'GETALL_NOTIFICATION_API_SUCCESS';
export const GETALL_NOTIFICATION_API_ERROR = 'GETALL_NOTIFICATION_API_ERROR';

export const GETALL_NOTIFICATIONT_TEMP_API_LOADING = 'GETALL_NOTIFICATIONT_TEMP_API_LOADING';
export const GETALL_NOTIFICATIONT_TEMP_API_SUCCESS = 'GETALL_NOTIFICATIONT_TEMP_API_SUCCESS';
export const GETALL_NOTIFICATIONT_TEMP_API_ERROR = 'GETALL_NOTIFICATIONT_TEMP_API_ERROR';

export const GET_USER_NOTIFICATION_API_LOADING = 'GET_USER_NOTIFICATION_API_LOADING';
export const GET_USER_NOTIFICATION_API_SUCCESS = 'GET_USER_NOTIFICATION_API_SUCCESS';
export const GET_USER_NOTIFICATION_API_ERROR = 'GET_USER_NOTIFICATION_API_ERROR';

export const GET_READ_NOTIFICATION_API_LOADING = 'GET_READ_NOTIFICATION_API_LOADING';
export const GET_READ_NOTIFICATION_API_SUCCESS = 'GET_READ_NOTIFICATION_API_SUCCESS';
export const GET_READ_NOTIFICATION_API_ERROR = 'GET_READ_NOTIFICATION_API_ERROR';