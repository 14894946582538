
import * as constant from "../../Constants/ReviewRatingConstants/ReviewRatingConstants";


const initialState = {
    isLoading: false,
    data: null,
    isError: false
}


const GetAllReviewRatingsByIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_REVIEW_API_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.GET_REVIEW_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }

        case constant.GET_REVIEW_API_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }

        default:
            return state;
    }
}

const GetAllReviewRatingsByHotelReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.GETHOTEL_REVIEW_API_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.GETHOTEL_REVIEW_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }

        case constant.GETHOTEL_REVIEW_API_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }

        default:
            return state;
    }
}

const GetAllReviewRatingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.GETALL_REVIEW_API_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.GETALL_REVIEW_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }

        case constant.GETALL_REVIEW_API_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }

        default:
            return state;
    }
}

const DeleteReviewRatingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.DELETE_REVIEW_API_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.DELETE_REVIEW_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }

        case constant.DELETE_REVIEW_API_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }

        default:
            return state;
    }
}



export { GetAllReviewRatingsByIdReducer, DeleteReviewRatingsReducer, GetAllReviewRatingsReducer, GetAllReviewRatingsByHotelReducer }