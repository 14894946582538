import instance from '../../../utils/_utils';
import * as constant from '../../Constants/HotelioCouponConstants/HotelioCouponContant';

export const GetAllCouponAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GET_ALL_COUPON_API_LOADING });
            const response = await instance.get(`/coupon/getall`);
            if (response.status === 200) {
                dispatch({ type: constant.GET_ALL_COUPON_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GET_ALL_COUPON_API_ERROR });
        }
    };
};


export const AddCoupon = (formData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.ADD_COUPON_API_LOADING });
            const response = await instance.post(`/coupon/create`, formData);
            if (response.status === 200) {
                dispatch({ type: constant.ADD_COUPON_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.ADD_COUPON_API_ERROR });
        }
    };
}

export const DeleteCoupon = (couponId) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.DELETE_COUPON_API_LOADING });
            const response = await instance.delete(`/coupon/delete/${couponId}`);
            if (response.status === 200) {
                dispatch({ type: constant.DELETE_COUPON_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.DELETE_COUPON_API_ERROR });
        }
    };
}