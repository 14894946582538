import * as constant from '../../Constants/adminConstants/allFacilitiesConstants'


const initialState = {
    isLoading: false,
    isError: false,
    data: null
}

const GetAllFacilities = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_ALL_FACILITIES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.GET_ALL_FACILITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.GET_ALL_FACILITIES_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}

const AddFacilitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.ADD_FACILITIES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.ADD_FACILITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.ADD_FACILITIES_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}

const RemoveFacilitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.REMOVE_FACILITIES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.REMOVE_FACILITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.REMOVE_FACILITIES_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}
const RemoveAllFacilitiesReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.REMOVE_ALL_FACILITIES_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.REMOVE_ALL_FACILITIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }

        case constant.REMOVE_ALL_FACILITIES_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }
        default:
            return state;
    }
}

export { GetAllFacilities, AddFacilitiesReducer, RemoveFacilitiesReducer, RemoveAllFacilitiesReducer }