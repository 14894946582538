import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Card, Checkbox, Grid, Rating, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import { GetUserHotelData } from '../Store/Actions/HotelActions/HotelCurdAction';
import { UseStateManager } from '../Context/StateManageContext';
import { GetAllReviewRatingsByHotelAction, UpdateReviewStatusAction } from '../Store/Actions/ReviewRatnigActions/ReviewRatnigAction';
import Bread from '../components/Bread/Bread';

const MainReviewComment = () => {
  const { currentUser } = UseStateManager();
  const dispatch = useDispatch();
  const HotelTab = useSelector((state) => state.GetAllHotelReducer);
  const ReviewList = useSelector((state) => state.GetAllReviewRatingsByHotelReducer);

  useEffect(() => {
    dispatch(GetUserHotelData(currentUser?._id, currentUser?.role));
  }, [currentUser]);

  const [selectHotel, setSelectHotel] = useState(null);

  const hotelSelection = (hotelId) => {
    setSelectHotel((prevHotel) => (prevHotel === hotelId ? null : hotelId));
  };

  useEffect(() => {
    if (selectHotel !== null) {
      dispatch(GetAllReviewRatingsByHotelAction(selectHotel));
    }
  }, [selectHotel]);

  const handleCheckboxChange = async (reviewId, event) => {
    try {
      // Display a confirmation dialog using SweetAlert2
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Your selected review will be shown on the website. Do you want to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, update it!'
      });

      if (result.isConfirmed) {
        // Update the review status
        await dispatch(UpdateReviewStatusAction(reviewId, event.target.checked));

        // Fetch updated review ratings
        await dispatch(GetAllReviewRatingsByHotelAction(selectHotel));

        // Display success message using SweetAlert2
        Swal.fire({
          title: 'Success!',
          text: 'Review status updated successfully.',
          icon: 'success'
        });

      } else {
        // User clicked "No" or closed the dialog
        console.log('Update review status canceled');
      }
    } catch (error) {
      console.error('Network error', error);
      Swal.fire({
        title: 'Network Error!',
        text: 'There was a network error. Please try again later.',
        icon: 'error'
      });
    }
  };

  return (
    <div>
      <Bread tabName={'Manage Review Comment'} />

      <Grid container spacing={2}>
        <Grid item xs={selectHotel ? 8 : 12}>
          <div
            style={{
              overflowY: 'scroll',
              maxHeight: '100vh'
            }}
          >
            <Grid container spacing={1}>
              {HotelTab?.data?.map((item, index) => (
                <Grid key={index} item xs={selectHotel ? 6 : 4}>
                  <Card
                    sx={{
                      boxShadow: 0,
                      textAlign: 'center',
                      border: item.id === selectHotel ? '4px solid #ee2e24' : '2px solid #ccc',
                      height: '200px',
                      cursor: 'pointer',
                      opacity: item.id === selectHotel && 0.5
                    }}
                    onClick={() => hotelSelection(item._id)}
                  >
                    <div style={{ height: '100%', position: 'relative' }}>
                      <img
                        src={item?.hotelCoverImg}
                        alt={item.title}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: '88%',
                          color: '#ee2e24',
                          width: '100%',
                          background: '#fff'
                        }}
                      >
                        <Typography variant="h6">{item?.hotelName}</Typography>
                      </div>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
        </Grid>

        {selectHotel && (
          <Grid item xs={4}>
            {ReviewList?.data?.[0]?.reviews.map((item, index) => (
              <Card key={index} sx={{ m: 1 }}>
                <div style={{ margin: '5px' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar>H</Avatar>
                    <div style={{ marginLeft: '10px', width: '100%' }}>
                      <Typography display="block" variant="p">
                        John Doe
                      </Typography>
                      <Rating name="read-only" value={item?.ratings} readOnly />
                    </div>
                    <div>
                      <Checkbox
                        checked={item?.timeline}
                        onChange={(event) => handleCheckboxChange(item?._id, event)}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    </div>
                  </div>
                  <Typography display="block" sx={{ m: 1 }} variant="p">
                    {item?.message}
                  </Typography>
                </div>
              </Card>
            ))}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MainReviewComment;
