// component
import { Icon } from '@iconify/react';
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const configAdmin = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Manage Partners',
    // path: '/dashboard/addPartner',
    icon: <Icon icon="ic:twotone-group" width="25" height="25" />,
    subItems: [
      {
        title: 'Add Partner',
        path: '/dashboard/addPartner',
        icon: icon('ic_add'),
      },
      {
        title: 'Partners List',
        path: '/dashboard/listPartner',
        icon: icon('ic_list'),
      },
      {
        title: 'Kyc Verification',
        path: '/dashboard/kycVerification',
        icon: icon('ic_list'),
      },
    ],
  },
  {
    title: 'Manage Hotels',
    // path: '/dashboard/add',
    icon: <Icon icon="ic:twotone-hotel" width="25" height="25" />,
    subItems: [
      {
        title: 'Add Hotel',
        path: '/dashboard/add',
        icon: icon('ic_add'),
      },
      {
        title: 'Hotel List',
        path: '/dashboard/list',
        icon: icon('ic_list'),
      },
      {
        title: 'Hotel Verification',
        path: '/dashboard/hotel-verfication',
        icon: <Icon icon="fa6-solid:building-circle-check" width="25" height="25" />,
      },
    ],
  },
  {
    title: 'FAQ Management',
    // path: '/dashboard/add',
    icon: <Icon icon="wpf:faq" width="25" height="25" />,
    subItems: [
      {
        title: 'Add FAQ',
        path: '/dashboard/add-faq',
        icon: icon('ic_add'),
      },
      {
        title: 'Location wise FAQ',
        path: '/dashboard/location-wise-faq',
        icon: icon('ic_list'),
      },
    ],
  },
  {
    title: 'Manage Customer',
    path: '/dashboard/manageCustomer',
    icon: <Icon icon="solar:user-bold-duotone" width="25" height="25" />,
  },
  {
    title: 'Manage Travel Agent',
    path: '/dashboard/manage-Travel-Agent',
    icon: <Icon icon="healthicons:travel" width="25" height="25" />,
  },
  {
    title: 'Room & Price Management',
    path: '/dashboard/room-Price-Management',
    icon: <Icon icon="solar:tag-price-bold-duotone" width="25" height="25" />,
  },
  {
    title: 'Website Management',
    // path: '/dashboard/add',
    icon: <Icon icon="fluent-mdl2:website" width="25" height="25" />,
    subItems: [
      {
        title: 'Manage Review & Comments',
        path: '/dashboard/manageReviewComment',
        icon: icon('ic_add'),
      },
      {
        title: 'Slider Management',
        path: '/dashboard/website-management',
        icon: icon('ic_list'),
      },
    ],
  },
  {
    title: 'Manage Bookings',
    // path: '/dashboard/add',
    icon: <Icon icon="ph:stack-duotone" width="25" height="25" />,
    subItems: [
      {
        title: 'All Bookings',
        path: '/dashboard/booking',
        icon: icon('ic_list'),
      },
      {
        title: 'Hotel Bookings',
        path: '/dashboard/hotel-bookings',
        icon: icon('ic_list'),
      },
    ],
  },
  {
    title: 'Notifications',
    icon: <Icon icon="iconamoon:notification-duotone" width="25" height="25" />,
    subItems: [
      {
        title: 'Notifications List',
        path: '/dashboard/notification',
        icon: icon('ic_list'),
      },
      {
        title: 'Notifications Management',
        path: '/dashboard/Notification-Configuration',
        icon: <Icon icon="et:gears" width="25" height="25" />,
      }
    ],
  },
  {
    title: 'Wallet Management',
    path: '/dashboard/wallet-Management',
    icon: <Icon icon="solar:wallet-bold-duotone" width="25" height="25" />,
  },
  {
    title: 'Manage Offer',
    path: '/dashboard/manageOffer',
    icon: <Icon icon="ic:twotone-discount" width="25" height="25" />,
  },
  {
    title: 'Manage Coupons',
    path: '/dashboard/manageCoupons',
    icon: <Icon icon="iconamoon:discount-duotone" width="25" height="25" />,
  },
  {
    title: 'Regulate Payment',
    path: '/dashboard/regulatePayment',
    icon: <Icon icon="ic:twotone-payment" width="25" height="25" />,
  },
  {
    title: 'Promotional Activities',
    path: '/dashboard/promotionalActivity',
    icon: <Icon icon="icons8:advertising" width="25" height="25" />,
  }
];


export default configAdmin;
