import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Autocomplete,
} from '@mui/material';
import { UseStateManager } from '../../Context/StateManageContext';
import WaitLoader from '../../utils/WaitLoader';
import { UpdateRoomData } from '../../Store/Actions/RoomManagement/roomManagementActions';
import { GetUserHotelData } from '../../Store/Actions/HotelActions/HotelCurdAction';
import instance from '../../utils/_utils';

const UpdateRoomForm = ({ handleClose, roomid, hotel }) => {
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const { roomList, currentUser } = UseStateManager();
  // State to store form data
  const [formData, setFormData] = useState({});

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const HandleUpdateRoomData = async (hotelid, roomid, formdata) => {
    setLoader(true);

    try {
      const response = await instance.post(`/hotel/room/update/${hotelid}/${roomid}`, formdata);

      if (response.status === 200) {
        await dispatch(GetUserHotelData(currentUser._id, currentUser?.role));
        handleClose();
      } else {
        // Handle unexpected response status codes
        console.error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      // Handle errors gracefully
      console.error('An error occurred:', error);
    } finally {
      setLoader(false); // Ensure loader is turned off, whether the request succeeds or fails
    }
  };

  return (
    <form>
      <h1>{roomList.data?.find((x) => x._id === roomid)?.roomType}</h1>
      <WaitLoader Loading={loader} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Number of Rooms"
            name="counts"
            value={formData.counts || ''}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Price per Room"
            name="price"
            value={formData.price || ''}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <div>
        <Button
          sx={{ m: 1 }}
          variant="contained"
          color="primary"
          type="button"
          onClick={() => HandleUpdateRoomData(hotel._id, roomid, formData)}
        >
          save
        </Button>
        <Button onClick={handleClose} sx={{ m: 1 }} variant="outlined" color="primary">
          Cancel
        </Button>
      </div>
    </form>
  );
};

export default UpdateRoomForm;
