
// tO add the rooms 
export const ADD_NEW_ROOM_API_LOADING = "ADD_NEW_ROOM_API_LOADING"
export const ADD_NEW_ROOM_API_SUCCESS = "ADD_NEW_ROOM_API_SUCCESS"
export const ADD_NEW_ROOM_API_ERROR = "ADD_NEW_ROOM_API_ERROR"



// to update the rooms data 

export const UPDATE_ROOM_API_LOADING = "UPDATE_ROOM_API_LOADING"
export const UPDATE_ROOM_API_SUCCESS = "UPDATE_ROOM_API_SUCCESS"
export const UPDATE_ROOM_API_ERROR = "UPDATE_ROOM_API_ERROR"

