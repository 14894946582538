import React, { useEffect } from 'react'
import { Grid } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import VendorTable from '../components/Tables/VendorTable';
import Bread from '../components/Bread/Bread';
import { AdminVendorCountAction } from '../Store/Actions/HotelActions/HotelCurdAction';

const PartnerList = () => {

  const dispatch = useDispatch();

  const PartnerStats = useSelector((state) => state.AdminVendorCountReducer?.data?.[0]);
  useEffect(() => {
    dispatch(AdminVendorCountAction())
  }, [])


  return (
    <div>
      <Bread icon={'ic:twotone-group'} tabName={'Manage Partner'} />
      <Grid container spacing={3}>
        {/* Add Own Hotel */}
        <Grid item xs={12} sm={4} md={4}>
          <AppWidgetSummary
            title="Total Partners"
            total={PartnerStats?.partner || '0'}
            color="info"
            icon={'ic:baseline-group'}
          //   onClick={handleOpen}
          />
        </Grid>

        {/* Add Vendor Hotel */}
        <Grid item xs={12} sm={4} md={4}>
          <AppWidgetSummary
            title="Active Partner"
            total={PartnerStats?.activePartners || '0'}
            color="success"
            icon={'prime:circle-fill'}
          //   onClick={handleOpen}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={4}>
          <AppWidgetSummary
            title="In-Active Partner"
            total={PartnerStats?.inActivePartners || '0'}
            color="error"
            icon={'prime:circle-fill'}
          //   onClick={handleOpen}
          />
        </Grid>

        {/* VendorHotel Component */}
        <Grid item xs={12} sm={12} md={12}>
          <VendorTable />
        </Grid>
      </Grid>
    </div>
  )
}

export default PartnerList