import instance from '../../utils/_utils';
import * as constant from '../Constants/RoomConfigConstant';

export const GetAllTheRoomConfig = (roomid) => {
  return async (dispatch) => {
    try {
      // const idQuery = new URLSearchParams({ roomid }).toString()
      dispatch({ type: constant.GET_ROOMS_CONFIG_LOADING });
      const response = await instance.get(`/hotel/room-confg/get?roomid=${roomid}`);
      if (response.status === 200) {
        dispatch({ type: constant.GET_ROOMS_CONFIG_SUCCESS, payload: response.data.data });
      }
    } catch (error) {
      dispatch({ type: constant.GET_ROOMS_CONFIG_ERROR });
    }
  };
};



export const GetRoomStats = (roomid, from, to) => {
  return async (dispatch) => {
    try {
      dispatch({ type: constant.GET_STATS_CONFIG_LOADING });
      const response = await instance.get(`hotel/room-info/${roomid}?from=${from}&to=${to}`);
      if (response.status === 200) {
        dispatch({ type: constant.GET_STATS_CONFIG_SUCCESS, payload: response.data.result });
      }
    } catch (error) {
      dispatch({ type: constant.GET_STATS_CONFIG_ERROR });
    }
  };
};
