import React from 'react';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';

const BookingList = ({ Data }) => {
  const navigate = useNavigate();

  const DataWithID = (data) => {
    const newData = [];
    if (data !== undefined) {
      data.forEach((item, index) => {
        newData.push({ ...item, id: index + 1 });
      });
    } else {
      newData.push({ id: 0 });
    }
    return newData;
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  };

  // const getTitleForRoom = (data) => {
  //     const { room, hotel } = data;

  //     if (hotel && hotel.rooms && room) {
  //         const matchingRoom = hotel.rooms.find((r) => r._id === room);

  //         if (matchingRoom && matchingRoom.roomType) {
  //             return matchingRoom.roomType.title;
  //         }
  //     }

  //     return null;
  // };

  const getStatusContent = (paymentStatus) => {
    if (paymentStatus === 'expired') {
      return <p style={{ color: 'orange' }}>Session Expired</p>;
    }

    if (paymentStatus === 'confirmed') {
      return <p style={{ color: '#81c14b' }}>Paid</p>;
    }

    if (paymentStatus === 'canceled') {
      return <p style={{ color: 'red' }}>cancelled</p>;
    }

    return <p>{paymentStatus}</p>;
  };

  const handleRowClick = (params) => {
    navigate(`/dashboard/booking/${params.row.bookingId}`);
  };

  const columns = [
    {
      field: 'bookingId',
      headerName: 'Booking ID',
      width: 100,
      renderCell: (params) => params.row?.bookingId,
    },
    {
      field: 'guest',
      headerName: 'Guest Name',
      width: 200,
      valueFormatter: ({ value }) => value?.name,
    },
    {
      field: 'guestContactDetails',
      headerName: 'Guest Contact Details',
      width: 200,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{params.row?.guest?.mobileNo}</p>
          <p style={{ margin: '0px' }}>{params.row?.guest?.email}</p>
        </div>
      ),
    },
    {
      field: 'checkInDate',
      headerName: 'Check In',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkIn).format('DD/MM/YY')}</p>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkIn).format('h:mm')}</p>
        </div>
      ),
    },
    {
      field: 'checkOutDate',
      headerName: 'Check Out',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkOut).format('DD/MM/YY')}</p>
          <p style={{ margin: '0px' }}>{moment(params.row?.bookingDate?.checkOut).format('h:mm')}</p>
        </div>
      ),
    },
    {
      field: 'numberOfRooms',
      headerName: 'No. Of Rooms',
      width: 100,
    },
    {
      field: 'numberOfGuests',
      headerName: 'No. Of Guests',
      width: 100,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{params.row?.numberOfGuests?.adults}</p>
        </div>
      ),
    },
    {
      field: 'roomType',
      headerName: 'Room Type',
      width: 150,
      // renderCell: (params) => (
      //     <div>
      //         <p style={{ margin: '0px' }}>{getTitleForRoom(params.row)}</p>
      //     </div>
      // )
    },
    {
      field: 'totalAmount',
      headerName: 'Total Amount',
      width: 150,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>Rs {params.row?.totalAmount ? params.row?.totalAmount : 0}</p>
        </div>
      ),
    },
    {
      field: 'paymentMethod',
      headerName: 'Payment Method',
      width: 150,
      renderCell: (params) => (
        <div>
          <p style={{ margin: '0px' }}>{params.row?.payment?.paymentType}</p>
        </div>
      ),
    },
    {
      field: 'bookingStatus',
      headerName: 'Payment Status',
      width: 150,
      // valueFormatter: ({ value }) => value
      renderCell: (params) => getStatusContent(params.row?.bookingStatus),
    },
    // {
    //     field: 'isConfirmed',
    //     headerName: 'Booking Status',
    //     width: 150
    // },
    // {
    //     field: 'action',
    //     headerName: 'Action',
    //     width: 150,
    //     renderCell: (params) => (
    //         <Button variant='text' color='error'><CancelIcon /> Cancel Order</Button>
    //     )
    // }
  ];

  return (
    <div style={{ minheight: '100vh', width: '100%', marginTop: '10px' }}>
      <DataGrid
        rows={DataWithID(Data)}
        columns={columns}
        loading={Data?.isLoading}
        onRowClick={handleRowClick}
        density="comfortable" // Set default density to "comfortable"
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        slots={{ toolbar: CustomToolbar }}
      />
    </div>
  );
};

export default BookingList;
