
import * as constant from "../../Constants/HotelConstants/HotelCurdConstants";


const initialState = {
    isLoading: false,
    data: [],
    isError: false
}


const GetAllHotelReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.HOTEL_GET_API_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.HOTEL_GET_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload
            }

        case constant.HOTEL_GET_API_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true
            }

        default:
            return state;
    }
}



export default GetAllHotelReducer