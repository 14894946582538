import * as React from 'react';
import { DataGrid, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import { DeleteHotelioOffersAction, GetHotelioOffersAction } from '../../Store/Actions/HotelioOffersActions/HotelioOffersAction';
import { GetAllRoomType } from '../../Store/Actions/AdminActions/allRoomType';
import { getRoomTitleById } from '../../utils/getRoomTitleById';


export default function DiscountTable() {

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(GetAllRoomType());
        dispatch(GetHotelioOffersAction());
    }, []);

    const HotelioOffersList = useSelector((state) => state.HotelioOffersReducers.data);
    const HotelioOffersIsLoading = useSelector((state) => state.VendorBookingReducers.isLoading);
    const [roomTypeId, setRoomTypeId] = React.useState([]);
    const [roomTitle, setRoomTitle] = React.useState("");

    React.useEffect(() => {
        const fetchRoomData = async () => {
            console.log(roomTypeId)
            const titles = await Promise.all(roomTypeId.map((id) => getRoomTitleById(id)));
            setRoomTitle(titles);
        };

        fetchRoomData();
    }, [roomTypeId]);

    const DataWithID = (data) => {
        const newData = [];
        if (data !== undefined) {
            data?.forEach((item, index) => {
                newData.push({ ...item, id: index + 1 });
            });
        } else {
            newData.push({ id: 0 });
        }
        return newData;
    };

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarQuickFilter />
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarExport />
                <GridToolbarDensitySelector />
            </GridToolbarContainer>
        );
    };

    const handleDelete = (offerCode) => {

        Swal.fire({
            title: 'Are you sure?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                // User confirmed, dispatch the delete action
                dispatch(DeleteHotelioOffersAction(offerCode));

                // Show a success Swal notification
                Swal.fire(
                    'Deleted!',
                    'Your offer has been deleted.',
                    'success'
                );
                // Optionally, re-fetch the data after deletion
                dispatch(GetHotelioOffersAction());
            }
        });
    };


    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        {
            field: 'title',
            headerName: 'Title',
            width: 150,
            renderCell: (params) => params.row?.title
        },
        {
            field: 'code',
            headerName: 'Code',
            width: 180
        },
        {
            field: 'roomtype',
            headerName: 'Applicable',
            width: 150,
            renderCell: (params) => (
                <div>
                    {setRoomTypeId(params.row?.validation?.roomtype || [])}
                    {roomTitle.join(', ')}
                </div>
            )
        },
        {
            field: 'discount',
            headerName: 'Discount',
            width: 150,
            renderCell: (params) => {
                const { codeDiscount } = params.row || {};

                return (
                    <div>
                        {codeDiscount?.amount && !codeDiscount?.percentage && (
                            <p>{`${codeDiscount.amount} (In Amount)`}</p>
                        )}

                        {!codeDiscount?.amount && codeDiscount?.percentage && (
                            <p>{`${codeDiscount.percentage} (In Percentage)`}</p>
                        )}
                    </div>
                )
            }
        },
        {
            field: 'createdAt',
            headerName: 'Start Date',
            width: 150,
            renderCell: (params) => moment(params.row?.createdAt).format('MM Do YYYY')
        },
        {
            field: 'expiry',
            headerName: 'Expiry',
            width: 150,
            renderCell: (params) => moment(params.row?.validation?.upto).format('MM Do YYYY')
        },
        {
            field: 'minTransactions',
            headerName: 'Min Value',
            width: 100,
            renderCell: (params) => params.row?.validation?.minTransactions
        },
        {
            field: 'claimed',
            headerName: 'Claimed',
            width: 100,
            renderCell: (params) => params.row?.usedByCustomers?.length
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 150,
            renderCell: (params) => (
                <div style={{ display: 'flex' }}>
                    <DeleteForeverIcon onClick={() => handleDelete(params.row?.code)} color='error' />
                </div>
            )
        }

    ];

    return (
        <div style={{ minheight: '100vh', width: '100%', marginTop: '8px' }}>
            <DataGrid
                rows={DataWithID(HotelioOffersList)}
                // rows={rows}
                columns={columns}
                loading={HotelioOffersIsLoading}
                density="comfortable"
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[10, 15, 20]}
                checkboxSelection
                slots={{ toolbar: CustomToolbar }}
            />
        </div>
    );
}