import { Card, Typography } from '@mui/material'
import React from 'react'

const EmailActivity = ({ handelselcted }) => {
    return (
        <div>
            <div>
                <Card
                    sx={{
                        margin: '2px',
                        boxShadow: 0,
                        textAlign: 'center',
                        border: '2px solid #c9d1d4',
                        position: 'relative',
                        height: '300px',
                        cursor: 'pointer'
                    }}
                    onClick={handelselcted}
                >
                    <div style={{ position: 'relative', height: '100%' }}>
                        <img
                            src="/assets/EmailActivity.png"
                            alt="Hotels"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                        {/* Add the gradient mask overlay */}

                        <div style={{
                            position: 'absolute',
                            top: '90%',
                            color: '#ee2e24',
                            width: '100%'
                        }}>
                            <Typography variant="h6">Email Promotion</Typography>
                        </div>
                    </div>
                </Card>
            </div>
        </div>
    )
}

export default EmailActivity