export const GET_ALL_AMENITIES_LOADING = "GET_ALL_AMENITIES_LOADING";
export const GET_ALL_AMENITIES_SUCCESS = 'GET_ALL_AMENITIES_SUCCESS';
export const GET_ALL_AMENITIES_ERROR = 'GET_ALL_AMENITIES_ERROR';

export const ADD_AMENITIES_LOADING = "ADD_AMENITIES_LOADING";
export const ADD_AMENITIES_SUCCESS = 'ADD_AMENITIES_SUCCESS';
export const ADD_AMENITIES_ERROR = 'ADD_AMENITIES_ERROR';

export const REMOVE_AMENITIES_LOADING = "REMOVE_AMENITIES_LOADING";
export const REMOVE_AMENITIES_SUCCESS = 'REMOVE_AMENITIES_SUCCESS';
export const REMOVE_AMENITIES_ERROR = 'REMOVE_AMENITIES_ERROR';

export const REMOVE_ALL_AMENITIES_LOADING = "REMOVE_ALL_AMENITIES_LOADING";
export const REMOVE_ALL_AMENITIES_SUCCESS = 'REMOVE_ALL_AMENITIES_SUCCESS';
export const REMOVE_ALL_AMENITIES_ERROR = 'REMOVE_ALL_AMENITIES_ERROR';