import * as constant from '../../Constants/NotificationConstants/NotificationConstants';

const initialState = {
    isLoading: false,
    data: [],
    isError: false,
};


const GetAllNotificationEventReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.GETALL_NOTIFICATION_API_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case constant.GETALL_NOTIFICATION_API_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case constant.GETALL_NOTIFICATION_API_ERROR:
            return {
                ...state,
                isError: true,
            };
        default:
            return state;
    }
};

const GetAllNotificationTempReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.GETALL_NOTIFICATIONT_TEMP_API_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case constant.GETALL_NOTIFICATIONT_TEMP_API_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case constant.GETALL_NOTIFICATIONT_TEMP_API_ERROR:
            return {
                ...state,
                isError: true,
            };
        default:
            return state;
    }
};

const GetUserNotificationReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_USER_NOTIFICATION_API_LOADING:
            return {
                ...state,
                isLoading: true,
            };
        case constant.GET_USER_NOTIFICATION_API_SUCCESS:
            return {
                ...state,
                data: action.payload,
                isLoading: false,
            };
        case constant.GET_USER_NOTIFICATION_API_ERROR:
            return {
                ...state,
                isError: true,
            };
        default:
            return state;
    }
};

export { GetAllNotificationEventReducer, GetAllNotificationTempReducer, GetUserNotificationReducer };