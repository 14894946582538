import * as constant from '../../Constants/HotelioOffersConstants/HotelioOffersConstant';

const initialState = {
    loading: false,
    error: false,
    data: null,
};

const HotelioOffersReducers = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_OFFERS_API_LOADING:
            return {
                ...state,
                loading: true,
            };
        case constant.GET_OFFERS_API_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case constant.GET_OFFERS_API_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};

const CreateHotelioOffersReducers = (state = initialState, action) => {
    switch (action.type) {
        case constant.CREATE_OFFERS_API_LOADING:
            return {
                ...state,
                loading: true,
            };
        case constant.CREATE_OFFERS_API_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case constant.CREATE_OFFERS_API_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};

const DeletedHotelioOffersReducers = (state = initialState, action) => {
    switch (action.type) {
        case constant.DELETE_OFFERS_API_LOADING:
            return {
                ...state,
                loading: true,
            };
        case constant.DELETE_OFFERS_API_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case constant.DELETE_OFFERS_API_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};

export { HotelioOffersReducers, CreateHotelioOffersReducers, DeletedHotelioOffersReducers };
