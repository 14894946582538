import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import EditNoteIcon from '@mui/icons-material/EditNote';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import Typography from '@mui/material/Typography';
import {
  Box,
  Button,
  Chip,
  Fade,
  Grid,
  IconButton,
  Input,
  List,
  ListItemText,
  ListSubheader,
  Switch,
  TextField,
} from '@mui/material';
import GlobalModel from '../GlobalModal/GlobalModel';
import AddRoomForm from '../Forms/AddRoomForm';
import UpdateRoomForm from '../Forms/UpdateRoomForm';
import { GetUserHotelData } from '../../Store/Actions/HotelActions/HotelCurdAction';
import { UseStateManager } from '../../Context/StateManageContext';
import instance from '../../utils/_utils';
import WaitLoader from '../../utils/WaitLoader';
import AddAmenitiesModal from './AddAmenitiesModal';
import AddFacilitiesModal from './AddFacilitiesModal';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// ==================================== Main Component Room Operations ====================================================

export default function RoomOpertions({ manageRoom, handelsBack }) {
  const [_AllRoomsHotel, setAllRoomsHotel] = React.useState(null);
  const [loader, setLoader] = React.useState(false);

  const hotellist = useSelector((state) => state.GetAllHotelReducer);

  const hotelData = hotellist?.data?.find((x) => manageRoom === x._id);

  // ===================== Get all the room of that hotel ======================================
  const GetAllRooms = async (id) => {
    setLoader(true);
    try {
      const response = await instance.get(`/hotel/room/getall/${id}`);
      setAllRoomsHotel(response.data.data);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const { currentUser, allFacilities } = UseStateManager();

  // ================= Accordion Handler ========================================
  const [expanded, setExpanded] = React.useState(``); // with room _id

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // =========================================================================

  // ============================== Modal handlers ===========================
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openUpdateForm, setOpenUpdateForm] = React.useState(false);
  const handelOpenUpdateForm = () => setOpenUpdateForm(true);
  const handelCloseUpdateForm = () => setOpenUpdateForm(false);

  const [openAmenities, setAmenities] = React.useState(false);
  const handleOpenAmenities = () => setAmenities(true);
  const handleCloseAmenities = () => setAmenities(false);

  const [openFacilities, setFacilities] = React.useState(false);
  const handleOpenFacilities = () => setFacilities(true);
  const handleCloseFacilities = () => setFacilities(false);

  // ===================================================================

  const dispatch = useDispatch();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  // ======================== Delete Room data APi  ============================
  const DeleteRoomType = async (hotelid, roomid) => {
    // console.log(hotelid, roomid);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoader(true);
        try {
          // console.log(hotelid, roomid);
          const response = await instance.get(`/hotel/room/delete/${hotelid}/${roomid}`);

          if (response.status === 200) {
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
            dispatch(GetUserHotelData(currentUser._id, currentUser?.role));
            setLoader(false);
          }
        } catch (error) {
          console.log(error);
          setLoader(false);
        }
      }
    });
  };

  const [newAmenities, setNewAmenities] = React.useState('');

  // ======================= Update The room Data Api Call ===============================

  const HandleUpdateRoomData = async (hotelid, roomid, formdata) => {
    setLoader(true);

    try {
      console.log(formdata, `/hotel/room/update/${hotelid}/${roomid}`);
      const response = await instance.post(`/hotel/room/update/${hotelid}/${roomid}`, formdata);

      if (response.status === 200) {
        await dispatch(GetUserHotelData(currentUser._id, currentUser?.role));
        handleClose();
      } else {
        // Handle unexpected response status codes
        console.error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      // Handle errors gracefully
      console.error('An error occurred:', error);
    } finally {
      setLoader(false);
    }
  };

  //  =================== Amenites =============================================

  const [selectedAdditonalAmmenities, setAdditionalAmmenities] = React.useState([]);

  // ============== Update the Room Addtional Amenites =======================================

  const HandleAdditionalAmenitiesAdd = (id, roomid, updateData) => {
    setLoader(true);
    HandleUpdateRoomData(id, roomid, {
      additionAmenities: updateData,
    })
      .then(() => {
        dispatch(GetUserHotelData(currentUser?._id, currentUser?.role));
        setAdditionalAmmenities([]);
        setLoader(false);
        handleCloseAmenities();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const HandleAdditionalFacilities = (id, roomid, formdata) => {
    setLoader(true);
    HandleUpdateRoomData(id, roomid, {
      additionalFacilties: formdata,
    })
      .then(() => {
        dispatch(GetUserHotelData(currentUser?._id, currentUser?.role));
        setSelectedAddtionalFacilities([]);
        setLoader(false);
        handleCloseFacilities();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // To extract the ammenities lis

  const [selectedAdditonalFacilities, setSelectedAddtionalFacilities] = useState([]);
  const [newFacilites, setNewFacilities] = useState('');

  // status change
  const ShowRoomStatus = (value) => {
    let title = '';
    let icon = '';

    if (value === true) {
      title = 'Room is Now <span style="color: green;">Active</span>';
      icon = 'success';
    } else {
      title = 'Room is <span style="color: red;">In-Active</span> Now';
      icon = 'error';
    }

    Swal.fire({
      title,
      icon,
      showConfirmButton: false,
      timer: 2000, // Adjust the time (in milliseconds) you want the alert to be displayed before automatically closing.
    });
  };

  React.useEffect(() => {
    GetAllRooms(manageRoom);
  }, [manageRoom]);

  return (
    <div style={{ marginTop: '5px' }}>
      {/* // Add Room Form */}
      <GlobalModel
        open={open}
        content={
          <Fade in={open}>
            <Box sx={style}>
              <AddRoomForm handleClose={handleClose} manageRoom={manageRoom} />
            </Box>
          </Fade>
        }
      />
      {/* // Update Room form */}
      <GlobalModel
        open={openUpdateForm}
        handleClose={handelCloseUpdateForm}
        content={
          <Fade in={openUpdateForm}>
            <Box sx={style}>
              <UpdateRoomForm handleClose={handelCloseUpdateForm} roomid={expanded} hotel={hotelData} />
            </Box>
          </Fade>
        }
      />

      <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <IconButton onClick={handelsBack}>
          <ArrowBackIosNewRoundedIcon />
        </IconButton>
        <Typography onClick={handelsBack} variant="h6">
          Back
        </Typography>
      </div>
      <Button onClick={handleOpen} sx={{ my: 1 }} color="error" variant="contained">
        Add Room
      </Button>

      {hotelData.rooms?.map((room) => (
        <Accordion expanded={expanded === room?._id} onChange={handleChange(room?._id)}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>{room?.roomType?.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <TextField fullWidth label="Room Category" name="roomCategory" value={room?.roomType?.title} disabled />
              </Grid>
              <Grid item xs={3}>
                <TextField fullWidth label="Number of Rooms" name="numberOfRooms" value={room?.counts} disabled />
              </Grid>
              <Grid item xs={3}>
                <TextField fullWidth label="Price per Room" name="pricePerRoom" value={room?.price} disabled />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  label="Max. Guests"
                  name="pricePerRoom"
                  // value={room?.personAllowed}
                  value={room?.roomType?.personAllowed}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid spacing={1} mt={1} container>
              <Grid item xs={4}>
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: '#ffffff',
                    border: '2px solid #c9d1d4',
                    borderRadius: '5px',
                    padding: 1,
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader sx={{ p: 0, color: 'red' }} component="div" color="error" id="nested-list-subheader">
                      Mandatory Amenities
                    </ListSubheader>
                  }
                >
                  <hr />
                  {/* listed the amenities as per the room types  */}
                  {room?.roomType?.amenties.map((ammenities, index) => (
                    <ListItemText sx={{ pt: 1 }} primary={ammenities.title} />
                  ))}
                  <hr />
                  <ListSubheader sx={{ p: 0, color: 'red' }} component="div" color="error" id="nested-list-subheader">
                    Your Additional Amenities
                  </ListSubheader>
                  <hr />

                  {/* listing all the additional Ammenities  */}
                  {room?.additionAmenities?.map((amenity, index) => (
                    <ListItemText sx={{ pt: 1 }} primary={amenity} />
                  ))}
                </List>
              </Grid>
              <Grid item xs={4}>
                <List
                  sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: '#ffffff',
                    border: '2px solid #c9d1d4',
                    borderRadius: '5px',
                    padding: 1,
                  }}
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  subheader={
                    <ListSubheader sx={{ p: 0, color: 'red' }} component="div" color="error" id="nested-list-subheader">
                      Mandatory facilities
                    </ListSubheader>
                  }
                >
                  <hr />

                  {/* alll facilities listing  */}
                  {room?.roomType?.includeFacilities?.map((facility, index) => (
                    <ListItemText sx={{ pt: 1 }} primary={facility.title} />
                  ))}
                  <hr />
                  <ListSubheader sx={{ p: 0, color: 'red' }} component="div" color="error" id="nested-list-subheader">
                    Your Additional facilities
                  </ListSubheader>
                  <hr />
                  {/* listing all the additional facilities  */}
                  {room?.additionalFacilties?.map((facility, index) => (
                    <ListItemText sx={{ pt: 1 }} primary={facility} />
                  ))}
                </List>
              </Grid>
              <Grid item xs={4}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '30px 0px' }}>
                    <Typography variant="p">In- Active</Typography>
                    <Switch
                      checked={room?.status}
                      onChange={(e) => {
                        HandleUpdateRoomData(hotelData._id, expanded, { status: e.target.checked }).then(() => {
                          ShowRoomStatus(e.target.checked);
                        });
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <Typography variant="p">Active</Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <IconButton onClick={handelOpenUpdateForm} color="primary">
                      <EditNoteIcon />
                    </IconButton>
                    <IconButton
                      color="error"
                      onClick={() => {
                        DeleteRoomType(hotelData?._id, expanded);
                      }}
                    >
                      <DeleteSweepIcon />
                    </IconButton>
                  </div>
                </div>
                <div>
                  <Button sx={{ m: 1 }} onClick={handleOpenAmenities} color="error" variant="contained">
                    Additional Amenities
                  </Button>
                  <Button sx={{ m: 1 }} onClick={handleOpenFacilities} color="error" variant="contained">
                    Additional facilities
                  </Button>
                </div>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      <AddFacilitiesModal
        openFacilities={openFacilities}
        handleCloseFacilities={handleCloseFacilities}
        loader={loader}
        setNewFacilities={setNewFacilities}
        style={style}
        selectedAdditonalFacilities={selectedAdditonalFacilities}
        setSelectedAddtionalFacilities={setSelectedAddtionalFacilities}
        hotelData={hotelData}
        expanded={expanded}
        HandleAdditionalFacilities={HandleAdditionalFacilities}
      />

      {/* ================================================ Addtional Amenities Modal  =================================================== */}
      <AddAmenitiesModal
        openAmenities={openAmenities}
        handleCloseAmenities={handleCloseAmenities}
        loader={loader}
        style={style}
        setAdditionalAmmenities={setAdditionalAmmenities}
        selectedAdditonalAmmenities={selectedAdditonalAmmenities}
        hotelData={hotelData}
        expanded={expanded}
        HandleAdditionalAmenitiesAdd={HandleAdditionalAmenitiesAdd}
      />
      {/* ================================================ Additonal Amenities Modal ends =============================================== */}
      <WaitLoader Loading={loader} />
    </div>
  );
}
