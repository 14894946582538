import Joi from "joi";
/* eslint-disable no-restricted-syntax */

const AddHotelStepOne = (
  step,
  formData,
  rooms,
  termsAndCondition,
  hotelFullySanitized,
  notSupportDiscrimination,
  validAndTrueData
) => {
  const roomsData = { rooms };
  const stepFiveData = {
    checkIn: formData.checkIn,
    checkOut: formData.checkOutTime,
    cancellationPrices: formData.cancellationPrices,
    termsAndCondition,
    hotelFullySanitized,
    notSupportDiscrimination,
    validAndTrueData,
  };

  const stepFiveSchema = Joi.object({
    checkIn: Joi.string().required().messages({
      'any.required': 'Please provide the check-in date.',
    }),
    checkOut: Joi.string().required().messages({
      'any.required': 'Please provide the check-out date.',
    }),
    cancellationPrices: Joi.string().required().messages({
      'any.required': 'Please provide cancellation prices.',
    }),
    termsAndCondition: Joi.boolean().valid(true).required().messages({
      'any.required': 'Please agree to the terms and conditions.',
      'boolean.base': 'Terms and conditions must be a boolean.',
      'any.only': 'Terms and conditions must be checked.',
    }),
    hotelFullySanitized: Joi.boolean().valid(true).required().messages({
      'any.required': 'Please confirm if the hotel is fully sanitized.',
      'boolean.base': 'Hotel sanitization status must be a boolean.',
      'any.only': 'Hotel sanitization must be checked.',
    }),
    notSupportDiscrimination: Joi.boolean().valid(true).required().messages({
      'any.required': 'Please confirm not to support discrimination.',
      'boolean.base': 'Support discrimination status must be a boolean.',
      'any.only': 'Support discrimination must be checked.',
    }),
    validAndTrueData: Joi.boolean().valid(true).required().messages({
      'any.required': 'Please confirm the validity of the data.',
      'boolean.base': 'Validity of data must be a boolean.',
      'any.only': 'Valid and True must be checked.',
    }),
  }).unknown(true);

  const roomSchema = Joi.object({
    roomType: Joi.string().required().messages({
      'any.required': 'Please select the room type; it is required.',
      'string.empty': 'The roomType should not be empty.',
    }),
    price: Joi.number().required().messages({
      'any.required': 'Please enter the price for the room.',
      'number.base': 'The number should not be empty.',
    }),
    counts: Joi.number().required().messages({
      'any.required': 'Please enter the number of rooms; it is required.',
      'number.base': 'The number should not be empty.',
    }),
    // Add more properties and their validation rules if needed
  });

  const stepTwoSchema = Joi.object({
    rooms: Joi.array()
      .custom((value, helpers) => {
        if (value.length === 0) {
          return helpers.error('any.required', { message: 'Please provide at least one room detail.' });
        }

        // Check each room object in the array
        for (const room of value) {
          const { error } = roomSchema.validate(room);

          if (error) {
            return helpers.error('object.base', { message: error.message });
          }
        }

        return value;
      })
      .messages({
        'array.base': 'Rooms must be provided as an array.',
        'object.base': 'Invalid room details: {{#message}}',
      }),
  }).unknown(true);

  const stepThreeSchema = Joi.object({
    hotelCoverImg: Joi.string().required().messages({
      'any.required': 'Please provide a hotel cover image.',
      'string.empty': 'Hotel cover image cannot be empty.',
    }),
    hotelImg: Joi.array()
      .required()
      .custom((value, helpers) => {
        if (value.length === 0) {
          return helpers.error('any.required');
        }
        return value;
      })
      .messages({
        'any.required': 'Please provide at least one hotel image.',
        'array.base': 'Hotel images must be provided as an array.',
      }),
  }).unknown(true);

  const stepFourSchema = Joi.object({
    ownershipType: Joi.string().required(),
    regNo: Joi.string().required().min(3),
    regImg: Joi.string().required(),
  }).unknown(true);

  const stepOneSchema = Joi.object({
    name: Joi.string().required().messages({
      'any.required': 'Please provide a Hotel Name.',
      'string.empty': 'Hotel Name  cannot be empty.',
    }),
    propertyType: Joi.string().required().messages({
      'any.required': 'Please select a property type.',
      'string.empty': 'Property type cannot be empty.',
    }),
    email: Joi.string().required().messages({
      'any.required': 'Please provide an Hotel email Address.',
      'string.empty': 'Hotel Email address cannot be empty.',
    }),
    mobile: Joi.number().required().messages({
      'any.required': 'Please provide a Hotel Mobile Number',
      'number.base': 'Mobile number must be a valid number.',
    }),
    locality: Joi.string().required().messages({
      'any.required': 'Please Choose the Locality',
      'string.empty': 'Locality cannot be empty.',
    }),
    city: Joi.string().required().messages({
      'any.required': 'Please provide a city.',
      'string.empty': 'City cannot be empty.',
    }),
    country: Joi.string().required().messages({
      'any.required': 'Please provide a country.',
      'string.empty': 'Country cannot be empty.',
    }),
    state: Joi.string().required().messages({
      'any.required': 'Please provide a state.',
      'string.empty': 'State cannot be empty.',
    }),
    zipcode: Joi.number().required().messages({
      'any.required': 'Please provide a zipcode.',
      'number.base': 'Zipcode must be a valid number.',
    }),
    address: Joi.string().required().messages({
      'any.required': 'Please provide an address.',
      'string.empty': 'Address cannot be empty.',
    }),
    latitude: Joi.number().required().messages({
      'any.required': 'Please provide a latitude.',
      'number.base': 'Latitude must be a valid number.',
    }),
    longitude: Joi.number().required().messages({
      'any.required': 'Please provide a longitude.',
      'number.base': 'Longitude must be a valid number.',
    }),
    hotelMapLink: Joi.string().required().messages({
      'any.required': 'Please provide a hotel map link.',
      'string.empty': 'Hotel map link cannot be empty.',
    }),
  }).unknown(true);

  let schema;

  switch (step) {
    case 0:
      schema = stepOneSchema.validate(formData);
      break;
    case 1:
      schema = stepTwoSchema.validate(roomsData);
      break;
    case 2:
      schema = stepThreeSchema.validate(formData);
      break;
    case 3:
      schema = stepFourSchema.validate(formData);
      break;
    case 4:
      schema = stepFiveSchema.validate(stepFiveData);
      break;
    default:
      break;
  }

  if (schema.error) return { error: true, message: schema.error.details[0].message };
  return { error: false, message: `success ${step + 1} step` };
};

export default { AddHotelStepOne };
