import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GetVendorLogIn } from '../Store/Actions/AuthActions/VendorActions';

export default function NewLogin({ isRole }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // api login data
  const loginData = useSelector((state) => state.GetVendorLoginReducer);

  const handleLogin = async () => {
    try {
      await dispatch(GetVendorLogIn({ email, password }, isRole));
      if (loginData.isSuccess) {
        navigate('/dashboard/app');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePasswordToggle = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Box sx={{ mb: 5 }}>
        <form>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            InputProps={{
              endAdornment: (
                <IconButton onClick={handlePasswordToggle} edge="end">
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              ),
            }}
          />
          <Grid container>
            <Grid item xs={6}>
              <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            </Grid>
            <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
          <Button type="button" fullWidth color="primary" variant="contained" sx={{ mt: 3 }} onClick={handleLogin}>
            Login
          </Button>
        </form>
      </Box>
    </Grid>
  );
}
