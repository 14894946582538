import { Grid } from '@mui/material'
import React from 'react'
import VendorVerificationForm from '../Forms/VendorVerificationForm'
import AdminVendorVerificationForm from '../Forms/AdminVendorVerificationForm'
import { UseStateManager } from '../../Context/StateManageContext'


const AddVendor = () => {

    const { currentUser } = UseStateManager()

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    {currentUser ? currentUser.role === 'admin' ? <AdminVendorVerificationForm /> : <VendorVerificationForm /> : null}
                </Grid>
            </Grid>
        </div>
    )
}

export default AddVendor