import * as constant from '../../Constants/BookingConstants/BookingConstants';

const initialState = {
  isLoading: false,
  data: [],
  isError: false,
};

const BookingByIdReducers = (state = initialState, action) => {
  switch (action.type) {
    case constant.HOTEL_BOOKINGBYID_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.HOTEL_BOOKINGBYID_API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case constant.HOTEL_BOOKINGBYID_API_ERROR:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};

const BookingReducers = (state = initialState, action) => {
  switch (action.type) {
    case constant.HOTEL_BOOKING_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.HOTEL_BOOKING_API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case constant.HOTEL_BOOKING_API_ERROR:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};

const VendorBookingReducers = (state = initialState, action) => {
  switch (action.type) {
    case constant.HOTEL_VENDORBOOKING_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.HOTEL_VENDORBOOKING_API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case constant.HOTEL_VENDORBOOKING_API_ERROR:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};

const VendorBookingStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.HOTEL_VENDORBOOKING_STATS_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.HOTEL_VENDORBOOKING_STATS_API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case constant.HOTEL_VENDORBOOKING_STATS_API_ERROR:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};

const GetAdminBookingStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    case constant.HOTEL_ADMINBOOKING_STATS_API_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case constant.HOTEL_ADMINBOOKING_STATS_API_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case constant.HOTEL_ADMINBOOKING_STATS_API_ERROR:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};

export { BookingByIdReducers, BookingReducers, VendorBookingReducers, VendorBookingStatsReducer, GetAdminBookingStatsReducer };