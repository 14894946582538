import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Card, CardHeader, CardContent } from '@mui/material';
// components
import { Icon } from '@iconify/react';
// sections
import { AppNewsUpdate, AppCurrentVisits, AppWebsiteVisits, AppWidgetSummary } from '../sections/@dashboard/app';
import { UseStateManager } from '../Context/StateManageContext';
import { GetUserHotelData } from '../Store/Actions/HotelActions/HotelCurdAction';
import VendorHotel from '../components/Tables/VendorHotel';
import { GetDashStatsAction, GetDashStatsHAction } from '../Store/Actions/DashStaticsActions/DashStaticsActions';
import UpComingBookingTable from '../components/Tables/UpComingBookingTable';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const { currentUser, GetVendorUpdatedData, isKyc } = UseStateManager();

  const theme = useTheme();
  const navigate = useNavigate();

  const name = currentUser ? currentUser?.name?.split(' ') : ['Welcome'];
  // console.log(currentUser.hotels.length)

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetUserHotelData(currentUser?._id, currentUser?.role));
    if (currentUser?.role === 'vendor') {
      GetVendorUpdatedData(currentUser?._id);
    }
  }, []);

  useEffect(() => {
    dispatch(GetDashStatsAction())
    dispatch(GetDashStatsHAction())
  }, [])

  const GetTopStats = useSelector((state) => state.GetAllDashStatsReducer?.data);
  const GetSecondStats = useSelector((state) => state.GetAllDashStatsHReducer?.data?.[0]);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      {currentUser ? (
        currentUser.role === 'admin' ? (
          <Container maxWidth="xl">
            <Typography variant="h4" sx={{ mb: 3 }}>
              Hi <span style={{ color: 'tomato' }}>{currentUser ? name[0] : 'Welcome'} </span>,  welcome to the Hotelio dashboard, your travel partner
            </Typography>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Total Customer"
                  color="success"
                  total={GetTopStats?.customer || '0'}
                  icon={'mdi:human-male-female-child'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Total Vendor"
                  total={GetTopStats?.vendor || '0'}
                  color="info"
                  icon={'foundation:torsos-male-female'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary title="Total Agent" total={GetTopStats?.agents || '0'} color="warning" icon={'raphael:customer'} />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Total Booking"
                  total={GetTopStats?.bookings || '0'}
                  color="error"
                  icon={'material-symbols:bookmark-outline'}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Card
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px',
                    color: 'tomato',
                    background: '#ffe7d9',
                  }}
                >
                  <div>
                    <Typography variant="h3">{GetSecondStats?.newlyAddedHotels || '0'}</Typography>
                    <Typography variant="subtitle2">Newly Added Hotel</Typography>
                  </div>
                  <Icon icon="bi:building-add" width="80" height="80" />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px',
                    color: '#7A4F01',
                    background: '#fff7cd',
                  }}
                >
                  <div>
                    <Typography variant="h3">{GetSecondStats?.rooms || '0'}</Typography>
                    <Typography variant="subtitle2">Total Rooms</Typography>
                  </div>
                  <Icon icon="material-symbols:meeting-room" width="80" height="80" />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px',
                    color: 'blue',
                    background: '#d0f2ff',
                  }}
                >
                  <div>
                    <Typography variant="h3">{GetSecondStats?.hotels || '0'}</Typography>
                    <Typography variant="subtitle2">Total Hotels</Typography>
                  </div>
                  <Icon icon="bi:building" width="80" height="80" />
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Card
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '20px',
                    color: 'green',
                    background: '#e9fcd4',
                  }}
                >
                  <div>
                    <Typography variant="h3">0</Typography>
                    <Typography variant="subtitle2">Total Revenue</Typography>
                  </div>
                  <Icon icon="fa6-solid:money-bill-trend-up" width="80" height="80" />
                </Card>
              </Grid>

              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits
                  title="Total Revenues"
                  subheader="(+43%) than last year"
                  chartLabels={[
                    '01/01/2003',
                    '02/01/2003',
                    '03/01/2003',
                    '04/01/2003',
                    '05/01/2003',
                    '06/01/2003',
                    '07/01/2003',
                    '08/01/2003',
                    '09/01/2003',
                    '10/01/2003',
                    '11/01/2003',
                  ]}
                  chartData={[
                    // {
                    //   name: 'Team A',
                    //   type: 'column',
                    //   fill: 'solid',
                    //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                    // },
                    {
                      name: 'Profit',
                      type: 'area',
                      fill: 'gradient',
                      data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                    },
                    {
                      name: 'Expenses',
                      type: 'line',
                      fill: 'solid',
                      data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Card sx={{ padding: '24px' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '20px',
                      color: 'tomato',
                      background: '#ffe7d9',
                      margin: '25px 0px',
                      borderRadius: '12px',
                    }}
                  >
                    <div>
                      <Typography variant="h3">0</Typography>
                      <Typography variant="subtitle2">Total Expenses</Typography>
                    </div>
                    <Icon icon="teenyicons:up-solid" width="30" height="30" />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '20px',
                      color: 'green',
                      background: '#e9fcd4',
                      margin: '25px 0px',
                      borderRadius: '12px',
                    }}
                  >
                    <div>
                      <Typography variant="h3">0</Typography>
                      <Typography variant="subtitle2">Total Profit</Typography>
                    </div>
                    <Icon icon="teenyicons:down-solid" width="30" height="30" />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '20px',
                      color: 'blue',
                      background: '#d0f2ff',
                      margin: '25px 0px',
                      borderRadius: '12px',
                    }}
                  >
                    <div>
                      <Typography variant="h3">0</Typography>
                      <Typography variant="subtitle2">Total Revenue</Typography>
                    </div>
                    <Icon icon="material-symbols:equal" width="25" height="25" />
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <AppWebsiteVisits
                  title="Locations"
                  subheader="(+43%) than last year"
                  chartLabels={[
                    '01/01/2003',
                    '02/01/2003',
                    '03/01/2003',
                    '04/01/2003',
                    '05/01/2003',
                    '06/01/2003',
                    '07/01/2003',
                    '08/01/2003',
                    '09/01/2003',
                    '10/01/2003',
                    '11/01/2003',
                  ]}
                  chartData={[
                    {
                      name: 'Active',
                      type: 'column',
                      fill: 'solid',
                      data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                    },
                    {
                      name: 'In-Active',
                      type: 'column',
                      fill: 'gradient',
                      data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22],
                    },
                    {
                      name: 'Total',
                      type: 'column',
                      fill: 'solid',
                      data: [3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23],
                    },
                  ]}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentVisits
                  title="5 Major Locations"
                  chartData={[
                    { label: 'Chennai', value: 4344 },
                    { label: 'Hyderabad', value: 5435 },
                    { label: 'Banglore', value: 1443 },
                    { label: 'Mumbai', value: 4443 },
                    { label: 'Delhi', value: 4443 },
                  ]}
                  chartColors={[
                    theme.palette.primary.main,
                    theme.palette.info.main,
                    theme.palette.warning.main,
                    theme.palette.error.main,
                    theme.palette.success.main,
                  ]}
                />
              </Grid>
              {/* 
              <Grid item xs={12} md={6} lg={8}>
                <AppConversionRates
                  title="Conversion Rates"
                  subheader="(+43%) than last year"
                  chartData={[
                    { label: 'Italy', value: 400 },
                    { label: 'Japan', value: 430 },
                    { label: 'China', value: 448 },
                    { label: 'Canada', value: 470 },
                    { label: 'France', value: 540 },
                    { label: 'Germany', value: 580 },
                    { label: 'South Korea', value: 690 },
                    { label: 'Netherlands', value: 1100 },
                    { label: 'United States', value: 1200 },
                    { label: 'United Kingdom', value: 1380 },
                  ]}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <AppCurrentSubject
                  title="Current Subject"
                  chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
                  chartData={[
                    { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                    { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                    { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
                  ]}
                  chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
                />
              </Grid> */}

              {/* <Grid item xs={12} md={12} lg={12}>
                <AppNewsUpdate
                  title="News Updates"
                  list={[...Array(5)].map((_, index) => ({
                    id: faker.datatype.uuid(),
                    title: 'New Hotel Added',
                    description: 'Lucknow Rainday, Gomti Nager',
                    image: `/assets/images/covers/cover_${index + 1}.jpg`,
                    postedAt: faker.date.recent(),
                  }))}
                />
              </Grid> */}
              <Grid item xs={12} md={12} lg={12}>
                <Card>

                  <CardHeader sx={{ fontWeight: '800' }} avatar={'Upcoming Bookings'} />
                  <CardContent>
                    <UpComingBookingTable />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader sx={{ fontWeight: '800' }} avatar={'Total Hotels'} />
                  <CardContent>
                    <VendorHotel />
                  </CardContent>
                </Card>
              </Grid>

              {/* <Grid item xs={12} md={6} lg={4}>
                <AppOrderTimeline
                  title="Order Timeline"
                  list={[...Array(5)].map((_, index) => ({
                    id: faker.datatype.uuid(),
                    title: [
                      '1983, orders, $4220',
                      '12 Invoices have been paid',
                      'Order #37745 from September',
                      'New order placed #XF-2356',
                      'New order placed #XF-2346',
                    ][index],
                    type: `order${index + 1}`,
                    time: faker.date.past(),
                  }))}
                />
              </Grid> */}

              {/* <Grid item xs={12} md={6} lg={4}>
                <AppTrafficBySite
                  title="Traffic by Site"
                  list={[
                    {
                      name: 'FaceBook',
                      value: 323234,
                      icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                    },
                    {
                      name: 'Google',
                      value: 341212,
                      icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                    },
                    {
                      name: 'Linkedin',
                      value: 411213,
                      icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                    },
                    {
                      name: 'Twitter',
                      value: 443232,
                      icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                    },
                  ]}
                />
              </Grid> */}

              {/* <Grid item xs={12} md={6} lg={8}>
                <AppTasks
                  title="Tasks"
                  list={[
                    { id: '1', label: 'Create FireStone Logo' },
                    { id: '2', label: 'Add SCSS and JS files if required' },
                    { id: '3', label: 'Stakeholder Meeting' },
                    { id: '4', label: 'Scoping & Estimations' },
                    { id: '5', label: 'Sprint Showcase' },
                  ]}
                />
              </Grid> */}
            </Grid>
          </Container>
        ) : (
          <Container maxWidth="xl">
            <Typography variant="h4" sx={{ mb: 5 }}>
              Hi, <span style={{ color: 'tomato' }}>{currentUser ? name[0] : 'Welcome'} </span>, welcome to your dashboard
            </Typography>

            {currentUser ? (
              currentUser.hotels.length > 0 ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary
                      onClick={() => navigate('/dashboard/roomAvailablity')}
                      title="Available Rooms"
                      total={1352831}
                      color="info"
                      icon={'fluent:conference-room-24-regular'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary
                      onClick={() => navigate('/dashboard/booking')}
                      title="Total Bookig"
                      color="success"
                      total={714000}
                      icon={'mdi:bookmark-outline'}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <AppWidgetSummary
                      onClick={() => navigate('/dashboard/list')}
                      title="Total Properties"
                      total={1723315}
                      color="warning"
                      icon={'mdi:building'}
                    />
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                      title="Total Revenue"
                      total={234}
                      color="error"
                      icon={'fa6-solid:money-bill-trend-up'}
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} md={6} lg={8}>
                    <AppWebsiteVisits
                      title="Total Revenue"
                      subheader="(+43%) than last year"
                      chartLabels={[
                        '01/01/2003',
                        '02/01/2003',
                        '03/01/2003',
                        '04/01/2003',
                        '05/01/2003',
                        '06/01/2003',
                        '07/01/2003',
                        '08/01/2003',
                        '09/01/2003',
                        '10/01/2003',
                        '11/01/2003',
                      ]}
                      chartData={[
                        // {
                        //   name: 'Team A',
                        //   type: 'column',
                        //   fill: 'solid',
                        //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                        // },
                        {
                          name: 'Profit',
                          type: 'area',
                          fill: 'gradient',
                          data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                        },
                        {
                          name: 'Expenses',
                          type: 'line',
                          fill: 'solid',
                          data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <AppCurrentVisits
                      title="Locations"
                      chartData={[
                        { label: 'Chennai', value: 4344 },
                        { label: 'Hyderabad', value: 5435 },
                        { label: 'Banglore', value: 1443 },
                        { label: 'Mumbai', value: 4443 },
                        { label: 'Delhi', value: 4443 },
                      ]}
                      chartColors={[
                        theme.palette.primary.main,
                        theme.palette.info.main,
                        theme.palette.warning.main,
                        theme.palette.error.main,
                        theme.palette.success.main,
                      ]}
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} md={6} lg={8}>
                    <AppConversionRates
                      title="Conversion Rates"
                      subheader="(+43%) than last year"
                      chartData={[
                        { label: 'Italy', value: 400 },
                        { label: 'Japan', value: 430 },
                        { label: 'China', value: 448 },
                        { label: 'Canada', value: 470 },
                        { label: 'France', value: 540 },
                        { label: 'Germany', value: 580 },
                        { label: 'South Korea', value: 690 },
                        { label: 'Netherlands', value: 1100 },
                        { label: 'United States', value: 1200 },
                        { label: 'United Kingdom', value: 1380 },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={4}>
                    <AppCurrentSubject
                      title="Current Subject"
                      chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
                      chartData={[
                        { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                        { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                        { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
                      ]}
                      chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
                    />
                  </Grid> */}

                  <Grid item xs={12} md={12} lg={12}>
                    <AppNewsUpdate
                      title="News Updates"
                      list={[...Array(5)].map((_, index) => ({
                        id: faker.datatype.uuid(),
                        title: 'New Hotel Added',
                        description: 'Lucknow Rainday, Gomti Nager',
                        image: `/assets/images/covers/cover_${index + 1}.jpg`,
                        postedAt: faker.date.recent(),
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <Card style={{ padding: '20px' }}>
                      <CardHeader sx={{ fontWeight: '800' }} avatar={'Total Hotels'} />
                      <VendorHotel />
                    </Card>
                  </Grid>

                  {/* <Grid item xs={12} md={6} lg={4}>
                    <AppOrderTimeline
                      title="Order Timeline"
                      list={[...Array(5)].map((_, index) => ({
                        id: faker.datatype.uuid(),
                        title: [
                          '1983, orders, $4220',
                          '12 Invoices have been paid',
                          'Order #37745 from September',
                          'New order placed #XF-2356',
                          'New order placed #XF-2346',
                        ][index],
                        type: `order${index + 1}`,
                        time: faker.date.past(),
                      }))}
                    />
                  </Grid> */}

                  {/* <Grid item xs={12} md={6} lg={4}>
                    <AppTrafficBySite
                      title="Traffic by Site"
                      list={[
                        {
                          name: 'FaceBook',
                          value: 323234,
                          icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                        },
                        {
                          name: 'Google',
                          value: 341212,
                          icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                        },
                        {
                          name: 'Linkedin',
                          value: 411213,
                          icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                        },
                        {
                          name: 'Twitter',
                          value: 443232,
                          icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={8}>
                    <AppTasks
                      title="Tasks"
                      list={[
                        { id: '1', label: 'Create FireStone Logo' },
                        { id: '2', label: 'Add SCSS and JS files if required' },
                        { id: '3', label: 'Stakeholder Meeting' },
                        { id: '4', label: 'Scoping & Estimations' },
                        { id: '5', label: 'Sprint Showcase' },
                      ]}
                    />
                  </Grid> */}
                </Grid>
              ) : isKyc?.isVerified === undefined ? (
                <Grid container spacing={1}>
                  <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    <Typography variant="h4" sx={{ mb: 5 }}>
                      First Complete you profile using below button
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                    <div style={{ position: 'relative' }}>
                      <div style={{ position: 'absolute', left: '-60%', top: '95%' }}>
                        {currentUser.kycVerified ? null : (
                          <img
                            className="animated-image"
                            style={{ width: '50px' }}
                            src="/assets/pointer.png"
                            alt="pointer"
                          />
                        )}
                      </div>
                      <Button color="primary" onClick={() => navigate('/dashboard/profile')} variant="contained">
                        {' '}
                        Go To Profile{' '}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ) : isKyc?.isVerified === 'requested' ? (
                <div>
                  <Typography variant="h4">
                    Your KYC has been requested and sent to the admin for review. Please wait for an update shortly.
                  </Typography>
                </div>
              ) : (
                isKyc?.isVerified === 'approved' && (
                  <Grid container spacing={1}>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                      <Typography variant="h4" sx={{ mb: 5 }}>
                        Begin your journey by listing your hotel on Hotelio.
                      </Typography>
                    </Grid>
                    <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                      <Button color="primary" onClick={() => navigate('/dashboard/add')} variant="contained">
                        {' '}
                        Add Hotel{' '}
                      </Button>
                    </Grid>
                  </Grid>
                )
              )
            ) : null}
          </Container>
        )
      ) : null}
    </>
  );
}
