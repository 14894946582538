import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { Box, List, ListItemText } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { StyledNavItem, StyledNavItemIcon } from './styles';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, subItems } = item;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const renderSubItems = () => {
    if (isDropdownOpen && subItems) {
      return (
        <List style={{ paddingLeft: '10px' }} disablePadding>
          {subItems.map((subItem) => (
            <NavItem key={subItem.title} item={subItem} />
          ))}
        </List>
      );
    }
    return null;
  };

  return (
    <>
      <StyledNavItem
        component={RouterLink}
        to={path}
        activeClassName="active"
        sx={{
          '&.active': {
            color: 'text.primary',
            // bgcolor: 'action.selected',
            fontWeight: 'fontWeightBold',
          },
        }}
        onClick={toggleDropdown}
      >
        <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
        <ListItemText disableTypography primary={title} />
        {info && info}
        {subItems && (isDropdownOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
      </StyledNavItem>
      {renderSubItems()}
    </>
  );
}
