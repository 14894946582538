import * as constant from '../../Constants/adminConstants/kycConstants';

const initialGetAllKycListState = {
    isLoading: false,
    isError: false,
    data: [],
};

const GetAllKycList = (state = initialGetAllKycListState, action) => {
    switch (action.type) {
        case constant.GET_ALL_LIST_KYC_LOADING:
            return {
                ...state,
                isLoading: true,
                isError: false, // Reset isError to false when loading starts
            };
        case constant.GET_ALL_LIST_KYC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                isError: false, // Reset isError to false on success
            };
        case constant.GET_ALL_LIST_KYC_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            return state;
    }
};

const initialMakeKycActionState = {
    isLoading: false,
    isError: false,
    data: [],
};

const MakeKycActionReducer = (state = initialMakeKycActionState, action) => {
    switch (action.type) {
        case constant.MAKE_ACTION_KYC_LOADING:
            return {
                ...state,
                isLoading: true,
                isError: false, // Reset isError to false when loading starts
            };
        case constant.MAKE_ACTION_KYC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
                isError: false, // Reset isError to false on success
            };
        case constant.MAKE_ACTION_KYC_ERROR:
            return {
                ...state,
                isLoading: false,
                isError: true,
            };
        default:
            return state;
    }
};

export { MakeKycActionReducer, GetAllKycList };
