import React from 'react'
import { Box, Button, Grid, TextField, Typography } from '@mui/material'

const Wallet = () => {
    return (
        <Box>
            <Box>
                <Typography variant='h4'>Wallet Management</Typography>
            </Box>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TextField
                        id='walletAmount'
                        name='walletAmount'
                        fullWidth
                        label='Enter Wallet Amount'
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id='expiryDate'
                        name='expiryDate'
                        fullWidth
                        type='date'
                        label='Enter Expiry Date'
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id='deductedAmount'
                        name='deductedAmount'
                        fullWidth
                        label='Enter Deducted Amount'
                    />
                </Grid>
                <Grid item textAlign={'right'} xs={12}>
                    <Button size='large' variant='contained'>Save</Button>
                </Grid>
            </Grid>
            <Grid container mt={1} spacing={1}>
                <Grid item xs={12}>
                    <Typography py={2} fontWeight={800} variant='body1'>Current Wallet Offer</Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id='walletAmount'
                        name='walletAmount'
                        fullWidth
                        disabled
                        label='Enter Wallet Amount'
                        value={'999'}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id='expiryDate'
                        name='expiryDate'
                        fullWidth
                        disabled
                        type='date'
                        label='Enter Expiry Date'
                        value={'09/31/2023'}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        id='deductedAmount'
                        name='deductedAmount'
                        fullWidth
                        disabled
                        label='Enter Deducted Amount'
                        value={'100'}
                    />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Wallet