import { useContext, createContext, useState } from 'react';
import Swal from 'sweetalert2';
import instance from '../utils/_utils';

const RoomManagerContext = createContext();

const RoomManagerProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const handleFormData = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const [updateformData, setUpdateFormData] = useState({});
  const handleUpdateFormData = (name, value) => {
    setUpdateFormData({ ...formData, [name]: value });
  };

  const CreateRoomConfig = async (hid, rid, data) => {
    try {
      const response = await instance.post(`/hotel/room-confg/create/config/${hid}/${rid}`, data);
      if (response.status === 200) {
        Swal.fire({ icon: 'success', text: 'New Config Created ' });
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateManagerConfig = async (id, data) => {
    try {
      const response = await instance.patch(`/hotel/room-confg/update/${id}`, data);
      if (response.status === 200) {
        Swal.fire({ icon: 'success', text: 'success Updated' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteRoomConfig = async (id) => {
    try {
      const response = await instance.delete(`/hotel/room-confg/delete/${id}`);
      if (response.status === 200) {
        Swal.fire({ icon: 'success', text: 'deleted SuccessFully' });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRoomConfigById = async (id) => {
    try {
      const response = await instance.get(`/hotel/room-confg/get?id=${id}`);

      if (response.status === 200) {
        const roomConfigData = response.data;

        console.log(roomConfigData);

      }
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <RoomManagerContext.Provider
      value={{ CreateRoomConfig, UpdateManagerConfig, DeleteRoomConfig, formData, setFormData, handleFormData, getRoomConfigById, updateformData, handleUpdateFormData, setUpdateFormData }}
    >
      {children}
    </RoomManagerContext.Provider>
  );
};

export const useRoomManager = () => {
  return useContext(RoomManagerContext);
};

export default RoomManagerProvider;
