import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { DeleteCustomerById, GetAllCustomer } from '../../Store/Actions/AdminActions/allcustomers';

export default function CustomerTable({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // delete the customer
  const DeleteCustomer = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleted = await DeleteCustomerById(id);
        if (deleted === true) {
          Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          dispatch(GetAllCustomer());
        } else {
          Swal.fire('Deleted!', 'Not Deleted Try Again', 'error');
        }
      }
    });
  };

  const handleRowClick = (params) => {
    navigate(`/dashboard/user/${params.row._id}`);
  };

  // map the id in the data
  const DataWithID = (data) => {
    const newData = [];
    if (data !== undefined) {
      data.forEach((item, index) => {
        newData.push({ ...item, id: index + 1 });
      });
    } else {
      newData.push({ id: 0 });
    }
    return newData;
  };
  const columns = [
    {
      field: 'id',
      headerName: 'Customer ID',
      width: 100,
      renderCell: (params) => <div>#{params.row.id}</div>,
    },
    {
      field: 'name',
      headerName: 'Customer Name',
      width: 200,
      renderCell: (params) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* <img width={60} height={60} style={{ borderRadius: '50%' }} src={params.row.image} alt=".." /> */}
          <div style={{ paddingLeft: '5px' }}>
            <h4 style={{ margin: '0px' }}>{params.row.name}</h4>
          </div>
        </div>
      ),
    },
    {
      field: 'state',
      headerName: 'Customer Location',
      width: 150,
    },
    {
      field: 'createdAt',
      headerName: 'Cust Reg. Date',
      width: 150,
      renderCell: (params) => <div>{params.row.createdAt}</div>,
    },
    {
      field: 'mobileNo',
      headerName: 'Customer Mobile',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Customer Email',
      width: 150,
    },
    {
      field: 'password',
      headerName: 'Password',
      width: 100,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="text"
          color="error"
          onClick={() => {
            DeleteCustomer(params.row._id);
          }}
        >
          <DeleteForeverIcon color="error" />
        </Button>
      ),
    },
  ];

  const rows = [
    {
      id: 1,
      image:
        'https://www.usatoday.com/gcdn/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg',
      customerName: 'Abhishek Sharma',
      customerLocation: 'Lucknow, UP',
      joinedDate: '23/07/2023',
      customerEmail: 'abc@gmail.com',
      customerMobileNo: '8090300447',
      password: '123456',
    },
    {
      id: 2,
      image:
        'https://www.usatoday.com/gcdn/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg',
      customerName: 'Abhishek Sharma',
      customerLocation: 'Lucknow, UP',
      joinedDate: '23/07/2023',
      customerEmail: 'abc@gmail.com',
      customerMobileNo: '8090300447',
      password: '123456',
    },
    {
      id: 3,
      image:
        'https://www.usatoday.com/gcdn/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg',
      customerName: 'Abhishek Sharma',
      customerLocation: 'Lucknow, UP',
      joinedDate: '23/07/2023',
      customerEmail: 'abc@gmail.com',
      customerMobileNo: '8090300447',
      password: '123456',
    },
    {
      id: 4,
      image:
        'https://www.usatoday.com/gcdn/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg',
      customerName: 'Abhishek Sharma',
      customerLocation: 'Lucknow, UP',
      joinedDate: '23/07/2023',
      customerEmail: 'abc@gmail.com',
      customerMobileNo: '8090300447',
      password: '123456',
    },
    {
      id: 5,
      image:
        'https://www.usatoday.com/gcdn/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg',
      customerName: 'Abhishek Sharma',
      customerLocation: 'Lucknow, UP',
      joinedDate: '23/07/2023',
      customerEmail: 'abc@gmail.com',
      customerMobileNo: '8090300447',
      password: '123456',
    },
    {
      id: 6,
      image:
        'https://www.usatoday.com/gcdn/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg',
      customerName: 'Abhishek Sharma',
      customerLocation: 'Lucknow, UP',
      joinedDate: '23/07/2023',
      customerEmail: 'abc@gmail.com',
      customerMobileNo: '8090300447',
      password: '123456',
    },
  ];

  return (
    <div style={{ minheight: '100vh', width: '100%', marginTop: '10px' }}>
      <DataGrid
        rows={DataWithID(data)}
        columns={columns}
        density="comfortable" // Set default density to "comfortable"
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
        onRowClick={handleRowClick}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
