import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import * as React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';

export default function TravelAgentTabel({ data }) {

    const rows = [
        {
            id: 1,
            image:
                'https://www.usatoday.com/gcdn/-mm-/05b227ad5b8ad4e9dcb53af4f31d7fbdb7fa901b/c=0-64-2119-1259/local/-/media/USATODAY/USATODAY/2014/08/13/1407953244000-177513283.jpg',
            agentName: 'Abhishek Sharma',
            agentLocation: 'Lucknow, UP',
            joinedDate: '23/07/2023',
            agentEmail: 'abc@gmail.com',
            agentMobileNo: '8090300447',
            agentBookings:'54',
            agentCommisionPercentage: '10%',
            agentWalletAmount:'₹500',
            agentGstNumber:'46323522522A',
            agentPanNumber:'LOSTY6522O',
            password: 'XXXXX'
        },
    ];

    // map the id in the data
    const DataWithID = (data) => {
        const newData = [];
        if (data !== undefined) {
            data.forEach((item, index) => {
                newData.push({ ...item, id: index + 1 });
            });
        } else {
            newData.push({ id: 0 });
        }
        return newData;
    };
    const columns = [
        {
            field: 'id',
            headerName: 'Travel Agent ID',
            width: 100,
            renderCell: (params) => <div>#{params.row.id}</div>,
        },
        {
            field: 'agentName',
            headerName: 'Travel Agent Name',
            width: 200,
            renderCell: (params) => (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {/* <img width={60} height={60} style={{ borderRadius: '50%' }} src={params.row.image} alt=".." /> */}
                    <div style={{ paddingLeft: '5px' }}>
                        <h4 style={{ margin: '0px' }}>{params.row.agentName}</h4>
                    </div>
                </div>
            ),
        },
        {
            field: 'agentLocation',
            headerName: 'Agent Location',
            width: 150,
        },
        {
            field: 'joinedDate',
            headerName: 'Joining Date',
            width: 150,
            renderCell: (params) => <div>{params.row.joinedDate}</div>,
        },
        {
            field: 'agentMobileNo',
            headerName: 'Travel Agent Mobile No.',
            width: 150,
        },
        {
            field: 'agentEmail',
            headerName: 'Agent Email id',
            width: 150,
        },
        {
            field: 'agentBookings',
            headerName: 'Agent Booking',
            width: 100,
        },
        {
            field: 'agentCommisionPercentage',
            headerName: 'Agent Commision Percentage',
            width: 100,
        },
        {
            field: 'agentWalletAmount',
            headerName: 'Agent Wallet Amount',
            width: 100,
        },
        {
            field: 'agentGstNumber',
            headerName: 'Agent GST No.',
            width: 150,
        },
        {
            field: 'agentPanNumber',
            headerName: 'Agent PAN No.',
            width: 150,
        },
        {
            field: 'password',
            headerName: 'Password',
            width: 100,
        },
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            renderCell: (params) => (
                <Button
                    variant="text"
                    color="error"
                >
                    <DeleteForeverIcon fontSize='large' />
                </Button>
            ),
        },
    ];


    return (
        <div style={{ minheight: '100vh', width: '100%', marginTop: '10px' }}>
            <DataGrid
                // rows={DataWithID(data)}
                rows={rows}
                columns={columns}
                density="comfortable" // Set default density to "comfortable"
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                    },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                components={{
                    Toolbar: GridToolbar,
                }}
            />
        </div>
    );
}
