import React from 'react'

const Instruction = () => {
    return (
        <div style={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
            <div>
                <img src='/assets/OppsImage.png' style={{ width: '350px' }} alt="oops" />
                <b>This website is best viewed on a desktop. Please switch to a desktop device for the optimal experience.</b>
            </div>
        </div>
    )
}

export default Instruction