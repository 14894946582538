import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import Bread from '../components/Bread/Bread';
import Header from '../layouts/dashboard/header';
import Nav from '../layouts/dashboard/nav';
import TravelAgentTabel from '../components/Tables/TravelAgentTabel';


const ManageTravelAgents = () => {

    const APP_BAR_MOBILE = 64;
    const APP_BAR_DESKTOP = 92;

    const StyledRoot = styled('div')({
        display: 'flex',
        minHeight: '100%',
        overflow: 'hidden',
    });
    const [open, setOpen] = useState(false);

    const Main = styled('div')(({ theme }) => ({
        flexGrow: 1,
        overflow: 'auto',
        minHeight: '100%',
        paddingTop: APP_BAR_MOBILE + 24,
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.up('lg')]: {
            paddingTop: APP_BAR_DESKTOP + 24,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    }));


    return (
        <StyledRoot>
            <Header onOpenNav={() => setOpen(true)} />
            <Nav openNav={open} onCloseNav={() => setOpen(false)} />
            <Main>
                <Outlet />
                <Bread icon={'healthicons:travel'} tabName={'Manage Travel Agent'} />
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <AppWidgetSummary
                            title="Total Travel Agents"
                            total={25}
                            color="info"
                            icon={'solar:user-bold-duotone'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <AppWidgetSummary
                            title="Travel Agent ( This Week ) "
                            total={85}
                            color="success"
                            icon={'icon-park-twotone:up-two'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <AppWidgetSummary
                            title="Non-Active Travel Agent"
                            total={11}
                            color="error"
                            icon={'icon-park-twotone:down-two'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TravelAgentTabel />
                    </Grid>
                </Grid>

            </Main>

        </StyledRoot>
    )
}

export default ManageTravelAgents