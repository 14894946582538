import { Box, styled, Typography } from '@mui/material';
import Header from '../layouts/dashboard/header';
import Nav from '../layouts/dashboard/nav';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

export default function VendorAgreement() {
  return (
    <StyledRoot>
      <Header />
      <Nav />
      <Main>
        <Box
          sx={{
            padding: 2,
          }}
        >
          <Typography variant="h3" textAlign={'center'} mb={2}>
            Agreement For Vendor ( Hotel Owner)
          </Typography>
          <Typography variant="h4" my={4}>
            HOTEL OWNER USER AGREEMENT
          </Typography>
          <Typography variant="body1" mt={2}>
            This Hotel Owner User Agreement ("Agreement") is entered into on [DATE] ("Effective Date") by and between:{' '}
          </Typography>
          <Typography variant="body1" mt={2}>
            [HOTEL OWNER'S NAME] ("Hotel Owner") with its principal place of business at [HOTEL OWNER'S ADDRESS]
          </Typography>
          <Typography variant="body1" mt={2}>
            Hotelio ("Hotelio") with its principal place of business at [HOTELIO'S ADDRESS].
          </Typography>
          <Typography variant="h4" my={4}>
            RECITALS
          </Typography>
          <Typography variant="body1" mt={2}>
            WHEREAS, Hotel Owner is the owner/operator of [HOTEL NAME] ("Hotel") located at [HOTEL ADDRESS]
          </Typography>
          <Typography variant="body1" mt={2}>
            WHEREAS, Hotelio operates an online travel agency platform ("Platform") that enables hotels to market and
            sell their rooms to customers.
          </Typography>
          <Typography variant="body1" mt={2}>
            WHEREAS, Hotel Owner desires to list its Hotel on the Platform and utilize Hotelio's services to market and
            sell its rooms.
          </Typography>
          <Typography variant="h4" my={4}>
            TERMS AND CONDITIONS
          </Typography>
          <ol>
            <li>
              <Typography variant="h6" mt={2}>
                Definitions
              </Typography>
              <ul
                style={{
                  marginLeft: '20px',
                }}
              >
                <li>"Room" means a hotel room made available by Hotel Owner on the Platform</li>
                <li>"Customer" means a person who books a Room through the Platform.</li>
                <li>"Booking" means a reservation made by a Customer for a Room.</li>
                <li>"Commission" means the percentage of the Booking revenue payable to Hotelio.</li>
              </ul>
            </li>
            <li>
              <Typography variant="h6" mt={2}>
                Hotel Listing
              </Typography>
              <ul
                style={{
                  marginLeft: '20px',
                }}
              >
                <li>Hotel Owner grants Hotelio permission to list its Hotel on the Platform.</li>
                <li>
                  Hotel Owner shall provide Hotelio with accurate and up-to-date information about its Hotel, including
                  room types, rates, and availability.
                </li>
              </ul>
            </li>
            <li>
              <Typography variant="h6" mt={2}>
                Onboarding Fees
              </Typography>
              <ul
                style={{
                  marginLeft: '20px',
                }}
              >
                <li>Hotel Owner shall pay Hotelio an onboarding fee of ₹11,000 (Rupees Eleven Thousand Only).</li>
                <li>The onboarding fee is non-refundable</li>
              </ul>
            </li>
            <li>
              <Typography variant="h6" mt={2}>
                Benefits to Hotel Owner
              </Typography>
              <ul
                style={{
                  marginLeft: '20px',
                }}
              >
                <li>
                  Hotelio shall provide Hotel Owner with one (1) hording (size: [insert size]) at no additional cost.
                </li>
                <li>Hotelio shall provide Hotel Owner with bathroom essentials (list of essentials: [insert list]).</li>
                <li>Hotelio shall provide Hotel Owner with marketing tools and support.</li>
              </ul>
            </li>
            <li>
              <Typography variant="h6" mt={2}>
                Booking and Payment
              </Typography>
              <ul>
                <li>Hotelio shall facilitate Bookings and payments for Rooms.</li>
                <li>
                  Hotel Owner shall ensure that Rooms are available and meet the standards described on the Platform.
                </li>
              </ul>
            </li>
            <li>
              <Typography variant="h6" mt={2}>
                Commission
              </Typography>
              <ul>
                <li>Hotel Owner shall pay Hotelio a Commission on each Booking.</li>
                <li>Commission rates shall be as agreed upon between Hotel Owner and Hotelio.</li>
              </ul>
            </li>
            <li>
              <Typography variant="h6" mt={2}>
                Cancellation and Refund Policy
              </Typography>
              <ul>
                <li>Hotel Owner shall establish a cancellation and refund policy for Bookings.</li>
                <li>Hotelio shall facilitate refunds to Customers in accordance with Hotel Owner's policy.</li>
              </ul>
            </li>
            <li>
              <Typography variant="h6" mt={2}>
                Customer Support
              </Typography>
              <ul>
                <li>Hotel Owner shall provide customer support to Customers.</li>
                <li>Hotelio shall provide support to Hotel Owner as needed.</li>
              </ul>
            </li>
            <li>
              <Typography variant="h6" mt={2}>
                Term and Termination
              </Typography>
              <ul>
                <li>
                  This Agreement shall commence on the Effective Date and continue until terminated by either party with
                  written notice
                </li>
                <li>Upon termination, Hotel Owner shall remove its Hotel from the Platform.</li>
              </ul>
            </li>
            <li>
              <Typography variant="h6" mt={2}>
                Confidentiality
              </Typography>
              <ul>
                <li>Hotel Owner and Hotelio shall maintain confidentiality of each other's proprietary information.</li>
              </ul>
            </li>
            <li>
              <Typography variant="h6" mt={2}>
                Liability
              </Typography>
              <ul>
                <li>
                  Hotel Owner and Hotelio shall indemnify each other against losses resulting from breach of this
                  Agreement
                </li>
              </ul>
            </li>
          </ol>
          <Typography variant="body1" fontWeight={700} my={4}>
            BY SIGNING BELOW, THE PARTIES ACKNOWLEDGE THAT THEY HAVE READ, UNDERSTAND, AND AGREE TO THE TERMS AND
            CONDITIONS OF THIS AGREEMENT.
          </Typography>
          <Typography variant="body1" mt={4} fontWeight={600}>
            HOTEL OWNER:
          </Typography>
          <Typography variant="body1" mt={1}>
            <span style={{ fontWeight: 600 }}>Name:</span> [HOTEL OWNER'S NAME]
          </Typography>
          <Typography variant="body1">
            <span style={{ fontWeight: 600 }}>Signature:</span> [HOTEL OWNER'S SIGNATURE]
          </Typography>
          <Typography variant="body1">
            <span style={{ fontWeight: 600 }}>Date:</span> [DATE]
          </Typography>
          <Typography variant="body1" mt={4} fontWeight={600}>
            HOTELIO:
          </Typography>
          <Typography variant="body1" mt={1}>
            <span style={{ fontWeight: 600 }}>Name:</span> [HOTELIO'S NAME]
          </Typography>
          <Typography variant="body1">
            <span style={{ fontWeight: 600 }}>Signature:</span> [HOTELIO'S SIGNATURE]
          </Typography>
          <Typography variant="body1">
            <span style={{ fontWeight: 600 }}>Date:</span> [DATE]
          </Typography>
        </Box>
      </Main>
    </StyledRoot>
  );
}

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
