import * as constant from '../../Constants/DashStaticsConstants/DashStaticsConstants';

const initialState = {
    loading: false,
    error: false,
    data: null,
};

const GetAllDashStatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_STATS_API_LOADING:
            return {
                ...state,
                loading: true,
            };
        case constant.GET_STATS_API_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case constant.GET_STATS_API_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};

const GetAllDashStatsHReducer = (state = initialState, action) => {
    switch (action.type) {
        case constant.GET_STATS_H_API_LOADING:
            return {
                ...state,
                loading: true,
            };
        case constant.GET_STATS_H_API_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case constant.GET_STATS_H_API_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };

        default:
            return state;
    }
};


export { GetAllDashStatsReducer, GetAllDashStatsHReducer };
