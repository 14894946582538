import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Fade,
    Grid,
    InputLabel,
    MenuItem,
    Modal,
    Select,
    TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import CouponsTable from '../components/Tables/CouponsTable';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import Bread from '../components/Bread/Bread';
import { AddCoupon, GetAllCouponAction } from '../Store/Actions/HotelioCouponActions/HotelioCouponAction';

const Coupons = () => {

    const dispatch = useDispatch();
    // State to control the visibility of the modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        dispatch(GetAllCouponAction())
    }, [])

    const AllCoupons = useSelector((state) => state.GetAllCouponReducer.data)

    // Modal component to add discounts
    const AddDiscount = ({ open, handleClose }) => {

        // State to store form data
        const [couponData, setCouponData] = useState({
            couponCode: "",
            session: {
                from: "",
                to: ""
            },
            active: true,
            descriptions: ""
        });

        // Handle form input changes
        const handleInputChange = (e) => {
            setCouponData({
                ...couponData,
                [e.target.name]: e.target.value,
            });
        };

        const isFormValid = () => {
            // Add validation logic here
            if (
                couponData.couponCode &&
                couponData.from &&
                couponData.to &&
                couponData.descriptions
            ) {
                return true;
            }
            return false;
        };

        // Handle form submission
        const handleSubmit = async () => {
            if (isFormValid()) {
                const data = {
                    couponCode: couponData.couponCode,
                    session: {
                        from: couponData.from,
                        to: couponData.to
                    },
                    active: true,
                    descriptions: couponData.descriptions
                }
                console.log(data);
                dispatch(AddCoupon(data))
                await dispatch(GetAllCouponAction())
                handleClose()
                Swal.fire({
                    icon: 'success',
                    title: 'Coupon Added successfully',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Validation Error',
                    text: 'Please fill in all fields before submitting.',
                });
            }
        };

        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                sx={{ zIndex: '1200' }}
                onClose={handleClose}
                closeAfterTransition
            >
                <Fade in={open}>
                    <Box sx={modalStyle}>
                        <Grid container spacing={2}>
                            {/* <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    value={couponData.name}
                                    onChange={handleInputChange}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Code"
                                    type="text"
                                    name="couponCode"
                                    sx={{ textTransform: 'uppercase' }}
                                    value={couponData.couponCode}
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    label="Start Date"
                                    name="from"
                                    focused
                                    type="date"
                                    value={couponData.from}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    focused
                                    label="Expiry Date"
                                    name="to"
                                    type="date"
                                    value={couponData.to}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Description"
                                    name="descriptions"
                                    value={couponData.descriptions}
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            {/* Buttons */}
                            <Grid item xs={12} display={'flex'} justifyContent={'space-between'}>
                                <Button variant="contained" onClick={handleClose}>
                                    Cancel
                                </Button>{' '}
                                <Button variant="contained" onClick={handleSubmit}>
                                    Add Discount
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        );
    };

    return (
        <div>
            <AddDiscount open={open} handleClose={handleClose} />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Bread icon={'ic:twotone-discount'} tabName={'Manage Coupons'} />
                </Grid>
                <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
                    <Button onClick={handleOpen} variant="contained">
                        Add Coupon
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Total Coupons"
                        total={11}
                        color="info"
                        icon={'ic:twotone-discount'}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Active Coupons"
                        total={10}
                        color="success"
                        icon={'bi:circle-fill'}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="In-Active Coupons"
                        total={1}
                        color="error"
                        icon={'bi:circle-fill'}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppWidgetSummary
                        title="Redemption Coupons"
                        total={10}
                        color="warning"
                        icon={'wpf:cut-paper'}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <CouponsTable Data={AllCoupons} />
                </Grid>
            </Grid>
        </div>
    );
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    overflow: 'auto', // Added scrolling property
};

export default Coupons;
