export const GET_REVIEW_API_LOADING = 'GET_REVIEW_API_LOADING';
export const GET_REVIEW_API_SUCCESS = 'GET_REVIEW_API_SUCCESS';
export const GET_REVIEW_API_ERROR = 'GET_REVIEW_API_ERROR';

export const GETHOTEL_REVIEW_API_LOADING = 'GETHOTEL_REVIEW_API_LOADING';
export const GETHOTEL_REVIEW_API_SUCCESS = 'GETHOTEL_REVIEW_API_SUCCESS';
export const GETHOTEL_REVIEW_API_ERROR = 'GETHOTEL_REVIEW_API_ERROR';

export const DELETE_REVIEW_API_LOADING = 'DELETE_REVIEW_API_LOADING';
export const DELETE_REVIEW_API_SUCCESS = 'DELETE_REVIEW_API_SUCCESS';
export const DELETE_REVIEW_API_ERROR = 'DELETE_REVIEW_API_ERROR';

export const GETALL_REVIEW_API_LOADING = 'GETALL_REVIEW_API_LOADING';
export const GETALL_REVIEW_API_SUCCESS = 'GETALL_REVIEW_API_SUCCESS';
export const GETALL_REVIEW_API_ERROR = 'GETALL_REVIEW_API_ERROR';

export const UPDATE_REVIEW_STATUS_API_LOADING = 'UPDATE_REVIEW_STATUS_API_LOADING';
export const UPDATE_REVIEW_STATUS_API_SUCCESS = 'UPDATE_REVIEW_STATUS_API_SUCCESS';
export const UPDATE_REVIEW_STATUS_API_ERROR = 'UPDATE_REVIEW_STATUS_API_ERROR';