
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
import { RingLoader } from "react-spinners";
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Logo from '../components/logo';
import Iconify from '../components/iconify';
// sections
import { LoginForm } from '../sections/auth/login';
import RegisterForm from './RegisterForm';
import GetVendorSignUpReducer from '../Store/Reducers/AuthReducers/VendorSignupReducer';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
    overflow: 'hidden'
}));

const StyledContent = styled('div')(({ theme }) => ({
    // maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    // display: 'flex',
    // justifyContent: 'center',
    // flexDirection: 'column',
    padding: theme.spacing(6, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterPage() {


    // redux data reducer 
    const SignupData = useSelector(pre => pre.GetVendorSignUpReducer)
    const mdUp = useResponsive('up', 'md');

    return (
        <>
            <Helmet>
                <title> Login </title>
            </Helmet>

            <StyledRoot>
                {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 20 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}

                {mdUp && (
                    <StyledSection>
                        <Typography variant="h3" sx={{ position: 'absolute', top: '15%' }}>
                            Welcome To Hotelio, Your Travel Partner
                        </Typography>
                        <img style={{ objectFit: 'cover' }} src="/assets/illustrations/illustration_login.svg" alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
                    {/* loader */}
                    <RingLoader
                        color="#ff543b"
                        loading={SignupData.isLoading}
                        style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50% , -50%)"
                        }}
                    />

                    <StyledContent>
                        {/* <Typography variant="h4" gutterBottom>
                            Adventure starts here 🚀
                            Make your app management easy and fun!
                        </Typography> */}


                        <RegisterForm />

                        <Divider sx={{ my: 3 }}>
                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                OR
                            </Typography>
                        </Divider>
                        <Typography variant="p" sx={{ mt: 5 }}>
                            Already have an account?
                        </Typography>
                        <Link href='/' variant="subtitle2">Sign in instead</Link>

                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
