import React, { useEffect } from 'react'
import {
    Box,
    Card,
    Grid,
    Typography,
    CardContent
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { GetReadNotificationAction, GetUserNotificationAction } from '../Store/Actions/Notification/NotificationActions';
import { UseStateManager } from '../Context/StateManageContext';

const AdminNotifications = () => {

    const { currentUser } = UseStateManager();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetUserNotificationAction(currentUser?._id));
    }, [currentUser]);

    const AdminNotifications = useSelector((state) => state.GetUserNotificationReducer.data);

    const handleReadNotification = async (notificationId) => {
        dispatch(GetReadNotificationAction(notificationId))
        await dispatch(GetUserNotificationAction(currentUser?._id));
    }

    return (
        <Box>
            <Typography variant="h4" color="initial">Notification List</Typography>
            <Grid container spacing={1}>
                {
                    AdminNotifications.length === 0 ? (
                        <Grid item xs={12}>
                            <Card>
                                <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <Typography variant='h5'>No Any Notifications</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ) : (
                        AdminNotifications.map((notification, index) => (
                            <Grid key={index} item xs={12}>
                                <Card onClick={() => handleReadNotification(notification._id)} sx={{ bgcolor: notification?.read === false ? '#ee2e2461' : '#fff', color: notification?.read === false ? '#fff' : '#ee2e24' }}>
                                    <CardContent style={{ display: 'flex', alignItems: 'center' }}>
                                        <img width={80} height={80} style={{ borderRadius: '50%' }} src="https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png" alt=".." />
                                        <div style={{ marginLeft: '10px', width: '50%' }}>
                                            <p>{notification?.message}</p>
                                            <h5 style={{ margin: '10px 0px', color: '#8D8D8D' }}>{moment(notification?.createdAt).startOf('hour').fromNow()}</h5>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))
                    )
                }

            </Grid>
        </Box>
    )
}

export default AdminNotifications