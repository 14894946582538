import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, Fade, Grid, Input, Typography } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import GlobalModel from '../GlobalModal/GlobalModel';
import WaitLoader from '../../utils/WaitLoader';

const AddAmenitiesModal = ({
  openAmenities,
  handleCloseAmenities,
  loader,
  style,
  hotelData,
  expanded,
  HandleAdditionalAmenitiesAdd,
  setAdditionalAmmenities,
  selectedAdditonalAmmenities,
}) => {
  const [value, setValue] = useState('');

  const roomData = hotelData?.rooms?.find((x) => x?._id === expanded);

  const HandleAddAmenities = (value) => {
    if (selectedAdditonalAmmenities.includes(value)) {
      window.alert('This Amenity is Already you have added');
    } else {
      setAdditionalAmmenities([...selectedAdditonalAmmenities, value]);
      setValue('');
    }
  };

  useEffect(() => {
    if (roomData) {
      setAdditionalAmmenities([...roomData?.additionAmenities]);
    }
  }, [expanded]);

  return (
    <GlobalModel
      open={openAmenities}
      handleClose={handleCloseAmenities}
      content={
        <Fade in={openAmenities}>
          <Box sx={style}>
            <Typography variant="h6">Add Additional Amenities</Typography>
            <hr />
            <div style={{ paddingTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid xs={6} item>
                  <div>
                    <Input
                      type="text"
                      placeholder="Type here"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                    />
                    <Button variant="contained" onClick={() => HandleAddAmenities(value)}>
                      Add Option
                    </Button>
                  </div>
                </Grid>
                <Grid xs={6} item>
                  <div>
                    <Box>
                      {selectedAdditonalAmmenities?.map((item) => (
                        <Chip
                          label={item}
                          sx={{ m: 1 }}
                          deleteIcon={<CancelRoundedIcon />}
                          onDelete={() =>
                            setAdditionalAmmenities([...selectedAdditonalAmmenities.filter((x) => x !== item)])
                          }
                          variant="outlined"
                        />
                      ))}
                    </Box>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div>
              <LoadingButton
                sx={{ m: 1 }}
                variant="contained"
                color="primary"
                type="button"
                loading={loader}
                onClick={() => HandleAdditionalAmenitiesAdd(hotelData?._id, expanded, selectedAdditonalAmmenities)}
              >
                Save
              </LoadingButton>
              <Button onClick={handleCloseAmenities} sx={{ m: 1 }} variant="outlined" color="primary">
                Cancel
              </Button>
            </div>
          </Box>
        </Fade>
      }
    />
  );
};

export default AddAmenitiesModal;
