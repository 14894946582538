import {
  Container,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Avatar,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import Header from '../layouts/dashboard/header';
import Nav from '../layouts/dashboard/nav';
import instance from '../utils/_utils';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

export default function UserDetails() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return Number.isNaN(date.getTime()) ? 'Invalid Date' : date.toLocaleDateString();
  };

  useEffect(() => {
    const fetchBooking = async () => {
      try {
        const res = await instance.get(`/admin/allcustomer?userId=${id}&listBooking=true`);
        if (!res.data) {
          setLoading(false);
          return;
        }
        setUser(res?.data?.data);
        console.log('user', res?.data?.data);

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchBooking();
  }, [id]);

  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
            }}
          >
            <ScaleLoader color="#FF4081" loading={loading} />
          </Box>
        ) : user ? (
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Card elevation={4}>
              <CardHeader
                avatar={<Avatar>{user.name[0]}</Avatar>}
                title={<Typography variant="h5">{user.name}</Typography>}
                subheader={
                  <Grid container alignItems="center">
                    <Grid item sm={6}>
                      <Typography variant="subtitle1">Mobile: {user.mobileNo}</Typography>
                      <Typography variant="subtitle1">Email: {user.email}</Typography>
                    </Grid>
                    <Grid item sm={6}>
                      <Typography variant="subtitle1">Verified By: {user.isVerified.join(', ')}</Typography>
                      <Typography variant="subtitle1">Referral Code: {user.referralCode}</Typography>
                    </Grid>
                  </Grid>
                }
              />

              <CardContent>
                {/* Wallet Section */}
                <Box mb={4}>
                  <Typography variant="h6" gutterBottom>
                    Wallet Details
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography>Amount: {user.wallet.amount} INR</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>Expires On: {formatDate(user.wallet.expire)}</Typography>
                    </Grid>
                  </Grid>
                </Box>

                {/* Booking History */}
                <Box>
                  <Typography variant="h6" gutterBottom>
                    Booking History
                  </Typography>
                  <List>
                    {user.bookings.map((booking, index) => (
                      <Box key={index} mb={2}>
                        <Card elevation={2}>
                          <CardContent>
                            <Typography variant="subtitle1" gutterBottom>
                              Booking ID: <Link to={`/dashboard/booking/${booking.bookingId}`}>{booking.bookingId}</Link>
                            </Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={6}>
                                <Typography>Check-In: {formatDate(booking.bookingDate.checkIn)}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography>Check-Out: {formatDate(booking.bookingDate.checkOut)}</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography>Amount Paid: {booking.payment.paidamount} INR</Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography>Status: {booking.bookingStatus}</Typography>
                              </Grid>
                            </Grid>
                            <Box mt={2}>
                              <Typography variant="body2">Special Requests: {booking.specialRequests}</Typography>
                            </Box>
                          </CardContent>
                        </Card>
                        {index < user.bookings.length - 1 && <Divider sx={{ my: 2 }} />}
                      </Box>
                    ))}
                  </List>
                </Box>
              </CardContent>
            </Card>
          </Container>
        ) : (
          <p>User not found!</p>
        )}
      </Main>
    </StyledRoot>
  );
}

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
