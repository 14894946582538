import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Box,
  FormControlLabel,
  Grid,
  Typography,
  Checkbox,
  Switch
} from '@mui/material';
import Header from '../layouts/dashboard/header';
import Nav from '../layouts/dashboard/nav';
import { GetAllNotificationEventTempAction } from '../Store/Actions/Notification/NotificationActions';

const NotificationMgmtById = () => {


  const APP_BAR_MOBILE = 64;
  const APP_BAR_DESKTOP = 92;

  const StyledRoot = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
  });
  const [open, setOpen] = useState(false);

  const Main = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  const [switchStatus, setSwitchStatus] = useState({
    admin: { email: true, inApp: true, sms: true },
    partner: { email: true, inApp: true, sms: true },
    customer: { email: true, inApp: true, sms: true },
    agent: { email: true, sms: true }
  });

  const { id } = useParams();
  const dispatch = useDispatch();
  const NotificationTemp = useSelector((state) => state.GetAllNotificationTempReducer)

  React.useEffect(() => {
    dispatch(GetAllNotificationEventTempAction(id))
  }, [])

  console.log(NotificationTemp)

  useEffect(() => {
    // Update switch status based on the NotificationTemp data
    if (NotificationTemp && NotificationTemp.length > 0) {
      const notificationData = NotificationTemp[0];
      setSwitchStatus({
        admin: {
          email: notificationData.notificationTo.admin.email,
          inApp: notificationData.notificationTo.admin.inApp,
          sms: notificationData.notificationTo.admin.sms
        },
        partner: {
          email: notificationData.notificationTo.partner.email,
          inApp: notificationData.notificationTo.partner.inApp,
          sms: notificationData.notificationTo.partner.sms
        },
        customer: {
          email: notificationData.notificationTo.customer.email,
          inApp: notificationData.notificationTo.customer.inApp,
          sms: notificationData.notificationTo.customer.sms
        },
        agent: {
          email: notificationData.notificationTo.agent.email,
          sms: notificationData.notificationTo.agent.sms
        }
      });
    }
  }, [NotificationTemp]);

  const handleSwitchChange = (role, key) => {
    setSwitchStatus((prevSwitchStatus) => ({
      ...prevSwitchStatus,
      [role]: {
        ...prevSwitchStatus[role],
        [key]: !prevSwitchStatus[role][key]
      }
    }));
  };


  return (
    <StyledRoot>
      <Header onOpenNav={() => setOpen(true)} />
      <Nav openNav={open} onCloseNav={() => setOpen(false)} />
      <Main>
        <Outlet />
        <Box>
          <Typography variant="h4" color="initial">Notification Configurations</Typography>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="initial">EventKey{id}</Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" color="initial">Admin</Typography>
              <Grid container spacing={1} p={1} alignItems="center" >
                <Grid item xs={12} >
                  <FormControlLabel
                    control={<Switch checked={switchStatus.admin.email} onChange={() => handleSwitchChange('admin', 'email')} />}
                    label="Email"
                  />
                </Grid>
                <Grid item xs={12} >
                  <FormControlLabel
                    control={<Switch checked={switchStatus.admin.inApp} onChange={() => handleSwitchChange('admin', 'inApp')} />}
                    label="In-App"
                  />
                </Grid>
                <Grid item xs={12} >
                  <FormControlLabel
                    control={<Switch checked={switchStatus.admin.sms} onChange={() => handleSwitchChange('admin', 'sms')} />}
                    label="SMS"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" color="initial">Vendor/Partner</Typography>
              <Grid container spacing={1} p={1} alignItems="center" >
                <Grid item xs={12} >
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Email" />
                </Grid>
                <Grid item xs={12} >
                  <FormControlLabel control={<Checkbox defaultChecked />} label="SMS" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" color="initial">Customer</Typography>
              <Grid container spacing={1} p={1} alignItems="center" >
                <Grid item xs={12} >
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Email" />
                </Grid>
                <Grid item xs={12} >
                  <FormControlLabel control={<Checkbox defaultChecked />} label="In-App" />
                </Grid>
                <Grid item xs={12} >
                  <FormControlLabel control={<Checkbox defaultChecked />} label="SMS" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h6" color="initial">Agent</Typography>
              <Grid container spacing={1} p={1} alignItems="center" >
                <Grid item xs={12} >
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Email" />
                </Grid>
                <Grid item xs={12} >
                  <FormControlLabel control={<Checkbox defaultChecked />} label="SMS" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Main>
    </StyledRoot>

  )
}

export default NotificationMgmtById