import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Box, Fade, Grid, Modal } from '@mui/material';
import { AppWidgetSummary } from '../sections/@dashboard/app';
import VendorTable from '../components/Tables/VendorTable';
import AddVendor from '../components/AddVendor/AddVendor';
import GetAllVendorsReducer from '../Store/Reducers/VendorReducer/VendorGetAllReducer';
import { GetAllVendors } from '../Store/Actions/VendorsActions/GetAllVendorAction';
import Bread from '../components/Bread/Bread';

const AddPartner = () => {
    // reducer data
    const { data } = useSelector((pre) => pre.GetAllVendorsReducer);

    // call the data
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetAllVendors());
    }, []);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 900,
        bgcolor: 'background.paper',
        borderRadius: '10px',
        boxShadow: 24,
        p: 4,
        overflow: 'auto', // Added scrolling property
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate()

    const AddHotelsForm = () => {
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                sx={{ zIndex: '1200' }}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <AddVendor />
                    </Box>
                </Fade>
            </Modal>
        );
    };

    return (
        <div>
            <AddHotelsForm />
            <Bread icon={'ic:twotone-group'} tabName={'Manage Partner'} />
            <Grid container spacing={3}>
                {/* Add Own Hotel */}
                <Grid item xs={12} sm={6} md={6}>
                    <AppWidgetSummary sx={{ height: "100%", display: "flex", alingItems: "center", flexDirection: "column", justifyContent: "center" }}
                        title="Add New Partner"
                        // total={10}
                        color="info"
                        icon={'material-symbols:add'}
                        onClick={handleOpen}
                    />
                </Grid>

                {/* Add Vendor Hotel */}
                <Grid item xs={12} sm={6} md={6}>
                    <AppWidgetSummary
                        title="Total Partner"
                        total={data.data?.length}
                        color="warning"
                        icon={'tabler:building-hospital'}
                        onClick={() => navigate('/dashboard/listPartner')}
                    // onClick={handleOpen}
                    />
                </Grid>
                {/* VendorHotel Component */}
                <Grid item xs={12} sm={12} md={12}>
                    <VendorTable />
                </Grid>
            </Grid>
        </div>
    );
};

export default AddPartner;
