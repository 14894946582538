import { combineReducers } from 'redux';
import GetVendorLoginReducer from './AuthReducers/VendorLoginReducer';
import GetVendorSignUpReducer from './AuthReducers/VendorSignupReducer';
import GetVendorVerifyReducer from './AuthReducers/VendorVerificationReducers';
import UploadReducer from './UploadReducer/UploadReducers';
import GetAllVendorsReducer from './VendorReducer/VendorGetAllReducer';
import GetAllHotelReducer from './HotelReducers/GetHotelsReducers';
import GetSingleHotelsDetails from './VendorReducer/GetSingleVendorReducer';
import GetAllCustomerReducer from './adminReducer/customerReducers';
import GetfacilitiesReducer from './VendorReducer/GetfacilitiesReducer';
import AllRoomTypeReducers from './adminReducer/AllRoomTypeReducers';
import { AllPropertyTypeReducers, AddPropertyTypeReducers, RemovePropertyTypeReducers, UpdatePropertyTypeReducers } from './adminReducer/AllPropertyTypeReducers';
import { MakeKycActionReducer, GetAllKycList } from './adminReducer/kycReducers';
import { GetAddTheRoomReducer, GetUpdateRoomData } from './RoomManagementReducer/roomManagementReducers';
import { AdminAddCountReducer, AdminVendorCountReducer, GetHotelAddReducers, HotelStatsReducers, UpdateHotelReducers } from './HotelReducers/HotelAddReducers';
import { GetFaqByUserReducer, CreateTheFaqReducer, UpdateFaqReducer } from './faqReducers';
import { CreateLocationReducer, UpdateLoationReducers, GetLocationsReducers } from './locationReducers';
import { GetAllRoomConfigReducer, GetRoomStatsReducer } from './RoomConfigReducer';
import BankDetailReducers from './BankDetailReducers'
import { BookingByIdReducers, BookingReducers, GetAdminBookingStatsReducer, VendorBookingReducers, VendorBookingStatsReducer } from './BookingReducers/BookingReducers'
import { HotelioOffersReducers, CreateHotelioOffersReducers, DeletedHotelioOffersReducers } from './HotelioOffersReducers/HotelioOffersReducer'
import { GetAllAmenities, AddAmenitiesReducer, RemoveAmenitiesReducer, RemoveAllAmenitiesReducer } from './adminReducer/AllamenitiesReducers'
import { GetAllFacilities, AddFacilitiesReducer, RemoveFacilitiesReducer, RemoveAllFacilitiesReducer } from './adminReducer/AllFacilitiesReducers'
import { GetAllReviewRatingsByIdReducer, DeleteReviewRatingsReducer, GetAllReviewRatingsReducer, GetAllReviewRatingsByHotelReducer } from './ReviewRatingReducers/ReviewRatingReducer';
import { GetAllNotificationEventReducer, GetAllNotificationTempReducer, GetUserNotificationReducer } from './NotificationReducers/NotificationReducers';
import { GetAllSliderReducer } from './SliderReducer/SliderReducer';
import { GetAllCouponReducer } from './HotelioCouponReducers/HotelioCouponReducer';
import { GetAllDashStatsHReducer, GetAllDashStatsReducer } from './DashStaticsReducers/DashStaticsReducers';

const rootReducers = combineReducers({
  GetVendorLoginReducer,
  GetVendorSignUpReducer,
  GetVendorVerifyReducer,
  GetAllVendorsReducer,
  UploadReducer,
  GetAllHotelReducer,
  GetSingleHotelsDetails,
  GetAllCustomerReducer,
  GetfacilitiesReducer,
  AllRoomTypeReducers,
  MakeKycActionReducer,
  GetAllKycList,
  GetAddTheRoomReducer,
  GetUpdateRoomData,
  UpdateHotelReducers,
  GetHotelAddReducers,
  GetFaqByUserReducer,
  CreateTheFaqReducer,
  UpdateFaqReducer,
  CreateLocationReducer,
  UpdateLoationReducers,
  GetLocationsReducers,
  GetAllRoomConfigReducer,
  GetRoomStatsReducer,
  AllPropertyTypeReducers,
  AddPropertyTypeReducers,
  RemovePropertyTypeReducers,
  UpdatePropertyTypeReducers,
  BankDetailReducers,
  BookingReducers,
  BookingByIdReducers,
  VendorBookingReducers,
  HotelioOffersReducers,
  CreateHotelioOffersReducers,
  DeletedHotelioOffersReducers,
  GetAllAmenities,
  AddAmenitiesReducer,
  RemoveAmenitiesReducer,
  RemoveAllAmenitiesReducer,
  GetAllFacilities,
  AddFacilitiesReducer,
  RemoveFacilitiesReducer,
  RemoveAllFacilitiesReducer,
  GetAllReviewRatingsByIdReducer,
  DeleteReviewRatingsReducer,
  GetAllReviewRatingsReducer,
  GetAllReviewRatingsByHotelReducer,
  VendorBookingStatsReducer,
  GetAllNotificationEventReducer,
  GetAllNotificationTempReducer,
  HotelStatsReducers,
  GetAllSliderReducer,
  GetAllCouponReducer,
  GetAllDashStatsReducer,
  GetAllDashStatsHReducer,
  GetAdminBookingStatsReducer,
  GetUserNotificationReducer,
  AdminVendorCountReducer,
  AdminAddCountReducer
});

export default rootReducers;
