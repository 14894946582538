
import * as constant from "../../Constants/UploadConstants/UploadConstants";

const initialStates = {
    isLoading: false,
    isSuccess: false,
    fileName: {},
    isError: false
}


// reducer 
const UploadReducer = (state = initialStates, action) => {
    switch (action.type) {
        case constant.UPLOAD_API_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case constant.UPLOAD_API_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSuccess: true,
                fileName: action.field,
            }
        case constant.UPLOAD_API_ERROR:
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                isError: true
            }

        default:
            return state;
    }
}



export default UploadReducer;