import instance from '../../../utils/_utils';
import * as constant from '../../Constants/HotelioOffersConstants/HotelioOffersConstant';

export const GetHotelioOffersAction = () => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GET_OFFERS_API_LOADING });
            const response = await instance.get(`/offers/get-offers`);
            if (response.status === 200) {
                dispatch({ type: constant.GET_OFFERS_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GET_OFFERS_API_ERROR });
        }
    };
};

export const CreateHotelioOffersAction = (fromdata) => {
    console.log(fromdata)
    return async (dispatch) => {
        try {
            dispatch({ type: constant.CREATE_OFFERS_API_LOADING });
            const response = await instance.post(`/offers/create-offers`, fromdata);
            if (response.status === 200) {
                console.log(response.data, response.status)
                dispatch({ type: constant.CREATE_OFFERS_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.CREATE_OFFERS_API_ERROR });
        }
    };
};

export const DeleteHotelioOffersAction = (deletedId) => {
    console.log(deletedId)
    return async (dispatch) => {
        try {
            dispatch({ type: constant.DELETE_OFFERS_API_LOADING });
            const response = await instance.delete(`/offers/delete-offers?code=${deletedId}`);
            if (response.status === 200) {
                console.log(response.data, response.status)
                dispatch({ type: constant.DELETE_OFFERS_API_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.DELETE_OFFERS_API_ERROR });
        }
    };
};