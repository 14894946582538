import instance from '../../utils/_utils';
import * as constant from '../Constants/BankDetailsConstants';

export const GetBankDetailsAction = (id) => {
    return async (dispatch) => {
        try {
            dispatch({ type: constant.GET_BANK_DETAIL_LOADING });
            const response = await instance.get(`/bank/bank-details/get/${id}`);
            if (response.status === 200) {
                dispatch({ type: constant.GET_BANK_DETAIL_SUCCESS, payload: response.data.data });
            }
        } catch (error) {
            dispatch({ type: constant.GET_BANK_DETAIL_ERROR });
        }
    };
};